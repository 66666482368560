import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './village.reducer';

export interface IVillageDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VillageDetail = (props: IVillageDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { villageEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Village [<b>{villageEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{villageEntity.name}</dd>
          <dt>
            <span id="pickupLocation">Pickup Location</span>
          </dt>
          <dd>{villageEntity.pickupLocation}</dd>
          <dt>
            <span id="locationLat">Latitude</span>
          </dt>
          <dd>{villageEntity.locationLat}</dd>
          <dt>
            <span id="locationLon">Longitude</span>
          </dt>
          <dd>{villageEntity.locationLon}</dd>
          <dt>Council</dt>
          <dd>{villageEntity.council ? villageEntity.council.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/village" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ village }: IRootState) => ({
  villageEntity: village.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VillageDetail);
