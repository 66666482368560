import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, IPayloadResult} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IDriver} from 'app/shared/model/driver.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IAmbulance} from "app/shared/model/ambulance.model";

export const ACTION_TYPES = {
  FETCH_DRIVER_LIST: 'driver/FETCH_DRIVER_LIST',
  FETCH_DRIVER: 'driver/FETCH_DRIVER',
  CREATE_DRIVER: 'driver/CREATE_DRIVER',
  UPDATE_DRIVER: 'driver/UPDATE_DRIVER',
  DELETE_DRIVER: 'driver/DELETE_DRIVER',
  UPDATE_NEIGHBOR_VILLAGES: 'driver/UPDATE_NEIGHBOR_VILLAGES',
  RESET_MODAL: 'driver/RESET_MODAL',
  RESET: 'driver/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDriver>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteFailure: false
};

export type DriverState = Readonly<typeof initialState>;

// Reducer

export default (state: DriverState = initialState, action): DriverState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DRIVER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DRIVER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DRIVER):
    case REQUEST(ACTION_TYPES.UPDATE_DRIVER):
    case REQUEST(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_DRIVER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DRIVER):
    case FAILURE(ACTION_TYPES.CREATE_DRIVER):
    case FAILURE(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteFailure: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DRIVER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_DRIVER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DRIVER):
    case SUCCESS(ACTION_TYPES.UPDATE_DRIVER):
    case SUCCESS(ACTION_TYPES.UPDATE_NEIGHBOR_VILLAGES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET_MODAL:
      return {
        ...state,
        deleteFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/drivers';

// Actions

export const getEntities: (page?, size?, sort?, countryId?) => IPayload<IDriver> | IPayloadResult<IDriver> = (page?, size?, sort?, countryId?) => {

  const requestUrl = (sort.split(",")[0] === "district" || sort.split(",")[0] === "council") ? `${apiUrl}${`?page=${page}&size=${size}&countryId=${countryId}&SortKey=${sort.split(",")[0]}&OrderKey=${sort.split(",")[1]}`}` 
    :`${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&countryId=${countryId}` : ''}`;
  
  return {
    type: ACTION_TYPES.FETCH_DRIVER_LIST,
    payload: axios.get<IDriver>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IDriver> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DRIVER,
    payload: axios.get<IDriver>(requestUrl),
  };
};


export const getDriverByName: (searchString,countryId,page) => IPayload<IDriver> | IPayloadResult<IDriver> = (searchString,countryId,page) => {
  const requestUrl = 'api/search/search-driver-by-name';
  return {
    type: ACTION_TYPES.FETCH_DRIVER_LIST,
    payload: axios.get<IDriver>(`${requestUrl+'/'+searchString+'/'+countryId}?page=${page}&cacheBuster=${new Date().getTime()}`),
  };
};


export const createEntity: ICrudPutAction<IDriver> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DRIVER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDriver> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DRIVER,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: (id, country?) => IPayload<IDriver> | IPayloadResult<IDriver> = (id, countryId?) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DRIVER,
    payload: axios.delete(requestUrl),
  });
  countryId ? dispatch(getEntities(0, 25, "id", countryId)): dispatch(getEntities())
  // dispatch(getEntities());
  return result;
};

export const resetModal = () => ({
  type: ACTION_TYPES.RESET_MODAL
})

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
