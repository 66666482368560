import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './rejection-reason.reducer';

export interface IRejectionReasonDriverDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RejectionReasonDriverDetail = (props: IRejectionReasonDriverDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { rejectionReasonDriverEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          RejectionReasonDriver [<b>{rejectionReasonDriverEntity.id}</b>]
        </h2>
        
        <dl className="jh-entity-details">
          <dt>
            <span id="description">Incident Report (English)</span>
          </dt>
          <dd>{rejectionReasonDriverEntity.enRejectionReason}</dd>
        </dl>

        <dl className="jh-entity-details">
          <dt>
            <span id="description">Incident Report (Local Language)</span>
          </dt>
          <dd>{rejectionReasonDriverEntity.stRejectionReason}</dd>
        </dl>
        <Button tag={Link} to="/rejection-reasons-driver" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/rejection-reasons-driver/${rejectionReasonDriverEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ rejectionReasonDriver }: IRootState) => ({
  rejectionReasonDriverEntity: rejectionReasonDriver.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RejectionReasonDriverDetail);
