import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RejectionReason from './rejection-reason';
import RejectionReasonDetail from './rejection-reason-detail';
import RejectionReasonUpdate from './rejection-reason-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RejectionReasonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RejectionReasonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RejectionReasonDetail} />
      <ErrorBoundaryRoute path={match.url} component={RejectionReason} />
    </Switch>
  </>
);

export default Routes;
