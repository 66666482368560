import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Table,Label} from 'reactstrap';
import {getSortState, JhiItemCount, JhiPagination} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PERMISSIONS} from 'app/config/constants';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities,getAmbulanceByName} from './ambulance.reducer';
import {getEntities as getHealthcareFacilities} from "../healthcare-facility/healthcare-facility.reducer";
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {getAllByType as getCountries} from "app/entities/region/region.reducer";
import {IRegion} from "app/shared/model/region.model";

export interface IAmbulanceProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Ambulance = (props: IAmbulanceProps) => {
  const {ambulanceList, healthcareFacilities, match, loading, totalItems ,userCountryId} = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>()
  const [ambulanceName, setAmbulanceName] = useState('')
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = (countryId: string) => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, countryId);
  };

  const sortEntities = (countryId: string) => {
    getAllEntities(countryId);
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
  }

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries,userCountryId]);

  useEffect(() => {
    props.getHealthcareFacilities();
    if (activeCountry) {
      sortEntities(activeCountry.id);
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (activeCountry) {
      sortEntities(activeCountry.id);
    }
  }, [activeCountry]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    const regCountriesList = props.countries.filter((ele)=>{
      return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
    })

  return (
    <div>
      <h2 id="ambulance-heading">
        Ambulances
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Create new Ambulance
        </Link>
      </h2>
      
      
      {(regCountriesList && regCountriesList.length > 0) && (<AvForm>
          <AvGroup>
            <AvInput id="healthcare-facility-country" type="select" className="form-control"
                     name="healthcare-facility-country" value={activeCountry?.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        </AvForm>
      )
      }
      <AvForm model={{}}>
      <AvGroup>
          <Label for="status-filter">Search by ambulance name</Label>
          <AvInput

            id="status-filter"
            type="input"
            className="form-control"
            name={'status-filter'}

            onChange={(e) => {
              const serachString  = e.target.value;
              if(e.target.value){
                props.getAmbulanceByName(serachString,activeCountry?.id)
                setAmbulanceName(serachString)
              }else{
                sortEntities(activeCountry.id);
                setAmbulanceName("")
              }
             
            }}
          // value={filterList.status}
          >
          </AvInput>
        </AvGroup>
        </AvForm>
      <div className="table-responsive">
        {ambulanceList && ambulanceList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('driverFirstName')}>
                Driver First Name <FontAwesomeIcon icon="sort"/>
              </th>
              <th className="hand" onClick={sort('driverLastName')}>
                Driver Last Name <FontAwesomeIcon icon="sort"/>
              </th>
              <th className="hand" onClick={sort('vehicle_type_id')}>
                Vehicle Type <FontAwesomeIcon icon="sort"/>
              </th>
              <th className="hand" onClick={sort('phoneNumber')}>
                Phone Number <FontAwesomeIcon icon="sort"/>
              </th>
              <th>
                Healthcare Facility <FontAwesomeIcon icon="sort"/>
              </th>

              <th className="hand" onClick={sort('is_active')}>
                Status <FontAwesomeIcon icon="sort"/>
              </th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {ambulanceList.map((ambulance, i) =>

              { 
                const hfId =ambulanceName !== '' ? ambulance?.healthcareFacility?.id :ambulance.healthcareFacilityId;
                
                return (
              <tr key={`entity-${i}`}>
                <td>{ambulance.driverFirstName}</td>
                <td>{ambulance.driverLastName}</td>
                <td>{ambulance.vehicleType?.enType}</td>
                <td>{ambulance.phoneNumber}</td>
                <td>
                  {hfId ? (
                    <Link to={`healthcare-facility/${hfId}`}>
                      {healthcareFacilities.find(v => v.id === hfId)?.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
              
                <td >{ambulance.active ? 'Active' :'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${ambulance.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${ambulance.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            )
              }
              )}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Ambulances found</div>
        )}
      </div>
      {props.totalItems ? (
        <div className={ambulanceList && ambulanceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage}/>
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ambulance, healthcareFacility, region,authentication}: IRootState) => ({
  ambulanceList: ambulance.entities,
  healthcareFacilities: healthcareFacility.entities,
  loading: ambulance.loading || healthcareFacility.loading,
  totalItems: ambulance.totalItems,
  countries: region.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getHealthcareFacilities,
  getCountries,
  getAmbulanceByName
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Ambulance);
