import axios from 'axios';
import {
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
  Storage,
  IPayloadResult
} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {REQUEST, SUCCESS, FAILURE} from 'app/shared/reducers/action-type.util';

import {ICondition, defaultValue} from 'app/shared/model/condition.model';
import {IRegion} from "app/shared/model/region.model";
import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IPatientCase} from "app/shared/model/patient-case.model";

export const ACTION_TYPES = {
  FETCH_CONDITION_LIST: 'symptom/FETCH_CONDITION_LIST',
  FETCH_SYMPTOM: 'symptom/FETCH_SYMPTOM',
  CREATE_SYMPTOM: 'symptom/CREATE_SYMPTOM',
  UPDATE_SYMPTOM: 'symptom/UPDATE_SYMPTOM',
  DELETE_SYMPTOM: 'symptom/DELETE_SYMPTOM',
  RESET: 'symptom/RESET',
  SET_ACTIVE_COUNTRY: 'symptom/SET_ACTIVE_COUNTRY',
  FETCH_ALL_COUNTRIES: 'symptom/FETCH_ALL_COUNTRIES',
};

const initialState = {
  loading: false,
  errorMessage: null,
  conditionList: [] as ReadonlyArray<ICondition>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  countries: [] as ReadonlyArray<IRegion>
};

export type ConditionState = Readonly<typeof initialState>;

// Reducer

export default (state: ConditionState = initialState, action): ConditionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONDITION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_SYMPTOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SYMPTOM):
    case REQUEST(ACTION_TYPES.UPDATE_SYMPTOM):
    case REQUEST(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CONDITION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SYMPTOM):
    case FAILURE(ACTION_TYPES.CREATE_SYMPTOM):
    case FAILURE(ACTION_TYPES.UPDATE_SYMPTOM):
    case FAILURE(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONDITION_LIST):
      return {
        ...state,
        loading: false,
        conditionList: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SYMPTOM):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SYMPTOM):
    case SUCCESS(ACTION_TYPES.UPDATE_SYMPTOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/condition';

// Actions

// export const getEntities: ICrudGetAllAction<Icondition> = (page, size, sort) => ({
//   type: ACTION_TYPES.FETCH_SYMPTOM_LIST,
//   payload: axios.get<Icondition>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
// });
export const getEntities: (page?, size?, sort?) => IPayload<ICondition> | IPayloadResult<ICondition> = (page, size, sort) => async dispatch => {
  const requestUrl = 'api/regions/type/COUNTRY';
  const countries = await dispatch({
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES,
    payload: axios.get<IRegion>(`${requestUrl}`),
  })

  const symptoms = await dispatch({
    type: ACTION_TYPES.FETCH_CONDITION_LIST,
    payload: axios.get<ICondition>(`${'api/conditions'}?cacheBuster=${new Date().getTime()}`),
  });

  return symptoms
}

export const getEntity: ICrudGetAction<ICondition> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SYMPTOM,
    payload: axios.get<ICondition>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICondition> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SYMPTOM,
    payload: axios.post('api/add-condition', cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICondition> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SYMPTOM,
    payload: axios.post('api/edit-condition', cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICondition> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SYMPTOM,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
