import { Moment } from 'moment';
import { IDriver } from 'app/shared/model/driver.model';
import { IRoute } from 'app/shared/model/route.model';
import { IPatientCase } from 'app/shared/model/patient-case.model';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';

export interface IJourney {
  endVillage?: any;
  endHealthcareFacility?: any;
  startVillage?: any;
  startHealthcareFacility?: any;
  vehicleType?: any;
  ambulance?: any;
  payment?: any;
  id?: string;
  status?: JourneyStatus;
  incidentReportDescription?: string;
  incidentReport?: any; 
  incidentReported?: boolean;
  totalCost?: number;
  totalDurationSeconds?: number;
  totalDistance?: number;
  createdAt?: string;
  driver?: IDriver;
  route?: IRoute;
  patientCase?: IPatientCase;
}

export const defaultValue: Readonly<IJourney> = {
  incidentReported: false,
};
