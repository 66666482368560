import React from 'react';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {Alert, Button, Col, Row} from 'reactstrap';
import {AvField, AvForm} from 'availity-reactstrap-validation';

import {IRootState} from 'app/shared/reducers';
import {login, LOGOUT_TYPES} from 'app/shared/reducers/authentication';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

const loginWrapperStyle = {
  display: "block",
  textAlign: "center" as const
}

const loginAreaStyle = {
  display: "inline-block",
  marginLeft: "auto",
  marginRight: "auto",
}

const loginFormStyle = {
  border: "1px solid #e0e0e0",
  borderRadius: "0.5rem",
  textAlign: "left" as const,
  padding: "1em",
  width: "25em"
}

const logoutMessageStyle = {
  padding: "1em",
  color: "darkred"
}

const welcomeTextStyle = {
  fontSize: "larger",
  fontWeight: "bold" as "bold"
}

const loginRequestStyle = {
  padding: "0.5em"
}

export const Login = (props: ILoginProps) => {

  const {loginError} = props;

  const handleLogin = (event, errors, values) => {
    if (errors.length === 0) {
      const {username = undefined, password = undefined} = {...values};
      if (username && password) {
        props.history.push("/login")
        props.login(username, password);
      }
    }
  }

  const {location, isAuthenticated, logoutType} = props;
  const {from} = (location.state as any) || {from: {pathname: '/', search: location.search}};
  if (isAuthenticated) {
    return <Redirect to={from}/>;
  }

  let logoutMessage;
  if (!loginError) {
    let text;
    switch (logoutType) {
      case LOGOUT_TYPES.USER:
        text = "You have signed out"
        break;
      case LOGOUT_TYPES.TIMEOUT:
        text = "You have been logged out due to inactivity"
        break;
      case LOGOUT_TYPES.ERROR:
        text = "You have been logged because a system error occurred"
        break;
      default:
        break;
    }
    if (text) {
      logoutMessage = <div style={logoutMessageStyle}>{text}</div>
    }
  }

  return (
    <div style={loginWrapperStyle}>
    <div style={loginAreaStyle}>
      { logoutMessage }
      <div style={welcomeTextStyle}>Welcome to the m-mama Web Portal</div>
      <div style={loginRequestStyle}>Please login to continue</div>
    <div style={loginFormStyle}>
    <AvForm onSubmit={handleLogin}>
        <Row>
          <Col md="12">
            {loginError ? (
              <Alert color="danger">
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
              </Alert>
            ) : null}
          </Col>
          <Col md="12">
            <AvField
              name="username"
              label="Username"
              placeholder="Your username"
              required
              errorMessage="Username cannot be empty!"
              autoFocus
            />
            <AvField
              name="password"
              type="password"
              label="Password"
              placeholder="Your password"
              required
              errorMessage="Password cannot be empty!"
            />
          </Col>
        </Row>
        <div className="mt-1">&nbsp;</div>

      <div style={{"textAlign": "right"}}>
        <Button color="primary" type="submit">
          Sign in
        </Button>
      </div>
    </AvForm>
    </div>
    </div>
    </div>
  )
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  logoutType: authentication.logoutType
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
