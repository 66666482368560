import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './healthcare-facility.reducer';
import { IHealthcareFacility } from 'app/shared/model/healthcare-facility.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IHealthcareFacilityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const HealthcareFacilityDetail = (props: IHealthcareFacilityDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { healthcareFacilityEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          HealthcareFacility [<b>{healthcareFacilityEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{healthcareFacilityEntity.name}</dd>
          <dt>
            <span id="level">Level</span>
          </dt>
          <dd>{healthcareFacilityEntity.level}</dd>
          <dt>
            <span id="facilityCode">Facility Code</span>
          </dt>
          <dd>{healthcareFacilityEntity.facilityCode}</dd>
          <dt>
            <span id="contactName">Contact Name</span>
          </dt>
          <dd>{healthcareFacilityEntity.contactName}</dd>
          <dt>
            <span id="phoneNumber">Phone Number</span>
          </dt>
          <dd>{healthcareFacilityEntity.phoneNumber}</dd>

          <dt>
            <span id="phoneNumber2">Phone Number 2</span>
          </dt>
          <dd>{healthcareFacilityEntity.phone_number_2}</dd>
          <dt>
            <span id="phoneNumber3">Phone Number 3</span>
          </dt>
          <dd>{healthcareFacilityEntity.phone_number_3}</dd>

          <dt>
            <span id="locationLat">Latitude</span>
          </dt>
          <dd>{healthcareFacilityEntity.locationLat}</dd>
          <dt>
            <span id="locationLon">Longitude</span>
          </dt>
          <dd>{healthcareFacilityEntity.locationLon}</dd>
          <dt>Council</dt>
          <dd>{healthcareFacilityEntity.council ? healthcareFacilityEntity.council.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/healthcare-facility" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/healthcare-facility/${healthcareFacilityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ healthcareFacility }: IRootState) => ({
  healthcareFacilityEntity: healthcareFacility.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareFacilityDetail);
