import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label,ButtonGroup } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { hasAnyPermission } from 'app/shared/auth/permissions';

import { getEntity, updateEntity, createEntity, reset } from './rejection-reason.reducer';

export interface IRejectionReasonDriverUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const IncidentReportUpdate = (props: IRejectionReasonDriverUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { rejectionReasonDriverEntity, loading, updating } = props;
  const [countryId, setCountryId] = useState(null)
  const [driverCountryId, setDriverCountryId] = useState(null)
  const [activeStatus, setActiveStatus] = useState(true);


  const handleClose = () => {
    props.history.push('/rejection-reasons-driver');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.countries.length > 0) {
      // const activeCountry = props.countries.find((country:any) => country.name === "Lesotho")
      const activeCountry = props.isAdmin ? props.countries[0].id : props.userCountryId
      setCountryId(activeCountry)
    } 
  }, [props.countries])

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const cid = isNew ? countryId: rejectionReasonDriverEntity.countryId;
      const country = props.countries.find(c => c.id === cid);


      const entity = {
        ...rejectionReasonDriverEntity,
        ...values,
        country
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.rejectionReasonDriver.home.createOrEditLabel">Create or edit a Rejection Reason (Driver)</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : rejectionReasonDriverEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="rejection-reason-driver-reports-id">ID</Label>
                  <AvInput id="rejection-reason-driver-reports-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
               
             

              <AvGroup>
                <Label id="enConditionLabel" for="followup-enCondition">
                Rejection Reason (English)
                </Label>
                <AvField
                  id="followup-enCondition"
                  type="text"
                  name="enRejectionReason"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="stConditionLabel" for="followup-stCondition">
                Rejection Reason (Local Language)
                </Label>
                <AvField
                  id="followup-stCondition"
                  type="text"
                  name="stRejectionReason"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                  }}
                />
              </AvGroup>

              {/* props.countries && props.countries.length > 0 && (
                <AvGroup>
                  <Label for="followup-country">Driver Country</Label>
                  <AvInput id="followup-country" type="select" className="form-control" name="rejectionReasonDriverCountryId"  value = {isNew? driverCountryId: rejectionReasonDriverEntity.rejectionReasonDriverCountryId} >
                    {props.countries
                      ? props.countries.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )*/}
              
              {props.countries && props.countries.length > 0 && (
                <AvGroup>
                  <Label for="followup-country">Country</Label>
                  <AvInput id="followup-country" type="select" className="form-control" name="countryId"  value = {isNew? countryId : rejectionReasonDriverEntity.countryId} >
                    {props.countries
                      ? props.countries.map(country => (
                        (country.id === (props.isAdmin ? country.id : isNew ? props.userCountryId: rejectionReasonDriverEntity.countryId)) && <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )

              }



              <Button tag={Link} id="cancel-save" to="/rejection-reasons-driver" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  rejectionReasonDriverEntity: storeState.rejectionReasonDriver.entity,
  loading: storeState.rejectionReasonDriver.loading,
  updating: storeState.rejectionReasonDriver.updating,
  updateSuccess: storeState.rejectionReasonDriver.updateSuccess,
  countries: storeState.authentication.countries,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentReportUpdate);
