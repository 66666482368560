import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import TableauReport from 'tableau-react';
import {getReportMenu} from 'app/shared/reducers/authentication';
import {IRootState} from 'app/shared/reducers';
const options = {
    height: window.innerHeight ? window.innerHeight : '100%' ,
    width: '100%',
    hideTabs: true,
    toolbar:false,
    hideToolbar:true,
    // All other vizCreate options are supported here, too
    // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
};

 
function Report(props:any){

    // const ref=useRef(null);
    // const [vizUrl,setVizUrl] = useState("");
    const {reportMenus }= props
    useEffect(() => {
        
    }, [reportMenus]);

    let vizUrl = '';
    if(reportMenus.length > 0 ){
        for(const key in reportMenus){
            if(props.match.params.type === reportMenus[key].slug){
                vizUrl = reportMenus[key].reportUrl;
            }
        }
        
    }
        


/*
        switch(props.match.params.type){
    
          //  case 'me-report' :vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/MEDashboard/MEDASHBOARD?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
          case 'me-report' :vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/M-MAMAMEDASHBOARD/MEDASHBOARD?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
           
             case 'dispatcher-dashboard' :vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/M-MAMADISPATCHERDASHBOARD/DISPATCHERDASHBOARD?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
        
            case 'government-dashboard' :vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/M-MAMAGOVERNMENTDASHBOARD/GOVERNMENTDASHBOARD?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
           
            case 'case-report' :vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/m-mamaCaseReport/CASEREPORT?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
              
            case 'payment-report' : vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/M-MAMAPAYMENTREPORT/PAYMENTREPORT?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
          
            default : vizUrl1 = 'https://prod-useast-b.online.tableau.com/t/mmamaict/views/M-MAMAPAYMENTREPORT/PAYMENTREPORT?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'; break;
          
          } */ 
          console.log("vizUrl23",vizUrl.split("?"));
        
    return(
        <div>
            { vizUrl ?
             <TableauReport
             url={vizUrl.split("?")[0]}
            // filters={filters}
             // parameters={parameters}
             options={options} // vizCreate options
             // Overwrite default query params
             // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
            query={"?"+vizUrl.split("?")[1]}
           />
            :
            <></>
            
            }
           
        </div>
    )
}

const mapStateToProps = ({ authentication}: IRootState) => ({
    reportMenus: authentication.reportMenus
   
  });
  
  const mapDispatchToProps = { getReportMenu };
  
  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;
  
  export default connect(mapStateToProps, mapDispatchToProps)(Report);
// export default Report;