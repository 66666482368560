import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Route from './route';
import Symptom from './symptom';
import Followup from './followup';
import Condition from './condition';
import HealthcareFacility from './healthcare-facility';
import HealthcareFacilityLevel from './healthcare-facility-level';
import Driver from './driver';
import Village from './village';
import Payment from './payment';
import Ambulance from './ambulance';
// import IncidentReport from './incident-report';
// import Council from "./council";
// import Country from "./country";
// import District from "./district";
import Region from './region';
import DspatchCenter from './dispatchCenter';
import VillageRoutes from './village-route'
import VehicleType from './vehicleType';
import IncidentReport from './incident-report';
import RejectionReasonsDriver from './rejection-reason';
import RejectionReasonsHF from './rejection-reason-hf';
import CaseManagement from './case-management';
import {IRootState} from 'app/shared/reducers';
import {connect} from 'react-redux';


const Routes = ({ match, permissionSettings, userRoles }) => {
  
  const array = [];
  const finalPermissions = permissionSettings
                            .filter((item:any) => item.role === userRoles[0])
                            .map((item:any) => {return {[item.role] : item.permissions}});

  finalPermissions.map((item: any) => {
      array.push(...Object.values(item))
  })                          
  
  console.log("ALL PERMISSIONS>>", userRoles, permissionSettings, finalPermissions , array[0]?.includes("ROUTES_VIEW"));
  
  return (
  <div>
    <Switch>
      {/* prettier-ignore */}
      {/* {(1 || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}council`} component={Council} />}
      {(1 || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}country`} component={Country} />}
      {(1 || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}district`} component={District} />}
      {(1 || userRoles[0] === "ADMIN") ? <ErrorBoundaryRoute path={`${match.url}incident-report`} component={IncidentReport} />*/}
      {(array[0]?.includes("AMBULANCE_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}ambulance`} component={Ambulance} /> }
      {(array[0]?.includes("DRIVER_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}driver`} component={Driver} />}
      {(array[0]?.includes("FACILITY_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}healthcare-facility`} component={HealthcareFacility} />}
      {(array[0]?.includes("FACILITY_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}healthcare-facility-level`} component={HealthcareFacilityLevel} />}
      {(array[0]?.includes("PAYMENTS_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}payment`} component={Payment} />}
      {(array[0]?.includes("ROUTES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}route`} component={Route} />}
      {(array[0]?.includes("SYMPTOM_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}symptom`} component={Symptom} />}
      {(array[0]?.includes("CONDITION_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}condition`} component={Condition} />}
      {(array[0]?.includes("FOLLOWUP_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}followup`} component={Followup} />}
      {(array[0]?.includes("VEHICLE_TYPE_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}vehicle-type`} component={VehicleType} />}
      {(array[0]?.includes("VILLAGE_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}village`} component={Village} />}
      {(array[0]?.includes("REGION_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}region`} component={Region} />}
      {(array[0]?.includes("DISPATCH_CENTER_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}dispatch-center`} component={DspatchCenter} />}
      {(array[0]?.includes("ROUTES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}village-routes`} component={VillageRoutes} />}
      {(array[0]?.includes("INCIDENT_REPORT_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}incident-report`} component={IncidentReport} />}
      {(array[0]?.includes("REJECTION_REASON_DRIVER_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}rejection-reasons-driver`} component={RejectionReasonsDriver} />}
      {(array[0]?.includes("REJECTION_REASON_HF_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}rejection-reasons-hf`} component={RejectionReasonsHF} />}
      {(array[0]?.includes("CASE_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}case-management`} component={CaseManagement} />}
      <Redirect to={{pathname: "/"}} />
    </Switch>
  </div>
)};

const mapStateToProps = (storeState: IRootState) => ({
  permissionSettings: storeState.permissionSettings.settings,
  userRoles: storeState.authentication.account.roles
});

export default connect(mapStateToProps)(Routes);
