import axios from 'axios';
import {
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudSearchAction,
  ICrudDeleteAction,
  IPayloadResult
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IHealthcareFacility, defaultValue } from 'app/shared/model/healthcare-facility.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IVillage} from "app/shared/model/village.model";

export const ACTION_TYPES = {
  FETCH_HEALTHCAREFACILITY_SEARCH: 'healthcareFacility/FETCH_HEALTHCAREFACILITY_SEARCH',
  FETCH_HEALTHCAREFACILITY_LIST: 'healthcareFacility/FETCH_HEALTHCAREFACILITY_LIST',
  ALL_FETCH_HEALTHCAREFACILITY_LIST: 'healthcareFacility/ALL_FETCH_HEALTHCAREFACILITY_LIST',
  FETCH_HEALTHCAREFACILITY: 'healthcareFacility/FETCH_HEALTHCAREFACILITY',
  CREATE_HEALTHCAREFACILITY: 'healthcareFacility/CREATE_HEALTHCAREFACILITY',
  UPDATE_HEALTHCAREFACILITY: 'healthcareFacility/UPDATE_HEALTHCAREFACILITY',
  DELETE_HEALTHCAREFACILITY: 'healthcareFacility/DELETE_HEALTHCAREFACILITY',
  RESET: 'healthcareFacility/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHealthcareFacility>,
  allFacility: [] as ReadonlyArray<IHealthcareFacility>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type HealthcareFacilityState = Readonly<typeof initialState>;

// Reducer

export default (state: HealthcareFacilityState = initialState, action): HealthcareFacilityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_SEARCH):
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_LIST):
    case REQUEST(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_HEALTHCAREFACILITY):
    case REQUEST(ACTION_TYPES.UPDATE_HEALTHCAREFACILITY):
    case REQUEST(ACTION_TYPES.DELETE_HEALTHCAREFACILITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_SEARCH):
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITY):
    case FAILURE(ACTION_TYPES.CREATE_HEALTHCAREFACILITY):
    case FAILURE(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_HEALTHCAREFACILITY):
    case FAILURE(ACTION_TYPES.DELETE_HEALTHCAREFACILITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_SEARCH):
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
      case SUCCESS(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
        return {
          ...state,
          loading: false,
          allFacility: action.payload.data,
        };
      
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_HEALTHCAREFACILITY):
    case SUCCESS(ACTION_TYPES.UPDATE_HEALTHCAREFACILITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_HEALTHCAREFACILITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/healthcare-facilities';

// Actions

export const getEntities: (page?, size?, sort?, countryId?) => IPayload<IHealthcareFacility> | IPayloadResult<IHealthcareFacility> = (page?, size?, sort?, countryId?) => {
// export const getEntities: ICrudGetAllAction<IHealthcareFacility> = (page?, size?, sort?, countryId?) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&countryId=${countryId}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITY_LIST,
    payload: axios.get<IHealthcareFacility>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllFacility=(countryId = '') => {
  // export const getEntities: ICrudGetAllAction<IHealthcareFacility> = (page?, size?, sort?, countryId?) => {
    const requestUrl = countryId !== '' || countryId != null?  `${apiUrl+"?countryId="+countryId}` :  `${apiUrl}`;
    return {
      type: ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST,
      payload: axios.get<IHealthcareFacility>(`${requestUrl}`),
    };
  };


export const getHfByName: (searchString) => IPayload<IHealthcareFacility> | IPayloadResult<IHealthcareFacility> = (searchString) => {
  // export const getEntities: ICrudGetAllAction<IHealthcareFacility> = (page?, size?, sort?, countryId?) => {
    const requestUrl = `api/search/search-healthcare-facility-by-name/`;
    return {
      type: ACTION_TYPES.FETCH_HEALTHCAREFACILITY_LIST,
      payload: axios.get<IHealthcareFacility>(`${requestUrl}${searchString+'?'}cacheBuster=${new Date().getTime()}`),
    };
  };


export const getFilteredEntities: ICrudSearchAction<IHealthcareFacility> = ( search: string, size?: number ) => {
    const page = 0;
    const defaultSize = 30;
    const requestUrl = `${apiUrl}?search=${search}&size=${size || defaultSize}&page=${page}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITY_SEARCH,
    payload: axios.get<IHealthcareFacility>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IHealthcareFacility> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITY,
    payload: axios.get<IHealthcareFacility>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IHealthcareFacility> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HEALTHCAREFACILITY,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IHealthcareFacility> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HEALTHCAREFACILITY,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IHealthcareFacility> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HEALTHCAREFACILITY,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
