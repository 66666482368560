import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Row, Table, ButtonGroup } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IRootState } from 'app/shared/reducers';
import { getPatientCases } from '../case-management/case-management.reducer';
import { getSortState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getMMamaSettings as getSettings } from 'app/modules/administration/mmama-settings/mmama-settings.reducer';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { fromCents, toCents } from 'app/shared/util/money-utils';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import CaseManagementModal from './case-management-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllByType } from '../../entities/region/region.reducer';
import Loader from "react-loader-spinner";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
  summary: {
    backgroundColor: "#ecf0f1"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: 'block',
    backgroundColor: "#eff5f7"
  },
}));

export interface ICaseManagementProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const PatientTypeMap = {
  MOTHER: 'Mother',
  BABY: 'Baby',
  BOTH: 'Mother & Baby',
};

export const CaseManagement = (props: ICaseManagementProps) => {
  const classes = useStyles();

  const [activeCountry, setActiveCountry] = useState('');
  const [filterList, setFilterList] = useState({
    countryId: '',
    regionId: '',
    districtId: '',
    activePage: 0,
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(
        {
          hash: props.location.hash,
          key: props.location.key,
          pathname: props.location.pathname,
          search: '?page=1&sort=createdDateTime,asc',
          state: props.location.state,
        },
        ITEMS_PER_PAGE
      ),
      props.location.search
    )
  );
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [mmamaSettingsDetail, setMmamaSettingsDetail] = useState({
    paymentEditThreshold: 0,
    costKm: 0,
    flatDriverFee: 0,
    countryId: '0',
    countryName: '',
    paymentMethod: '',
    currencySymbol: ''
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [regionFilterList, setRegionFilterList] = useState([]);
  const [districtFilterList, setDistrictFilterList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const { match, loading, patientCaseList, totalItems, mmamaSettings, userRoles, permissionSettings } = props;

  const permissionArray = permissionSettings.filter((item:any) => item.role === userRoles[0]);

  useEffect(() => {
    props.getSettings();
  }, []);

  useEffect(() => {
    if (filterList.countryId === '') {
      Promise.resolve(props.getAllByType("COUNTRY")).then((data: any) => {
        const filteredCountries = data.action.payload.data.filter(country => {
          return  props.isAdmin ? 1  : country.id === props.userCountryId;
        })
        setCountryList([...filteredCountries]);
        // setActiveCountry(data.action.payload.data[0]);
      });

      Promise.resolve(props.getAllByType("REGION")).then((data: any) => {
        setRegionList([...data.action.payload.data]);
        setRegionFilterList([...data.action.payload.data]);
      });

      Promise.resolve(props.getAllByType("DISTRICT")).then((data: any) => {
        setDistrictList([...data.action.payload.data]);
        setDistrictFilterList([...data.action.payload.data]);
      });

    }
  }, [props.userCountryId]);

  useEffect(() => {
    if (activeCountry && startDate && endDate) {
      const start = new Date(new Date(startDate).setDate(startDate?.getDate() + 90));
      if (endDate > start) {
        setEndDate('');
      } else {
        const filterData = {
          ...filterList,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        };
        filterData.activePage = paginationState.activePage - 1;
        props.getPatientCases(filterData);
        setShowDownload(true);
      }
    }
  }, [activeCountry, startDate, endDate, selectedRegion, selectedDistrict, paginationState.activePage, paginationState.order, refreshData]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const onDateChange = (date: any, text: string) => {
    if(text === 'startDate') {
      setStartDate(date);
      setEndDate(new Date(new Date(date).setDate(date?.getDate() + 90)));
    } else {
      setEndDate(date);
    }
  };

  const onCountryChange = (countryId: string) => {
    setActiveCountry(countryId);
    setFilterList({ ...filterList, countryId, regionId: '', districtId: ''});

    mmamaSettings.forEach((mmamaSetting) => {
      if (countryId === mmamaSetting.country.id) {
        setMmamaSettingsDetail(mmamaSetting);
      }
    });

    const newFilteredRegion = regionList.filter(region => region.parentId === countryId);
    setRegionFilterList([...newFilteredRegion]);

    setSelectedDistrict("");
    setDistrictFilterList([]);
  }

  const onRegionChange = (id: string) => {
    setSelectedRegion(id);

    setFilterList({ ...filterList, regionId: id, districtId: ''});

    const newFilteredDistrict = districtList.filter(district => district.parentId === id);
    setDistrictFilterList([...newFilteredDistrict]);
  }

  const onDistrictChange = (id: string) => {
    setSelectedDistrict(id);
    setFilterList({ ...filterList, districtId: id});
  }

  const changeActiveStatus = (id:any, status: any) => {
    console.log("ID >> ", id, "STATUS >>> ", status);
    setModalData({
      modalText: "Change Case Active Status",
      modalCode: "activeStatus",
      caseId: id,
      caseStatus: status
    });
    setModalOpen(true);
  }

  const changeJourneyDriver = (id: any, name: string, type: string, payStatus: string) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Journey Driver",
      modalCode: "editDriver",
      journeyId: id,
      countryId: activeCountry,
      driverName: name,
      driverType: type,
      paymentStatus: payStatus
    });
    setModalOpen(true);
  }

  const changePaymentStatus = (id: any, payment: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Change Payment Status",
      modalCode: "paymentStatus",
      journeyId: id,
      paymentId: payment
    });
    setModalOpen(true);
  }

  const changePickupLocation = (type: string, endType: string, endId: any, name: string, journey: any) => {
    // console.log("ID >> ", id);
    setModalData({
      modalText: "Change Pickup Location",
      modalCode: 'changePickupLocation',
      destinationId: endId,
      pickupType: type,
      endPickupType: endType,
      pickupLocationName: name,
      countryId: activeCountry,
      journeyId: journey
    });
    setModalOpen(true);
  }

  const changeDestination = (type: string, endType: string, startId: any, name: string, journey: any) => {
    // console.log("ID >> ", id);
    setModalData({
      modalText: "Change Destination",
      modalCode: 'changeDestination',
      pickupType: type,
      endPickupType: endType,
      pickupId: startId,
      destinationName: name,
      countryId: activeCountry,
      journeyId: journey
    });
    setModalOpen(true);
  }

  const changeVehicleType = (id: any, country: any, journey: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Change Vehicle Type",
      modalCode: 'changeVehicleType',
      vehicleTypeId: id,
      journeyId: journey,
      countryId: country
    });
    setModalOpen(true);
  }

  const editMotherFollowup = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Mother Followup",
      modalCode: 'editMotherFollowup',
      emergencyType: type,
      followupId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }

  const editBabyFollowup = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Baby Followup",
      modalCode: 'editBabyFollowup',
      emergencyType: type,
      followupId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }
  const editMotherCondition = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Mother Condition",
      modalCode: 'editMotherCondition',
      emergencyType: type,
      conditionId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }

  const editBabyCondition = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Baby Condition",
      modalCode: 'editBabyCondition',
      emergencyType: type,
      conditionId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }

  const editMotherSymptoms = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Mother Symptoms",
      modalCode: 'editMotherSymptoms',
      emergencyType: type,
      symptomsId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }

  const editBabySymptoms = (id: any, type: string, caseid: any) => {
    console.log("ID >> ", id);
    setModalData({
      modalText: "Edit Baby Symptoms",
      modalCode: 'editBabySymptoms',
      emergencyType: type,
      symptomsId: id,
      countryId: activeCountry,
      caseId: caseid
    });
    setModalOpen(true);
  }

  const downloadCases = async () => {
    // call API here
    console.log("DOWNLOAD CASES API CALLED");
    setDownloadLoader(true);

    await axios.get(`api/exportcases?${(filterList.countryId !== '' && filterList.countryId) ?
              `&countryId=${filterList.countryId}` : (filterList.regionId !== '' && filterList.regionId) ? `&regionId=${filterList.regionId}`
              : (filterList.districtId !== '' && filterList.districtId) ? `&districtId=${filterList.districtId}` : ""}&startDate=${moment(startDate).format('YYYY-MM-DD')}
              &endDate=${moment(endDate).format('YYYY-MM-DD')}`,{
      responseType: 'blob'
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Patient Cases - ${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadLoader(false);
    }).catch(error => {
      console.log("ERROR", error);
      setDownloadLoader(false);
    });
  }

  return (
    <div>
      <CaseManagementModal
        opened={modalOpen}
        toggle={() => { setModalOpen(false); setModalData({}); }}
        modalData={modalData}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
      <h2 id="case-management-heading">
        Patient Case Management
        {(((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_EXPORT")) || props.isAdmin) && showDownload && patientCaseList.length > 0)
          && (<Button className="btn btn-primary float-right download-payments" id="download-payments"
          onClick={() => downloadCases()}
          disabled={downloadLoader}
        >
          {downloadLoader ? <Loader type="TailSpin" color="#FFFFFF" height={15} width={15} > Loading...</Loader> :
          <span><FontAwesomeIcon icon="save" />
          &nbsp; Download</span>}
        </Button>)}
      </h2>

      <AvForm model={{}}>
        <AvGroup>
          <Label id="typeLabel" for="region-type">
            Country
          </Label>
          {countryList && (
            <AvInput
              id="region-type"
              type="select"
              className="form-control"
              name="type"
              value={activeCountry}
              onChange={(event: any) => onCountryChange(event.target.value)}
            >
              <option value={""}>{""}</option>
              {countryList.length > 0 &&
                countryList?.map(country => {
                  return (
                    <option value={country.id} key={'country-' + country.name}>
                      {country.name}
                    </option>
                  );
                })}
            </AvInput>
          )}
        </AvGroup>

        <AvGroup>
          <Label for="case-management-regions">Select Region</Label>
          <AvInput
            id="case-management-regions"
            type="select"
            className="form-control"
            name="caseManagementRegion"
            onChange={(e: any) => onRegionChange(e.target.value)}
            value={selectedRegion}
          >
            <option value={""}>{"All"}</option>
            {regionFilterList && activeCountry &&
               regionFilterList.map(region => (
                <option value={region.id} key={region.id} >
                  {region.name}
                </option>
              ))}
          </AvInput></AvGroup>

          <AvGroup>
          <Label for="case-management-districts">Select District</Label>
          <AvInput
            id="case-management-districts"
            type="select"
            className="form-control"
            name="caseManagementDistrict"
            onChange={(e: any) => onDistrictChange(e.target.value)}
            value={selectedDistrict}
          >
            <option value={""}>{"All"}</option>
            {districtFilterList && selectedRegion &&
              districtFilterList.map(district => (
                <option value={district.id} key={district.id} >
                  {district.name}
                </option>
              ))}
          </AvInput></AvGroup>

        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <Label id="startDate" for="startDate">
                Start Date
              </Label>
            </div>
            <div style={{ width: '50%', marginLeft: '5px' }}>
              <Label id="endDate" for="endDate">
                End Date
              </Label>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <DatePicker
                id="case-management-startDate"
                selected={startDate}
                name="startDate"
                value={startDate}
                placeholder="YYYY-MM-DD"
                // minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                onChange={(date: any) => onDateChange(date, 'startDate')}
              />
            </div>
            <div style={{ width: '50%', marginLeft: '5px' }}>
              <DatePicker
                id="case-management-endDate"
                selected={endDate}
                name="endDate"
                value={endDate}
                placeholder="YYYY-MM-DD"
                minDate={startDate && new Date(new Date(startDate).setDate(startDate?.getDate() + 1))}
                maxDate={startDate && new Date(new Date(startDate).setDate(startDate?.getDate() + 90))}
                onChange={(date: any) => onDateChange(date, 'endDate')}
              />
            </div>
          </div>
        </div>
      </AvForm>
      <br />

      <>
        {activeCountry && startDate && endDate ? (
          patientCaseList && patientCaseList.length > 0 ? (
            <>
              <div className={classes.root}>
                {patientCaseList && patientCaseList.map((patient, i) => (
                  <Accordion key={i}>
                    <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className={classes.heading}>
                        Patient Case <b>[{patient?.id}] [{convertDateTimeFromServer(patient?.createdAt)}] [{patient?.journeys[0]?.startHealthcareFacility ? `${patient?.journeys[0]?.startHealthcareFacility?.council?.parent?.name} / ${patient?.journeys[0]?.startHealthcareFacility?.council?.parent?.parent?.name} ` : `${patient?.journeys[0]?.startVillage?.council?.parent?.name} / ${patient?.journeys[0]?.startVillage?.council?.parent?.parent?.name} `}]</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <Typography>
                        <b>Patient Type:</b> {PatientTypeMap[patient?.type]}
                      </Typography>
                      <Typography>
                        <b>Patient Name:</b> {patient?.patient?.firstName} {patient?.patient?.lastName}
                      </Typography>
                      <br />
                      {/* <Typography>
                        <b>Primary Contact Type:</b> ({patient?.primaryContactType})
                      </Typography>
                      <Typography>
                        <b>Primary Contact Name:</b> {patient?.primaryContactName || 'Name Unspecified'}
                      </Typography>
                      <Typography>
                        <b>Primary Contact Number:</b> {patient?.primaryContactNumber || 'Not Specified'}
                      </Typography>
                      <Typography>
                        <b>Secondary Contact Number:</b> {patient?.secondaryContactNumber || 'Not Specified'}
                      </Typography>
                      <br /> */}
                      <Typography>
                        <b>Case Status:</b> {patient?.caseStatus}
                      </Typography>
                      <br />

                      {patient?.motherSymptoms?.length > 0 && (
                        <>
                          <Typography>
                            <b>MOTHER SYMPTOMS: </b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editMotherSymptoms(patient?.motherSymptoms?.map(item => item.id), patient?.motherSymptoms[0]?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.motherSymptoms[0]?.emergencyType}
                          </Typography>
                          {patient?.motherSymptoms?.map((item, index) => (
                            <>
                              <Typography key={index}>
                                <b>Symptom {index + 1}:</b> {item.enCondition}
                              </Typography>
                            </>
                          ))}
                        </>
                      )}

                      <br />

                      {patient?.babySymptoms?.length > 0 && (
                        <>
                          <Typography>
                            <b>BABY SYMPTOMS:</b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editBabySymptoms(patient?.babySymptoms?.map(item => item.id), patient?.babySymptoms[0]?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.babySymptoms[0]?.emergencyType}
                          </Typography>
                          {patient?.babySymptoms?.map((item, index) => (
                            <>
                              <Typography key={index}>
                                <b>Symptom {index + 1}:</b> {item.enCondition}
                              </Typography>
                            </>
                          ))}
                        </>
                      )}

                      <br />

                      <Typography>
                        <b>Is Bypass:</b> {patient?.bypass ? "True" : "False"}
                      </Typography>
                      <br />

                      <Typography>
                        <b>Is Emergency:</b> {patient?.emergency ? "True" : "False"}
                      </Typography>
                      <br />

                      {patient?.motherFollowupId && Object.keys(patient?.motherFollowupId).length > 0 && (
                        <>
                          <Typography>
                            <b>MOTHER FOLLOWUP: </b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editMotherFollowup(patient?.motherFollowupId?.id, patient?.motherFollowupId?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.motherFollowupId?.emergencyType}
                          </Typography>
                          <Typography>
                            <b>Followup:</b> {patient?.motherFollowupId?.enFollowup}
                          </Typography>
                        </>
                      )}

                      <br />

                      {patient?.babyFollowupId && Object.keys(patient?.babyFollowupId).length > 0 && (
                        <>
                          <Typography>
                            <b>BABY FOLLOWUP: </b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editBabyFollowup(patient?.babyFollowupId?.id, patient?.babyFollowupId?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.babyFollowupId?.emergencyType}
                          </Typography>
                          <Typography>
                            <b>Followup:</b> {patient?.babyFollowupId?.enFollowup}
                          </Typography>
                        </>
                      )}

                      <br />
                      {patient?.motherConditionId && Object.keys(patient?.motherConditionId).length > 0 && (
                        <>
                          <Typography>
                            <b>MOTHER CONDITION: </b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editMotherCondition(patient?.motherConditionId?.id, patient?.motherConditionId?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.motherConditionId?.emergencyType}
                          </Typography>
                          <Typography>
                            <b>Condition:</b> {patient?.motherConditionId?.enFollowup}
                          </Typography>
                        </>
                      )}

                      <br />

                      {patient?.babyConditionId && Object.keys(patient?.babyConditionId).length > 0 && (
                        <>
                          <Typography>
                            <b>BABY CONDITION: </b>
                            {
                              ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  editBabyCondition(patient?.babyConditionId?.id, patient?.babyConditionId?.emergencyType, patient?.id)
                                }}
                                size="sm"
                              ><FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                              </Button>
                            }
                          </Typography>
                          <Typography>
                            <b>Emergency Type:</b> {patient?.babyConditionId?.emergencyType}
                          </Typography>
                          <Typography>
                            <b>Condition:</b> {patient?.babyConditionId?.enFollowup}
                          </Typography>
                        </>
                      )}

                      <br />

                      <div>
                      <Typography>
                        <b>Active Status: </b>
                        {
                          ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) ?
                            <ButtonGroup style={{marginLeft: "30px"}}>
                              <Button
                                color={patient?.activated ? 'primary' : 'secondary'}
                                // value={activeStatus}
                                onClick={() => {
                                  if (patient?.activated !== true) {
                                    changeActiveStatus(patient?.id, patient?.activated)
                                  }
                                }}
                              >
                                Active
                              </Button>


                              <Button
                                color={!patient?.activated ? 'primary' : 'secondary'}
                                // value={activeStatus}
                                onClick={() => {
                                  if (patient?.activated !== false) {
                                    changeActiveStatus(patient?.id, patient?.activated)
                                  }
                                }}
                              >
                                Inactive
                              </Button>

                            </ButtonGroup>
                            : patient?.activated ? "Active" : "Inactive"
                        }
                      </Typography>

                      </div>
                      <div>
                        {patient?.journeys &&
                          patient?.journeys.map((journey, j) => (
                            <Accordion key={j}>
                              <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>Journey {j + 1} <b> [{convertDateTimeFromServer(journey?.createdAt)}] [Pickup: {journey?.startHealthcareFacility ? `${journey?.startHealthcareFacility?.name}` : journey?.startVillage ? `${journey?.startVillage?.name}` : ``}] [Destination: {journey?.endHealthcareFacility ? `${journey?.endHealthcareFacility?.name} ${journey?.endHealthcareFacility?.level}` : journey?.endVillage ? `${journey?.endVillage?.name}` : ``}] </b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className={classes.details}>
                                <Typography>
                                  <b>Journey Date: </b> {convertDateTimeFromServer(journey?.createdAt)}
                                </Typography>
                                <Typography>
                                  <b>Pickup {journey?.startHealthcareFacility ? `Facility` : journey?.startVillage ? `Village` : ``}: </b> {journey?.startHealthcareFacility ? `${journey?.startHealthcareFacility?.name} / ${journey?.startHealthcareFacility?.council?.name} / ${journey?.startHealthcareFacility?.council?.parent?.name} / ${journey?.startHealthcareFacility?.council?.parent?.parent?.name} ` : journey?.startVillage ? `${journey?.startVillage?.name} / ${journey?.startVillage?.council?.name} / ${journey?.startVillage?.council?.parent?.name} / ${journey?.startVillage?.council?.parent?.parent?.name} ` : ``} {" "}
                                  {
                                    (journey?.startHealthcareFacility || journey?.startVillage) &&
                                    ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        changePickupLocation(
                                          journey?.startHealthcareFacility ? "HEALTHCARE FACILITY" : "VILLAGE",
                                          journey?.endHealthcareFacility ? "HEALTHCARE FACILITY" : "VILLAGE",
                                          journey?.endHealthcareFacility ? journey?.endHealthcareFacility?.id : journey?.endVillage?.id,
                                          journey?.startHealthcareFacility ? `${journey?.startHealthcareFacility?.name} / ${journey?.startHealthcareFacility?.council?.name} / ${journey?.startHealthcareFacility?.council?.parent?.name} / ${journey?.startHealthcareFacility?.council?.parent?.parent?.name} ` : `${journey?.startVillage?.name} / ${journey?.startVillage?.council?.name} / ${journey?.startVillage?.council?.parent?.name} / ${journey?.startVillage?.council?.parent?.parent?.name} `,
                                          journey.id
                                        )
                                      }}
                                      size="sm"
                                    ><FontAwesomeIcon icon="pencil-alt"/> <span
                                      className="d-none d-md-inline">Edit</span>
                                    </Button>
                                  }
                                </Typography>
                                <Typography>
                                  <b>Destination {journey?.endHealthcareFacility ? `Facility` : journey?.endVillage ? `Village` : ``}: </b> {journey?.endHealthcareFacility ? `${journey?.endHealthcareFacility?.name} ${journey?.endHealthcareFacility?.level} / ${journey?.endHealthcareFacility?.council?.name} / ${journey?.endHealthcareFacility?.council?.parent?.name} / ${journey?.endHealthcareFacility?.council?.parent?.parent?.name} ` : journey?.endVillage ? `${journey?.endVillage?.name} / ${journey?.endVillage?.council?.name} / ${journey?.endVillage?.council?.parent?.name} / ${journey?.endVillage?.council?.parent?.parent?.name} ` : ``}
                                  {
                                    (journey?.endHealthcareFacility || journey?.endVillage) &&
                                    ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        changeDestination(
                                          journey?.startHealthcareFacility ? "HEALTHCARE FACILITY" : "VILLAGE",
                                          journey?.endHealthcareFacility ? "HEALTHCARE FACILITY" : "VILLAGE",
                                          journey?.endHealthcareFacility ? journey?.endHealthcareFacility.id : journey?.endVillage.id,
                                          journey?.endHealthcareFacility ? `${journey?.endHealthcareFacility?.name} ${journey?.endHealthcareFacility?.level} / ${journey?.endHealthcareFacility?.council?.name} / ${journey?.endHealthcareFacility?.council?.parent?.name} / ${journey?.endHealthcareFacility?.council?.parent?.parent?.name}` : `${journey?.endVillage?.name} / ${journey?.endVillage?.council?.name} / ${journey?.endVillage?.council?.parent?.name} / ${journey?.endVillage?.council?.parent?.parent?.name}`,
                                          journey.id
                                        )
                                      }}
                                      size="sm"
                                    ><FontAwesomeIcon icon="pencil-alt"/> <span
                                      className="d-none d-md-inline">Edit</span>
                                    </Button>
                                  }
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Vehicle Type: </b> {journey?.vehicleType?.enType} {" "}
                                  {
                                    patient?.bypass &&
                                    ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        changeVehicleType(journey?.vehicleType?.id, journey?.vehicleType?.countryId, journey.id)
                                      }}
                                      size="sm"
                                    ><FontAwesomeIcon icon="pencil-alt"/> <span
                                      className="d-none d-md-inline">Edit</span>
                                    </Button>
                                  }
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Journey Status: </b> {journey?.status}
                                </Typography>
                                <br />
                                {journey?.incidentReport?.description && <Typography>
                                  <b>Incident Report: </b> {journey?.incidentReport?.description}
                                </Typography>}
                                <br />
                                <Typography>
                                  {journey?.driver ? <span>
                                    <b>Community Driver Name: </b> {journey?.driver?.firstName} {journey?.driver?.lastName} {" "}
                                  </span> : <span>
                                    <b>Ambulance Driver Name: </b> {journey?.ambulance?.driverFirstName} {journey?.ambulance?.driverLastName} {" "}
                                  </span>
                                  }
                                  {
                                    ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        changeJourneyDriver(journey.id,
                                          journey?.driver ? journey?.driver?.firstName + " " + journey?.driver?.lastName : journey?.ambulance?.driverFirstName + " " + journey?.ambulance?.driverLastName,
                                          journey?.driver ? "driver" : "ambulance", journey?.payment?.status)
                                      }}
                                      size="sm"
                                    ><FontAwesomeIcon icon="pencil-alt"/> <span
                                      className="d-none d-md-inline">Edit</span>
                                    </Button>
                                  }
                                </Typography>
                                <Typography>
                                  <b>M-Pesa ID: </b> {journey?.driver?.mpesaNumber}
                                </Typography>
                                <Typography>
                                  <b>Total Distance: </b> {journey?.totalDistance} km
                                </Typography>
                                <Typography>
                                  <b>Total Cost: </b> {fromCents(journey?.totalCost)} {mmamaSettingsDetail.currencySymbol ? mmamaSettingsDetail.currencySymbol : 'LSL'}
                                </Typography>
                                <br />

                                <Accordion>
                                  <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography className={classes.heading}>Payment <b> [ID: {journey?.payment?.id}] </b></Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className={classes.details}>
                                  <Typography>
                                  <b>Payment Status: </b> {journey?.payment?.status}
                                    {
                                      journey?.payment?.status === "PROCESSED" &&
                                      ((permissionArray.length > 0 && permissionArray[0].permissions.includes("CASE_MODIFY")) || props.isAdmin) &&
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          changePaymentStatus(journey.id, journey.payment.id);
                                        }}
                                        size="sm"
                                      ><FontAwesomeIcon icon="pencil-alt"/> <span
                                        className="d-none d-md-inline">Edit</span>
                                      </Button>
                                    }
                                </Typography>
                                <Typography>
                                  <b>Payment ID: </b> {journey?.payment?.id}
                                </Typography>
                                <Typography>
                                  <b>Amount: </b> {fromCents(journey?.payment?.overrideAmount > 0 ? journey?.payment?.overrideAmount : journey?.payment?.requestedAmount)} {mmamaSettingsDetail.currencySymbol ? mmamaSettingsDetail.currencySymbol : 'LSL'}
                                </Typography>
                                <Typography>
                                  <b>M-Pesa Transaction ID: </b> {journey?.payment?.mpesaTransactionId}
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Tendered Date: </b> {convertDateTimeFromServer(journey?.payment?.tenderedDateTime)}
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Approver Date: </b> {convertDateTimeFromServer(journey?.payment?.approvedDateTime)}
                                </Typography>
                                <Typography>
                                  <b>Approver Note: </b> {journey?.payment?.approverNote}
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Processor Date: </b> {convertDateTimeFromServer(journey?.payment?.processedDateTime)}
                                </Typography>
                                <Typography>
                                  <b>Processor Note: </b> {journey?.payment?.processorNote}
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Rejected Date: </b> {convertDateTimeFromServer(journey?.payment?.rejectedDateTime)}
                                </Typography>
                                <br />
                                <Typography>
                                  <b>Additional Note: </b> {journey?.payment?.additionalNotes}
                                </Typography>
                                  </AccordionDetails>
                                </Accordion>

                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>

              <div>
                {props.totalItems && (
                  <div className={patientCaseList ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                      <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
                    </Row>
                    <Row className="justify-content-center">
                      <JhiPagination
                        activePage={paginationState.activePage}
                        onSelect={handlePagination}
                        maxButtons={5}
                        itemsPerPage={paginationState.itemsPerPage}
                        totalItems={props.totalItems}
                      />
                    </Row>
                  </div>
                )}
              </div>
            </>
          ) : (
            !loading && <div className="alert alert-warning">No Patient Cases found</div>
          )
        ) : (
          <div className="alert alert-warning">{'Please select Country and Date Range'}</div>
        )}
      </>
    </div>
  );
};

const mapStateToProps = ({ caseManagement, authentication, mmamaSettings, permissionSettings }: IRootState) => ({
  patientCaseList: caseManagement.patientCases,
  loading: caseManagement.loading,
  totalItems: caseManagement.totalItems,
  // RegCountries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  mmamaSettings: mmamaSettings.settings,
  userRoles: authentication.account.roles,
  permissionSettings: permissionSettings.settings
});

const mapDispatchToProps = {
  getPatientCases,
  getSettings,
  getAllByType
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
