import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PERMISSIONS} from 'app/config/constants';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities} from './symptom.reducer';
import {AvForm, AvGroup, AvInput, Label} from 'availity-reactstrap-validation';
import {IRegion} from "app/shared/model/region.model";

export interface ISymptomProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Symptom = (props: ISymptomProps) => {
  const emptyRegion: IRegion = null
  const {symptomList, match, loading,userCountryId} = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({})
  const [symptomsPerCountry, setSymptomsPerCountry] = useState([])

  useEffect(() => {
    props.getEntities();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries, userCountryId]);

  const updateSymptomList = (ac) => {
    setSymptomsPerCountry(props.symptomList.filter(symptom => symptom.country && symptom.country.id === ac.id))
  }

  useEffect(() => {
    if (props.symptomList && activeCountry) {
      updateSymptomList(activeCountry)
    }
  }, [props.symptomList]);

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    updateSymptomList(newActiveCountry)
  }
  const regCountriesList = props.countries.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
  })

  return (
    <div>
      <h2 id="symptom-heading">
        Symptoms
        {activeCountry && (<AvForm>
            <AvGroup>
              <AvInput id="payment-countries" type="select" className="form-control" name="payment-country"
                       value={activeCountry.id} onChange={onCountryChange}>
                {regCountriesList
                  ? regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))
                  : null}
              </AvInput>
            </AvGroup>
          </AvForm>
        )
        }
        {(activeCountry && typeof activeCountry.id !== 'undefined') && (
          <Link to={`${match.url}/new?country_id=${activeCountry.id}`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus"/>
            &nbsp; Create new Symptom
          </Link>
        )
        }
      </h2>
      <div className="table-responsive">
        {symptomsPerCountry && symptomsPerCountry.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Emergency Type</th>
              <th>Condition (English)</th>
              <th>Condition (Local Language)</th>
              <th>Required Facility Level</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {symptomsPerCountry.map((symptom, i) => (
              <tr key={`entity-${i}`}>
                <td>{symptom.emergencyType}</td>
                <td>{symptom.enCondition}</td>
                <td>{symptom.stCondition}</td>
                <td>{symptom.requiredFacilityLevel}</td>
                <td>{symptom.isActive ? 'Active' : 'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${symptom.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${symptom.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Symptoms found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({symptom,authentication}: IRootState) => ({
  symptomList: symptom.entities,
  loading: symptom.loading,
  countries: symptom.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Symptom);
