import {ISymptom} from 'app/shared/model/symptom.model';
import {IJourney} from 'app/shared/model/journey.model';
import {IPatient} from 'app/shared/model/patient.model';
import {CaseType} from 'app/shared/model/enumerations/case-type.model';
import {CaseStatus} from 'app/shared/model/enumerations/case-status.model';
import {ContactRelation} from 'app/shared/model/enumerations/contact-relation.model';
import {ReasonForTransfer} from 'app/shared/model/enumerations/reason-for-transfer.model';
import {FollowUpResult} from 'app/shared/model/enumerations/follow-up-result.model';


export interface IPatientCase {
  emergency?: boolean;
  bypass?: boolean;
  activated?: any;
  id?: string;
  type?: CaseType;
  caseStatus?: CaseStatus;
  primaryContactType?: ContactRelation;
  primaryContactName?: string;
  primaryContactNumber?: string;
  secondaryContactNumber?: string;
  reasonForTransferType?: ReasonForTransfer;
  reasonForTransferDescription?: string;
  followUpResult?: FollowUpResult;
  followUpMessage?: string;
  createdAt?: Date;
  motherSymptoms?: ISymptom[];
  babySymptoms?: ISymptom[];
  motherFollowupId?: any;
  babyFollowupId?: any;
  motherConditionId?: any;
  babyConditionId?: any;
  journeys?: IJourney[];
  patient?: IPatient;
}

export const defaultValue: Readonly<IPatientCase> = {};
