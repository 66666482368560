import './home.scss';

import React from 'react';
import { Alert, Col, Row ,Container} from 'reactstrap';
export const Home = () => {
  return (

    <Container >
    <Row >
     
      <Col md="12">
        <div data-cy="header" className="text-center" style={{ scrollBehavior: 'smooth' }}>
         <br/>
        <img src="content/images/logo_red.svg"  style={{height:"39px"}}/>
        <br/>
        <br/>
        <br/>
        </div>

        <div  className="sc-hGPBjI bDIWpD" style={{ scrollBehavior: 'smooth' }}>
         
       
         <div  className="text-center">
         <b style={{fontSize:'24px' ,marginBottom:"10px"}}>m-mama: Emergency transport for pregnant women and newborn</b>
         <br/>
        <br/>
         </div>
       </div>

       </Col>
       <Col md="12">
   
        <div className="sc-FNXRL ceioAs">
    <p></p>
          <p >The m-mama platform uses mobile technology to connect pregnant women and newborns to care when they need it the most. The affordable system is helping to reduce high rates of maternal mortality, identified as the number one health challenge by the United Nations’ Sustainable Development Goals.</p>
          <p >Thousands of pregnant women and their newborns across rural Africa are unable to travel to clinics or hospital when they experience a pregnancy-related emergency, resulting in high rates of death and disability. It is a global tragedy but also a devastating reality for too many families across the continent.</p>
          <div  className="text-center" style={{ padding: '16px' }}><img src="content/images/aboutpage_img1.png" alt="img1" className="sc-dUbtfd gupsFN" /></div>
          <p >m-mama is a cost-effective emergency transport system that manages a network of local private cars to act as ‘taxi ambulances’ in areas where ambulances are rarely available.</p>
          <p >When the patient or caregiver makes a free call to a 24/7 dispatch centre, a call handler assesses the patient’s condition using the m-mama app, which indicates the patient’s need for transfer to a health facility.</p>
          <p >In an emergency the dispatch checks for the availability of an ambulance. In many cases no ambulance is available, so the nearest taxi ambulance driver is identified on the m-mama app and receives a request to take the woman to the closest health facility. On safe arrival, the driver is paid instantly for the journey via M-Pesa (the mobile money transfer system) or via other government approved payment methods.</p>
          <div  className="text-center" style={{ padding: '16px' }}> <img src="/content/images/aboutpage_img2.png" className="sc-dUbtfd gupsFN" /></div>
          <p >The programme has seen a 27% reduction in maternal mortality in the Lake Zone region of Tanzania where it was launched.</p>
          <p >The national scale-up of m-mama in multiple countries is being funded through a financial and implementation partnership between Vodafone Foundation and National Governments. Further funding support is provided by Grand Challenges Canada. Technical support and implementation is provided by the Touch Foundation, Pathfinder International, Clinton Health Access Initiative (CHAI) and local government officials and facilities across the countries of operation. The m- mama system is sustained through local and national government funding and it is they who provide the operating staff and own the system.</p>
        </div>
      </Col>
    </Row>
    </Container>)
};


export default Home;
