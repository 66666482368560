export const PRIMARY_CHART_COLOR = '#008631';
export const SECONDARY_CHART_COLOR = '#00ab41';

export const DUMMMY_LABELS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const DUMMY_DATA = [DUMMMY_LABELS, ['200', '400', '950', '620', '800', '750', '300', '1500', '1200', '900', '550', '300']];

export const BAR_CHART_DATA_SET = [500, 1500, 900, 200, 700, 500, 1400, 2400, 2800, 3400, 1700, 1400];

export const DUMMY_PAYMENT_STATUS_HF_DATA = [500, 1500, 900, 200, 700, 500, 1400, 2400, 2800, 3400, 1700, 1400, 700, 500, 1400, 2400, 2800, 3400, 1700, 1400];

export const LEVEL_LABELS = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'];
export const DUMMMY_LEVELS_DATA = [200, 300, 100, 400, 500];

export const DATE_OPTIONS = [
  {
    id: 1,
    value: '9ce42304-b732-4791-9731-6f299b6df8c7',
    label: 'Blue',
  },
  {
    id: 2,
    value: '90419f06-7d07-45c8-bcec-d675096fe27f',
    label: 'Goldenrod',
  },
];

export const DUMMY_PAYMENT_STATUS_DATA = [
  'Approved',
  'Processed Offline',
  'Rejected',
  'Requested'
];

export const DUMMY_PAYMENT_STATUS_HF_LABEL = [
  'Sofi Majiji Dispensary',
  'Itete Dispensary',
  'Mngeta Health Center',
  'Namwawala Dispensary',
  'Chita Rural Dispensary',
  'Mbingu Dispensary',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'Shinyanga Regional Ref',

];

export const DUMMY_PSHF_DATA = {
  Community: {
    label: 'Payment status - number of payment per HF',
    color: PRIMARY_CHART_COLOR,
    data: [400, 554, 678, 908, 234, 223, 1234, 3324, 3432, 898, 565, 1345, 400, 554, 678, 908, 234, 223, 1234, 3324],
  },
  Health: {
    label: 'Payment status - number of payment per HF',
    color: PRIMARY_CHART_COLOR,
    data: [400, 554, 678, 908, 234, 223, 1234, 3324, 3432, 898, 565, 1345, 400, 554, 678, 908, 234, 223, 1234, 3324],
  }
};

export const DUMMY_OOTEVVHF_DATA = {
  Community: {
    label: 'Community',
    color: PRIMARY_CHART_COLOR,
    data: [400, 554, 678, 908, 234, 223, 1234, 3324, 3432, 898, 565, 1345],
  },
  'Health Facility': {
    label: 'Health Facility',
    color: '#4b70a9',
    data: [1200, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 1300],
  },
};

export const DUMMY_TRPTOR_DATA = {
  Community: {
    label: 'Community',
    color: PRIMARY_CHART_COLOR,
    data: [400, 554, 678, 908, 234, 223, 1234, 3324, 3432, 898, 565, 1345],
  },
  'Health Facility': {
    label: 'Health Facility',
    color: '#4b70a9',
    data: [1200, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 1300],
  },
};

export const DUMMY_CUMULATIVE_DATA_BY_MONTH = {
  Approved: {
    label: 'Aproved',
    color: PRIMARY_CHART_COLOR,
    data: [400, 554, 678, 908, 234, 223, 1234, 3324, 3432, 898, 565, 1345],
  },
  'Processed Offline': {
    label: 'Processed Offline',
    color: '#4b70a9',
    data: [1200, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 1300],
  },
  'Rejected': {
    label: 'Rejected',
    color: '#4b70a9',
    data: [1200, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 1300],
  },
  'Requested': {
    label: 'Requested',
    color: '#6f8dba',
    data: [1200, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 1300],
  },
};

export const DUMMY_TRPTOP_DATA = {
  Antepartum: {
    label: 'Maternal',
    color: PRIMARY_CHART_COLOR,
    data: [600, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 300],
  },
  'In Labour': {
    label: 'Neonatal',
    color: SECONDARY_CHART_COLOR,
    data: [600, 500, 780, 420, 1400, 1550, 950, 1200, 900, 710, 670, 1223],
  },
  Postpartum: {
    label: 'Both',
    color: '#00c04b',
    data: [400, 554, 678, 908, 734, 823, 1234, 3324, 3432, 898, 565, 1345],
  },
};

export const DUMMY_TBTOD_DATA = {
  Antepartum: {
    label: 'Commmunity Driver',
    color: PRIMARY_CHART_COLOR,
    data: [600, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 300],
  },
  'In Labour': {
    label: 'Car',
    color: SECONDARY_CHART_COLOR,
    data: [600, 500, 780, 420, 1400, 1550, 950, 1200, 900, 710, 670, 1223],
  },
  Postpartum: {
    label: 'Ambulance',
    color: '#00c04b',
    data: [400, 554, 678, 908, 734, 823, 1234, 3324, 3432, 898, 565, 1345],
  },
};

export const DUMMY_TBTOV_DATA = {
  'In Labour': {
    label: 'Car',
    color: PRIMARY_CHART_COLOR,
    data: [600, 500, 780, 420, 1400, 1550, 950, 1200, 900, 710, 670, 1223],
  },
  Postpartum: {
    label: 'Ambulance',
    color: SECONDARY_CHART_COLOR,
    data: [400, 554, 678, 908, 734, 823, 1234, 3324, 3432, 898, 565, 1345],
  },
};

export const DUMMY_DOLVBE_DATA = {
  'Live emergency': {
    label: 'Live emergency',
    color: PRIMARY_CHART_COLOR,
    data: [600, 500, 780, 420, 1400, 1550],
  },
  'Bypass emergency': {
    label: 'Bypass emergency',
    color: SECONDARY_CHART_COLOR,
    data: [400, 554, 678, 908, 734, 823],
  },
};

export const DUMMMY_REF_BY_REF_FAC_LABELS = [
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
  'Shinyanga Regional Ref',
  'chamaguha Dispensary',
  'Tinde Health Center',
];
export const DUMMY_REF_BY_REF_FAC_DATA = {
  Antepartum: {
    label: 'Maternal',
    color: PRIMARY_CHART_COLOR,
    data: [600, 400, 950, 620, 800, 750, 300, 1500, 1200, 900, 550, 300],
  },
  'In Labour': {
    label: 'Neonatal',
    color: SECONDARY_CHART_COLOR,
    data: [600, 500, 780, 420, 1400, 1550, 950, 1200, 900, 710, 670, 1223],
  },
  Postpartum: {
    label: 'Both',
    color: '#00c04b',
    data: [400, 554, 678, 908, 734, 823, 1234, 3324, 3432, 898, 565, 1345],
  },
};

export const DUMMY_LORFRHTH_LABELS = [
  'Needs higher level of care',
  'No blood is available',
  'Appropriate healthcare worker not available',
  'Unable to do lab tests',
  'Needs an operative procedure',
  'Other',
];
export const DUMMY_LORFRHTH_DATA = [1400, 2400, 2800, 3400, 1700, 1400];

export const DUMMY_COMP_TRIPS_FOR_BEMONC_LABELS = [
  'Community',
  'Dispensary',
  'Health Center (BEmONC)',
  'Health Center (CEmONC)',
  'District Hospital',
  'Regional Referral',
];
export const DUMMY_COMP_TRIPS_FOR_BEMONC_DATA = [1400, 2400, 2800, 3400, 1700, 1400];

export const DUMMY_DISTRICT_LIST = [
  'Kilwa District',
  'Lindi District',
  'Lindi Municipal',
  'liwale District',
  'Nachingwea District',
  'Ruangwa District',
];

export const DUMMY_10_REASONS_FOR_DRIVER_REFUSAL = [
  'Car ran out of fuel',
  'Car broke down',
  'No further transport needed',
  'Patient  transported using their own car',
  'Emergency case resolved at origin HF',
]

export const DUMMY_OUTCOMES_ROW_DATA = [
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
  {
    "Day,Month,Year": "September 12,2022",
    "Payment ID": "fege04eedmjkj",
    "Driver First Name": "Jospeh Salum",
    "Driver Last Name": "Mkopoka",
    "Driver ID": "shgsdhfbfsd6654sdjhsdhj7",
    "Driver M-PESA": 115211286985125,
    "Start Location Name1": "Chiola Dispensary",
    "end Facility Name": "kahama District Facility",
    "Payment Status1": "Approved",
    "Minimum Amount": 2910101,
    "Approved Date": "9/12/2022",
    "Approver L": "Yotham",
    "Processed Date": "NULL",
    "Process": "NULL",
  },
];

export const DUMMY_PAPMPHF_ROW_DATA = [
  {
    "Region Name": "Lindi",
    "District Name": "Kilwa District Council",
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": 1234567,
    "June": 123456,
    "July": 3345566,
    "August": 2345678,
    "September": 2323432,
    "October": 23432535,
    "November": 34242323,
    "December": 23456546,
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": '',
    "April": '',
    "May": '',
    "June": '',
    "July": 3345566,
    "August": '',
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": 23456546,
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "District Name": "Lindi District Council",
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": 1234567,
    "June": 123456,
    "July": 3345566,
    "August": 2345678,
    "September": 2323432,
    "October": 23432535,
    "November": 34242323,
    "December": 23456546,
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "Start Location Name1": "Chiola Dispensary",
    "January": 1345672,
    "February": 1234567,
    "March": 1234567,
    "April": 1234567,
    "May": '',
    "June": 123456,
    "July": '',
    "August": 2345678,
    "September": 2323432,
    "October": '',
    "November": 34242323,
    "December": '',
  },
  {
    "Region Name": "Morogoro",
    "District Name": "Gairo District Council",
    "Start Location Name1": "Chakwale Dispensary",
    "January": '134,5672.00',
    "February": 1234567,
    "March": 1234567,
    "April": '',
    "May": 1234567,
    "June": '',
    "July": '',
    "August": 2345678,
    "September": '',
    "October": 23432535,
    "November": '',
    "December": '',
  },
  {
    "Start Location Name1": "Chakwale Dispensary",
    "January": '134,5672.00',
    "February": 1234567,
    "March": 1234567,
    "April": '',
    "May": 1234567,
    "June": '',
    "July": '',
    "August": 2345678,
    "September": '',
    "October": 23432535,
    "November": '',
    "December": '',
  },
  {
    "Start Location Name1": "Chakwale Dispensary",
    "January": '134,5672.00',
    "February": 1234567,
    "March": 1234567,
    "April": '',
    "May": 1234567,
    "June": '',
    "July": '',
    "August": 2345678,
    "September": '',
    "October": 23432535,
    "November": '',
    "December": '',
  },
];

const GREEN_COLORS = ['#008631', '#00ab41', '#00c04b', '#1fd655', '#39e75f', '#5ced73', '#83f28f', '#abf7b1', '#cefad0'];
const BLUE_COLORS = [
  '#2d4365',
  '#354e76',
  '#3c5a87',
  '#446598',
  '#4b70a9',
  '#4b70a9',
  '#5d7eb2',
  '#6f8dba',
  '#819bc3',
  '#93a9cb',
  '#a5b8d4',
];
const COLORS = [...BLUE_COLORS, ...GREEN_COLORS, ...BLUE_COLORS, ...GREEN_COLORS];

const ANTEPARTUM_KEYS = [
  'Convulsions',
  'Placenta previa',
  'Premature rupture of membranes (PROM)',
  'Severe pre-eclampsia',
  'Severe Abdominal pain',
  'Eclampsia',
  'Foul smelling vaginal discharge',
  'Severe anemia',
  'Severe vomiting',
  'Other',
  'High fever',
  'Reduced fetal movements',
  'Severe headache',
  'Blurry vision',
  'Rupture of membranes (ROM) without any contractions',
  'Difficulty breathing',
  'Bleeding per vagina (PV)',
  'Weakness',
  'Unconscious',
];

const LABOR_KEYS = [
  'LABOUR stopped',
  'Transverse lie',
  'Breech',
  'Prolonged LABOUR',
  'Fetal distress',
  'Shoulder dystocia',
  'Big baby',
  'Partograph to the right of the alert line',
  'Other',
  'Severe pre-eclampsia',
  'Severe Abdominal pain',
  'High fever',
  'Reduced fetal movements',
  'Severe headache',
  'Blurry vision',
  'Rupture of membranes (ROM) without any contractions',
  'Difficulty breathing',
  'Bleeding per vagina (PV)',
  'Weakness',
  'Unconscious',
  'Convulsions',
  'Premature rupture of membranes (PROM)',
  'Eclampsia',
  'Foul smelling vaginal discharge',
  'Severe anemia',
  'Severe vomiting',
  'prolapsed cord',
];

const POSTPARTUM_KEYS = [
  'severe pelvic pain',
  'Severe headache',
  'blurry vision',
  'Fever',
  'Difficulty breathing',
  'Weakness',
  'Foul smellng vaginal discharge',
  'Severe bleeding per vagina',
  'Unconscious',
  'Convulsions',
  'Retained placenta',
  'Septic incision from caesarian section',
  'Painful calves',
  'Other',
  'severe abdominal pain',
  'Urinary retention',
  'Distended tender abdomen',
  'inability to walk after delivery (symphysiotomy)',
];

const NEONATAL_KEYS = [
  'Very small',
  'Premature',
  'Convulsions',
  'Difficulty breathing',
  'heart rate >120 bpm',
  'Unable to feed',
  'Jaundice',
  'Temperature > 37.5',
  'Temperature <35',
  'Umbilical bleeding',
  'Septic cord',
  'Stiff body',
  'Unconscious',
  'Floppy',
  'Vomiting',
  'Not passing urine or stool',
  'structural anomalies eg facial deformities, spinal abnormalities, abdominal wall defects etc',
];

export const ANTEPARTUM_EMG_PIE_DATA = ANTEPARTUM_KEYS.map((el, i) => {
  return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: Math.floor(Math.random() * 200 + 30) };
});

export const LABOR_EMG_PIE_DATA = LABOR_KEYS.map((el, i) => {
  return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: Math.floor(Math.random() * 200 + 30) };
});

export const POSTPARTUM_EMG_PIE_DATA = POSTPARTUM_KEYS.map((el, i) => {
  return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: Math.floor(Math.random() * 200 + 30) };
});

export const NEONATAL_EMG_PIE_DATA = NEONATAL_KEYS.map((el, i) => {
  return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: Math.floor(Math.random() * 200 + 30) };
});
