import {IRegion} from "app/shared/model/region.model";

export interface IVehicleType {
  id?: string;
  enType?: string;
  stType?: string;
  countryId?: IRegion;
  image?: any;
}

export const defaultValue: Readonly<IVehicleType> = {};
