import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getContinents} from 'app/entities/continent/continent.reducer';
import {getAllEntities as getRegions} from 'app/entities/region/region.reducer';
import {createEntity, getEntity, reset, updateEntity} from './region.reducer';
import {RegionType} from "app/shared/model/enumerations/region-type.model";

export interface IRegionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RegionUpdate = (props: IRegionUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [regionType, setRegionType] = useState('COUNTRY')
  const [regionParentId, setRegionParentId] = useState('')
  const [parentRegions, setParentRegions] = useState([])

  const { regionEntity, continents, regions, loading, updating } = props;

  const onParentTypeChange = (event) => {
    const parentType = event.target.value
    setParentRegions(props.regions.filter(region => region.type.toString() === parentType))
  }

  const handleClose = () => {
    props.history.push('/region' + props.location.search);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setRegionType(params.get('type'))
    if (isNew) {
      setRegionParentId(params.get('parentId'))
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getContinents();
    props.getRegions();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.getContinents();
      props.getRegions();
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (isNew && props.regions) {
      setParentRegions(props.regions.filter(region => region.type === RegionType.COUNTRY))
    }
  }, [props.regions]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...regionEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.region.home.createOrEditLabel">Create or edit a {regionType.toLowerCase()}</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : regionEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="region-id">ID</Label>
                  <AvInput id="region-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="region-name">
                  Name
                </Label>
                <AvField id="region-name" type="text" name="name" />
              </AvGroup>
              <AvGroup>
                <Label id="locationLatLabel" for="region-locationLat">
                  Location Lat
                </Label>
                <AvField id="region-locationLat" type="string" className="form-control" name="locationLat" />
              </AvGroup>
              <AvGroup>
                <Label id="locationLonLabel" for="region-locationLon">
                  Location Lon
                </Label>
                <AvField id="region-locationLon" type="string" className="form-control" name="locationLon" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="region-description">
                  Description
                </Label>
                <AvField id="region-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="typeLabel" for="region-type">
                  Type
                </Label>
                <AvInput
                  id="region-type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={(!isNew && regionEntity.type) || regionType} disabled
                >
                  <option value="COUNTRY">COUNTRY</option>
                  <option value="COUNCIL">COUNCIL</option>
                  <option value="DISTRICT">DISTRICT</option>
                  <option value="PROVINCE">PROVINCE</option>
                  <option value="STATE">STATE</option>
                  <option value="REGION">REGION</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <AvInput id="region-continent" type="select" className="form-control" name="continentId" value = {props.africa? props.africa.id: 0} hidden>
                  <option value="" key="0" />
                  {continents
                    ? continents.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="region-parent">Parent</Label>
                <AvInput id="region-parent" type="select" className="form-control" name="parentId" disabled value = {regionParentId}>
                  <option value="" key="0" />
                  {props.regions
                    ? props.regions.map(parent=> (
                        <option value={parent.id} key={parent.id}>
                          {parent.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/region" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  continents: storeState.continent.entities,
  africa: storeState.continent.entities.filter(continent => continent.name === 'Africa')[0],
  regions: storeState.region.entities,
  regionEntity: storeState.region.entity,
  loading: storeState.region.loading,
  updating: storeState.region.updating,
  updateSuccess: storeState.region.updateSuccess,
});

const mapDispatchToProps = {
  getContinents,
  getRegions,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionUpdate);
