import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row, InputGroup, ButtonGroup} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from "axios";
import {IRegion, IRegionSearchResponse} from "app/shared/model/region.model";
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {IRootState} from "app/shared/reducers";
import {createEntity, getEntity, reset, updateEntity} from "app/entities/village/village.reducer";

export interface IVillageUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VillageUpdate = (props: IVillageUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [councils, setCouncils] = useState([]);
  const [country, setCountry] = useState<IRegion>({"name":""});
  const [region, setRegion] = useState<IRegion>({"name":""});
  const [district, setDistrict] = useState<IRegion>({"name": ""});
  const [council, setCouncil] = useState<IRegion>({"name": ""});
  const [countryId, setCountryId] = useState(null);
  const [activeStatus, setActiveStatus] = useState(true);

  const {villageEntity, loading, updating} = props;

  const handleCountrySearch = (query: string) => {
    axios.get<IRegionSearchResponse>(`api/regions/search/COUNTRY/${query}`)
      .then((response) => {
          setCountries(response.data.regionResults)
        },
        (error) => {
          // TODO: hand error here
        })
  }

  const handleRegionSearch = (query: string) => {
    axios.get<IRegionSearchResponse>(`api/regions/search/from-parent/${country?.id}/${query}`)
      .then((response) => {
          setRegions(response.data.regionResults)
        },
        (error) => {
          // TODO: hand error here
        })
  }

  const handleDistrictSearch = (query: string) => {
    axios.get<IRegionSearchResponse>(`api/regions/search/from-parent/${region?.id}/${query}`)
      .then((response) => {
          setDistricts(response.data.regionResults)
        },
        (error) => {
          // TODO: hand error here
        })
  }

  const handleCouncilSearch = (query: string) => {
    axios.get<IRegionSearchResponse>(`api/regions/search/from-parent/${district?.id}/${query}`)
      .then((response) => {
          setCouncils(response.data.regionResults)
        },
        (error) => {
          // TODO: hand error here
        })
  }
  const handleClose = () => {
    props.history.push('/village' + props.location.search);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (isNew) {
      setCountryId(params.get('country_id'))
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (!isNew) {
      const villageCouncil = props.villageEntity.council
      const villageDistrict = council?.parent
      const villageRegion = district?.parent
      const villageCountry = region?.parent
      setCountry(villageCountry)
      setRegion(villageRegion)
      setDistrict(villageDistrict)
      setCouncil(villageCouncil)
      setActiveStatus(props.villageEntity.activated)
    }
  }, [props.villageEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...villageEntity,
        ...values,
        activated: activeStatus,
        council
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.village.home.createOrEditLabel">Create or Edit Village</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : villageEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="village-id">ID</Label>
                  <AvInput id="village-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="village-name">
                  Name
                </Label>
                <AvField id="village-name" type="text" name="name"/>
              </AvGroup>
              <AvGroup>
                <Label id="activeStatusLabel" for="village-activeStatus">
                  Active Status
                </Label>
              <ButtonGroup style={{marginLeft:"30px"}}>
                <Button
                 color={activeStatus? 'primary' : 'secondary'}
                  value={activeStatus}
                  onClick={(e)=>{
                    setActiveStatus(true)
                  }}
                >
                  Active 
                </Button>
                <Button
                  color={!activeStatus? 'primary' : 'secondary'}
                  value={activeStatus}
                   onClick={()=>{setActiveStatus(false)}}
                >
                  Inactive
                </Button>
              </ButtonGroup>
              </AvGroup>
              <AvGroup>
                <Label id="locationLatLabel" for="village-locationLat">
                  Latitude
                </Label>
                <AvField id="village-locationLat" type="number" className="form-control" name="locationLat"/>
              </AvGroup>
              <AvGroup>
                <Label id="locationLonLabel" for="village-locationLon">
                  Longitude
                </Label>
                <AvField id="village-locationLon" type="number" className="form-control" name="locationLon"/>
              </AvGroup>
              <AvGroup>
                <Label id="pickupLocation" for="village-pickupLocation">
                  Pick-up location
                </Label>
                <AvField id="village-pickupLocation" type="text" name="pickupLocation"/>
              </AvGroup>
              {isNew ? (
              <AvGroup>
                <Label id="countryLabel" for="village-country">
                  Country
                </Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="country-async"
                  labelKey="name"
                  minLength={1}
                  onSearch={handleCountrySearch}
                  options={countries}
                  inputProps={{required: true}}
                  onChange={(selected: [IRegion]) => {
                    const selectedCountry = selected.length > 0 ? selected[0] : undefined
                    setCountry(selectedCountry)
                  }}
                />
              </AvGroup>): null }
              {isNew? (
              <AvGroup>
                <Label id="regionLabel" for="village-region">
                  Region
                </Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="region-async"
                  labelKey="name"
                  minLength={1}
                  onSearch={handleRegionSearch}
                  options={regions}
                  inputProps={{required: true}}
                  onChange={(selected: [IRegion]) => {
                    const selectedRegion = selected.length > 0 ? selected[0] : undefined
                    setRegion(selectedRegion)
                  }}
                />
              </AvGroup>): null}
              {isNew? (
              <AvGroup>
                <Label id="districtLabel" for="village-district">
                  District
                </Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="district-async"
                  labelKey="name"
                  minLength={1}
                  onSearch={handleDistrictSearch}
                  options={districts}
                  onChange={(selected: [IRegion]) => {
                    const selectedDistrict = selected.length > 0 ? selected[0] : undefined
                    setDistrict(selectedDistrict)
                  }}
                />
              </AvGroup>): null}
              {isNew? (
              <AvGroup>
                <Label id="councilLabel" for="village-council">
                  Council
                </Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="council-async"
                  labelKey="name"
                  minLength={1}
                  onSearch={handleCouncilSearch}
                  options={councils}
                  onChange={(selected: [IRegion]) => {
                    const selectedCouncil = selected.length > 0 ? selected[0] : undefined
                    setCouncil(selectedCouncil)
                  }}
                />
              </AvGroup>): null}
              {!isNew? (
                <Row >
                  &nbsp;&nbsp;&nbsp;Council: {villageEntity?.council?.name} \ {villageEntity?.council?.parent?.name} \ {villageEntity?.council?.parent?.parent?.name} \ {villageEntity?.council?.parent?.parent?.parent?.name}
                </Row>): null
              }
              <Button tag={Link} id="cancel-save" to="/village" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({village}: IRootState) => ({
  villageEntity: village.entity,
  loading: village.loading,
  updating: village.updating,
  updateSuccess: village.updateSuccess,
  countries: village.countries,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VillageUpdate);
