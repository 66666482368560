import React, { useEffect, useState } from 'react'
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, StackedBarChart } from '../../../charts';
import { shuffle } from 'lodash';
import { MONTH_LABELS, PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR } from '../constants';
import { BAR_CHART_DATA_SET, DUMMY_COMP_TRIPS_FOR_BEMONC_DATA, DUMMY_COMP_TRIPS_FOR_BEMONC_LABELS } from '../dummyData';
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const ReferralPathwaySection = ({countReferrals, emergencyReferrals}) => {
    const [labels, setLabels] = useState([]);
    const [emergencyData, setEmergencyData] = useState({});
    const [communityToHfData, setCommunityToHfData] = useState([]);
    const [hfToHfData, setHfToHfData] = useState([]);
    const [referringLabels, setReferringLabels] = useState([]);
    const [referringData, setReferringData] = useState({});
    const [emReasonsHfToHfData, setEmReasonsHfToHfData] = useState([]);
    const [receivingLabels, setReceivingLabels] = useState([]);
    const [receivingData, setReceivingData] = useState({});
    const [refulsalByDistrictData, setRefulsalByDistrictData] = useState([]);
    // const [startBemomncData, setStartBemoncData] = useState([]);
    // const [startCemoncData, setStartCemoncData] = useState([]);

    useEffect(() => {
        if(emergencyReferrals?.communityReferrals && typeof emergencyReferrals?.communityReferrals === 'object') {
            const emergencyKeys = {
                Community: {
                  label: 'Community',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'Health Facility': {
                  label: 'Health Facility',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
            }

            const referralKeys = {
                Antepartum: {
                  label: 'Maternal',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'In Labour': {
                  label: 'Neonatal',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
                Postpartum: {
                  label: 'Both',
                  color: '#00c04b',
                  data: [],
                },
            };

            const receivingKeys = {
              Antepartum: {
                label: 'Maternal',
                color: PRIMARY_CHART_COLOR,
                data: [],
              },
              'In Labour': {
                label: 'Neonatal',
                color: SECONDARY_CHART_COLOR,
                data: [],
              },
              Postpartum: {
                label: 'Both',
                color: '#00c04b',
                data: [],
              },
            };

            // console.log("ENTERIES", Object.entries(emergencyReferrals?.maternalReferringFacilities).sort(([,a]:any,[,b]:any) => a > b ? -1 : a < b ? 1 :0).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}))
            const sortedReferringMaternalKeys = Object.entries(emergencyReferrals?.maternalReferringFacilities).sort(([,a]:any,[,b]:any) => a > b ? -1 : a < b ? 1 :0).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            const sortedReferringNeonatalKeys = Object.entries(emergencyReferrals?.maternalReferringFacilities).sort(([,a]:any,[,b]:any) => a > b ? -1 : a < b ? 1 :0).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            const sortedReceivingMaternalKeys = Object.entries(emergencyReferrals?.maternalReceivingFacilities).sort(([,a]:any,[,b]:any) => a > b ? -1 : a < b ? 1 :0).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            const sortedReceivingNeonatalKeys = Object.entries(emergencyReferrals?.neonatalReceivingFacilities).sort(([,a]:any,[,b]:any) => a > b ? -1 : a < b ? 1 :0).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            const keys = Object.keys(emergencyReferrals?.communityReferrals).length > Object.keys(emergencyReferrals?.hfReferrals).length ? Object.keys(emergencyReferrals?.communityReferrals) : Object.keys(emergencyReferrals?.hfReferrals);
            const referringFacilitiesKeys = Object.keys(emergencyReferrals?.maternalReferringFacilities).length > Object.keys(emergencyReferrals?.neonatalReferringFacilities).length ? Object.keys(sortedReferringMaternalKeys) : Object.keys(sortedReferringNeonatalKeys);
            const receivingFacilitiesKeys = Object.keys(emergencyReferrals?.maternalReceivingFacilities).length > Object.keys(emergencyReferrals?.neonatalReceivingFacilities).length ? Object.keys(sortedReceivingMaternalKeys) : Object.keys(sortedReceivingNeonatalKeys);

            const aggregatedEmergencyGraphData = mergeAndSum([
                emergencyReferrals?.communityReferrals,
                emergencyReferrals?.hfReferrals,
            ])

            const emergencyGraphDistrictKeys = Object.keys(aggregatedEmergencyGraphData)
                .sort(function(a,b){return aggregatedEmergencyGraphData[b]-aggregatedEmergencyGraphData[a]});

            for(let i = 0; i < emergencyGraphDistrictKeys.length; i++) {
                emergencyKeys['Community'].data.push(emergencyReferrals?.communityReferrals[emergencyGraphDistrictKeys[i]] ?? 0)
                emergencyKeys['Health Facility'].data.push(emergencyReferrals?.hfReferrals[emergencyGraphDistrictKeys[i]] ?? 0)
            }

            for(let j = 0; j < referringFacilitiesKeys.length; j++) {
                referralKeys["Antepartum"].data.push(emergencyReferrals?.maternalReferringFacilities[referringFacilitiesKeys[j]] ? emergencyReferrals?.maternalReferringFacilities[referringFacilitiesKeys[j]] : 0)
                referralKeys["In Labour"].data.push(emergencyReferrals?.neonatalReferringFacilities[referringFacilitiesKeys[j]] ? emergencyReferrals?.neonatalReferringFacilities[referringFacilitiesKeys[j]] : 0)
                referralKeys["Postpartum"].data.push(emergencyReferrals?.bothReferringFacilities[referringFacilitiesKeys[j]] ? emergencyReferrals?.bothReferringFacilities[referringFacilitiesKeys[j]] : 0)
            }
            for(let j = 0; j < receivingFacilitiesKeys.length; j++) {
                receivingKeys["Antepartum"].data.push(emergencyReferrals?.maternalReceivingFacilities[receivingFacilitiesKeys[j]] ? emergencyReferrals?.maternalReceivingFacilities[receivingFacilitiesKeys[j]] : 0)
                receivingKeys["In Labour"].data.push(emergencyReferrals?.neonatalReceivingFacilities[receivingFacilitiesKeys[j]] ? emergencyReferrals?.neonatalReceivingFacilities[receivingFacilitiesKeys[j]] : 0)
                receivingKeys["Postpartum"].data.push(emergencyReferrals?.bothReceivingFacilities[receivingFacilitiesKeys[j]] ? emergencyReferrals?.bothReceivingFacilities[receivingFacilitiesKeys[j]] : 0)
            }

            const communityToHfKeys = Object.keys(emergencyReferrals?.communityToHf).map((el, i) => {
                return { label: `Level of care ${el}`, data: emergencyReferrals?.communityToHf[el] };
            });

            const hfToHfKeys = Object.keys(emergencyReferrals?.hfToHf).map((el, i) => {
                return { label: `Level of care ${el}`, data: emergencyReferrals?.hfToHf[el] };
            });

            const emergencyReasonsKeys = Object.keys(emergencyReferrals?.reasonForEmergenciesFromHfToHf).map((el, i) => {
                return { label: el, data: emergencyReferrals?.reasonForEmergenciesFromHfToHf[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            // const startBemoncKeys = Object.keys(emergencyReferrals?.startBemonc).map((el, i) => {
            //     return { label: el, data: emergencyReferrals?.startBemonc[el] };
            // });

            // const startCemoncKeys = Object.keys(emergencyReferrals?.startCemonc).map((el, i) => {
            //     return { label: el, data: emergencyReferrals?.startCemonc[el] };
            // });

            const refusalByDistrictKeys = Object.keys(emergencyReferrals?.refusalByDistrictReasons).map((el, i) => {
                return { label: el, data: emergencyReferrals?.refusalByDistrictReasons[el] };
            });

            setLabels(emergencyGraphDistrictKeys);
            setEmergencyData(emergencyKeys);
            setCommunityToHfData(communityToHfKeys);
            setHfToHfData(hfToHfKeys);
            setReferringLabels(referringFacilitiesKeys);
            setReferringData(referralKeys);
            setEmReasonsHfToHfData(emergencyReasonsKeys);
            setReceivingLabels(receivingFacilitiesKeys);
            setReceivingData(receivingKeys);
            // setStartBemoncData(startBemoncKeys);
            // setStartCemoncData(startCemoncKeys);
            setRefulsalByDistrictData(refusalByDistrictKeys);
        }
    }, [emergencyReferrals])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>4. Referral Pathway</p>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.1 Origin of the Emergencies village vs health facility", value: "" }}>
                        <StackedBarChart chartLabels={labels} chartData={emergencyData} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.2 Journeys from community - Breakdown for type of destination facility", value: "" }}>
                        <BarChart chartLabel="Journeys from community - Breakdown for type of destination facility" labels={communityToHfData.map(item => item.label)} dataSet={communityToHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.3 Journeys from HF - Breakdown for type of destination facility", value: "" }}>
                        <BarChart chartLabel="Journeys from HF - Breakdown for type of destination facility" labels={hfToHfData.map(item => item.label)} dataSet={hfToHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.4 Number of Referrals by referring facilities", value: "" }}>
                        <StackedBarChart chartLabels={referringLabels} chartData={referringData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.5 List of reasons for emergencies from HF to HF", value: "" }}>
                        <BarChart chartLabel="List of reasons for emergencies from HF to HF" labels={emReasonsHfToHfData.map(item => item.label)} dataSet={emReasonsHfToHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.6 Number of Referrals by receiving facilities", value: "" }}>
                        <StackedBarChart chartLabels={receivingLabels} chartData={receivingData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.7 Completed journeys from BEmONC  - distribution for destination facility", value: "" }}>
                        <BarChart chartLabel="Completed journeys from BEmONC  - distribution for destination facility" labels={startBemomncData.map(item => item.label)} dataSet={startBemomncData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.8 Completed journeys from CEmONC  - distribution for destination facility", value: "" }}>
                        <BarChart chartLabel="Completed journeys from CEmONC  - distribution for destination facility" labels={startCemoncData.map(item => item.label)} dataSet={startCemoncData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.9 Number of onward referrals (patient that need hirgher level of care)", value: "" }}>
                        <BarChart chartLabel="List of reasons for onward transfer" labels={DUMMMY_LABELS} dataSet={BAR_CHART_DATA_SET} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.7 List of reasons for onward transfer", value: "" }}>
                        <BarChart chartLabel="List of reasons for onward transfer (2nd referral)" labels={emReasonsHfToHfData.map(item => item.label)} dataSet={emReasonsHfToHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.10 Number of refusals per facility", value: "" }}>
                        <BarChart chartLabel="Number of refusals per facility" labels={DUMMMY_REF_BY_REF_FAC_LABELS} dataSet={DUMMY_REF_BY_REF_FAC_DATA["In Labour"]["data"]} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "4.8 List of Reasons for refusals", value: "" }}>
                        <BarChart chartLabel="List of Reasons for refusals" labels={refulsalByDistrictData.map(item => item.label)} dataSet={refulsalByDistrictData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
