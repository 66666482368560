import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEntity as getVillage} from '../village/village.reducer'

import {IRootState} from 'app/shared/reducers';
import {getEntity, deleteEntity, resetModal} from './driver.reducer';
import {IRegion} from "app/shared/model/region.model";
import {IVillage} from "app/shared/model/village.model";

export interface IDriverDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const DriverDeleteDialog = (props: IDriverDeleteDialogProps) => {
  // [driverVillage, setDriverVillage] = useState()
  const [driverVillage, setDriverVillage] = useState<IVillage>({})

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    if(props?.driverEntity?.homeVillageId){
      props.getVillage(props.driverEntity.homeVillageId)
    }
  }, [props.driverEntity]);

  const handleClose = () => {
    props.resetModal();
    props.history.push('/driver' + props.location.search);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const {driverEntity, village} = props;

  const confirmDelete = () => {
    props.deleteEntity(props.driverEntity.id, village?.council?.parent?.parent?.parent?.id);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
      <ModalBody id="mmamaApp.region.delete.question">Are you sure you want to delete this Driver?
        { props.deleteFailure && (
          <Row className="justify-content-center">
            <Alert color="danger"><strong>Unable to delete driver</strong></Alert>
          </Row>
        )
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban"/>
          &nbsp; Cancel
        </Button>
        <Button id="jhi-confirm-delete-region" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash"/>
          &nbsp; Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({driver,village}: IRootState) => ({
  driverEntity: driver.entity,
  updateSuccess: driver.updateSuccess,
  deleteFailure: driver.deleteFailure,
  village: village.entity
});

const mapDispatchToProps = {getEntity, deleteEntity, resetModal, getVillage};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverDeleteDialog);
