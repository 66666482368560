import axios from 'axios';
import { ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IMMamaSettings } from "app/shared/model/mmama-settings-model";
import { fromCents, fromPercent } from 'app/shared/util/money-utils';

export const ACTION_TYPES = {
  FETCH: 'mmamaSettings/FETCH',
  FETCH_BY_ID: 'mmamaSettings/FETCH_BY_ID',
  UPDATE: 'mmamaSettings/UPDATE',
  RESET: 'mmamaSettings/RESET',
  DELETE_REPORT: 'DELETE_REPORT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  settings: [],
  settingsDetail: {
    paymentEditThreshold: 0,
    costKm: 0,
    flatDriverFee: 0,
    countryId: '0',
    countryName: '',
    paymentMethod: '',
    currencyConverter: 0,
    currencySymbol: '',
    babyAgeLimit:0,
    motherDeliveryDateLimit:0,
    nodeAutoLogoff: 0,
    ecdLivesSaved: 0,
  },
  updating: false,
  updateSuccess: false,
};

export type MMamaSettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: MMamaSettingsState = initialState, action): MMamaSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.FETCH):
    case FAILURE(ACTION_TYPES.UPDATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };

    case SUCCESS(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };

    case SUCCESS(ACTION_TYPES.FETCH): {
      const data = action.payload.data
      console.log("getMMamaSettingsById", data)
      data.forEach((item) => {

        item.costKm = fromCents(item.costKm);
        item.flatDriverFee = fromCents(item.flatDriverFee);
        item.paymentEditThreshold = fromPercent(item.paymentEditThreshold);

        return item;

      });

      return {
        ...state,
        settings: [
          ...data,
          // costKm: fromCents(data.costKm),
          // flatDriverFee: fromCents(data.flatDriverFee),
          // paymentEditThreshold: fromPercent(data.paymentEditThreshold)
        ],
      };
    }


    case SUCCESS(ACTION_TYPES.FETCH_BY_ID): {
      const data = action.payload.data
      return {
        ...state,
        settingsDetail: {
          ...data,
          costKm: fromCents(data.costKm),
          flatDriverFee: fromCents(data.flatDriverFee),
          paymentEditThreshold: fromPercent(data.paymentEditThreshold),
        },
      };
    }



    case SUCCESS(ACTION_TYPES.UPDATE):
      {
        const data = action.payload.data

        // data.forEach((item) => {
        //   item.costKm = fromCents(item.costKm);
        //   item.flatDriverFee = fromCents(item.flatDriverFee);
        //   item.paymentEditThreshold = fromPercent(item.paymentEditThreshold);

        //   return item;

        // });
        return {
          ...state,
          updating: false,
          updateSuccess: true,
          // settings: [
          //   ...data
          // ],
        };
      }

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/mmamaSettings';
// Actions

export const getMMamaSettings: ICrudGetAllAction<MMamaSettingsState> = () => {
  const requestUrl = `${apiUrl}`;
  console.log("getMMamaSettingsById", apiUrl)
  return {
    type: ACTION_TYPES.FETCH,
    payload: axios.get(requestUrl),
  };
};

export const getMMamaSettingsById: ICrudGetAllAction<MMamaSettingsState> = (id) => {
  const requestUrl = `${apiUrl + '/' + id}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(requestUrl),
  };
};


export const getMMamaSettingsByCountryID = (id) => {
  const requestUrl = `${apiUrl + '/byCountryId/' + id}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(requestUrl),
  };
};


export const deleteReportDashboard = (reportId) => {
  const requestUrl = `api/delete-report-menu/${reportId}`;
  return {
    type: ACTION_TYPES.DELETE_REPORT,
    payload: axios.get(requestUrl),
  };
};

export const updateMMamaSettings: ICrudPutAction<IMMamaSettings> = (mmamaSettings) => async dispatch => {
  const id = mmamaSettings.id;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE,
    payload: axios.put(apiUrl + '/' + id, mmamaSettings),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
