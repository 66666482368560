import { IPatient } from 'app/shared/model/patient.model';
import { IDriver } from 'app/shared/model/driver.model';
import { ICouncil } from 'app/shared/model/council.model';
import {IRegion} from "app/shared/model/region.model";

export interface IVillage {
  id?: string;
  name?: string;
  pickupLocation?: string;
  locationLat?: number;
  locationLon?: number;
  patients?: IPatient[];
  drivers?: IDriver[];
  council?: IRegion;
  activated?: boolean; 
}

export const defaultValue: Readonly<IVillage> = {};
