export interface IIncidentReport {
  id?: string;
  description?: string;
  enIncident?: string;
  stIncident?: string;
  countryId?: string;
  isActive?:boolean;
}

export const defaultValue: Readonly<IIncidentReport> = {};
