import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, PieChart, StackedBarChart } from '../../../charts';
import { SECONDARY_CHART_COLOR, MONTH_LABELS, PRIMARY_CHART_COLOR, COLORS } from '../constants';
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const TriageConditionsSection = ({ countReferrals, emergencyReferrals }) => {
    const [labels, setLabels] = useState([]);
    const [graphData, setGraphData] = useState({});
    const [antepartumCount, setAntepartumCount] = useState(0);
    const [antepartumData, setAntepartumData] = useState([]);
    const [labourCount, setLabourCount] = useState(0);
    const [labourData, setLabourData] = useState([]);
    const [postpartumCount, setPostpartumCount] = useState(0);
    const [postpartumData, setPostpartumData] = useState([]);
    const [neonatalCount, setNeonatalCount] = useState(0);
    const [neonatalData, setNeonatalData] = useState([]);
    const [neonatalLabels, setNeonatalLabels] = useState([]);
    const [neonatalGraphData, setNeonatalGraphData] = useState({});

    const TOTAL_CARD_GROUP_DATA = [
        { label: "3.1 Total maternal emergencies", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.motherCount },
        { label: "3.2 Total neonatal emergencies:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyCount },
        { label: "3.3 Total both emergencies:", icon: "content/images/pregnant_woman_black.svg", value: countReferrals?.bothCount },
    ]

    useEffect(() => {
        if (emergencyReferrals?.maternalAntepartum && Object.keys(emergencyReferrals?.maternalAntepartum)) {
            const months = [];
            const neonatalMonths = [];
            const mAntepartumKeys = Object.keys(emergencyReferrals?.maternalAntepartum);
            const mLabourKeys = Object.keys(emergencyReferrals?.maternalByLabour);
            const mPostpartumKeys = Object.keys(emergencyReferrals?.maternalByPostpartum);
            const data = {
                Antepartum: {
                  label: 'Antepartum',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'In Labour': {
                  label: 'In Labour',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
                Postpartum: {
                  label: 'Postpartum',
                  color: '#00c04b',
                  data: [],
                },
            };
            const neonatalGraph = {
                Community: {
                  label: 'Community',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'Health Facility': {
                  label: 'Health Facility',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
            };

            // Maternal
            const aggregatedGraphData = mergeAndSum([
                emergencyReferrals?.maternalAntepartum,
                emergencyReferrals?.maternalByLabour,
                emergencyReferrals?.maternalByPostpartum
            ])

            const graphDistrictKeys = Object.keys(aggregatedGraphData)
                .sort(function(a,b){return aggregatedGraphData[b]-aggregatedGraphData[a]});

            for(let i = 0; i < graphDistrictKeys.length; i++) {
                data['Antepartum'].data.push(emergencyReferrals?.maternalAntepartum[graphDistrictKeys[i]] ?? 0)
                data['In Labour'].data.push(emergencyReferrals?.maternalByLabour[graphDistrictKeys[i]] ?? 0)
                data['Postpartum'].data.push(emergencyReferrals?.maternalByPostpartum[graphDistrictKeys[i]] ?? 0)
            }

            // Neonatal
            const aggregatedNeonatalGraphData = mergeAndSum([
                emergencyReferrals?.neonatalByCommunity,
                emergencyReferrals?.neonatalByHf,
            ])

            const neonatalGraphDistrictKeys = Object.keys(aggregatedNeonatalGraphData)
                .sort(function(a,b){return aggregatedNeonatalGraphData[b]-aggregatedNeonatalGraphData[a]});

            for(let i = 0; i < neonatalGraphDistrictKeys.length; i++) {
                neonatalGraph['Community'].data.push(emergencyReferrals?.neonatalByCommunity[neonatalGraphDistrictKeys[i]] ?? 0)
                neonatalGraph['Health Facility'].data.push(emergencyReferrals?.neonatalByHf[neonatalGraphDistrictKeys[i]] ?? 0)
            }


            let antepartumSum = 0;
            let labourSum = 0;
            let postpartumSum = 0;
            let neonatalSum = 0;
            const antepartumKeys = Object.keys(emergencyReferrals?.antepartumEmergencies).map((el, i) => {
                antepartumSum = antepartumSum + emergencyReferrals?.antepartumEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.antepartumEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const labourKeys = Object.keys(emergencyReferrals?.labourEmergencies).map((el, i) => {
                labourSum = labourSum + emergencyReferrals?.labourEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.labourEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const postpartumKeys = Object.keys(emergencyReferrals?.postpartumEmergencies).map((el, i) => {
                postpartumSum = postpartumSum + emergencyReferrals?.postpartumEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.postpartumEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const neonatalKeys = Object.keys(emergencyReferrals?.neonatalEmergencies).map((el, i) => {
                neonatalSum = neonatalSum + emergencyReferrals?.neonatalEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.neonatalEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setLabels(graphDistrictKeys);
            setGraphData(data);
            setNeonatalLabels(neonatalGraphDistrictKeys);
            setNeonatalGraphData(neonatalGraph)
            setAntepartumCount(antepartumSum);
            setAntepartumData(antepartumKeys);
            setLabourCount(labourSum);
            setLabourData(labourKeys);
            setPostpartumCount(postpartumSum);
            setPostpartumData(postpartumKeys);
            setNeonatalCount(neonatalSum);
            setNeonatalData(neonatalKeys);
        }
    }, [emergencyReferrals]);

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>3. Triage Conditions</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 3)} col="col-4" />
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.4 Number of Emergencies per stage of pregnancy:", value: "1250" }}>
                        <StackedBarChart chartLabels={DUMMMY_LABELS} chartData={DUMMY_NOEPSOP_DATA} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.4 Number of maternal emergencies per stage of pregnancy", value: "" }}>
                        <StackedBarChart chartLabels={labels} chartData={graphData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.5 Antepartum emergencies - Triage conditon:", value: antepartumCount }}>
                        {/* <PieChart chartData={antepartumData} /> */}
                        <BarChart chartLabel='Antepartum emergencies' labels={antepartumData.map(item => item.label)} dataSet={antepartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.6 Labor emergencies - Triage conditon:", value: labourCount }}>
                        {/* <PieChart chartData={labourData} /> */}
                        <BarChart chartLabel='Labor emergencies' labels={labourData.map(item => item.label)} dataSet={labourData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.7 Postpartum emergencies - Triage conditon:", value: postpartumCount }}>
                        {/* <PieChart chartData={postpartumData} /> */}
                        <BarChart chartLabel='Postpartum emergencies' labels={postpartumData.map(item => item.label)} dataSet={postpartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.8 Neonatal emergencies:", value: "" }}>
                        <StackedBarChart chartLabels={neonatalLabels} chartData={neonatalGraphData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.9 Neonatal emergencies - Triage conditon:", value: neonatalCount }}>
                        {/* <PieChart chartData={neonatalData} /> */}
                        <BarChart chartLabel='Neonatal emergencies' labels={neonatalData.map(item => item.label)} dataSet={neonatalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
