import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import {
    getRegionsByType,
    getDispatchCenters,
    getAllFacility,
    getHealthFacilityLevel,
    getYear,
    getMonth,
    getTransportType,
    getJourneyType,
    getPatientType,
    getEmergencyType,
    getBypassType,
    // getOSTotalEmergencyReferrals,
    // getOSTotalPatientCases,
    // getOSTotalMaternalEmergencies,
    // getOSTotalNeonatalEmergencies,
    // getOSTotalBothEmergencies,
    getTotalReferralsPerMonth,
} from './payment-dashboard.reducer';
import { setFilterOption } from "../../dashboardWrapper/sidebar/subMenu/childSubMenu/child-sub-menu.reducer"
import { CardGroup, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBriefcase,
    faCashRegister,
} from "@fortawesome/free-solid-svg-icons";
import DashboardWrapper from '../../dashboardWrapper/dashboardWrapper';
import AgGridComp from '../../common/AgGridComp';
import { LineChart, AreaChart, BarChart, StackedBarChart, PieChart } from '../../charts';
import { shuffle } from 'lodash';
import { useInView } from "react-intersection-observer";
import { getFormattedMonthFromIndexes, getTotal } from '../../utils';
import "./paymentDashboard.scss";
import {
    DUMMMY_LABELS,
    DUMMY_DATA,
    BAR_CHART_DATA_SET,
    DATE_OPTIONS,
    DUMMY_PAYMENT_STATUS_DATA,
    DUMMY_CUMULATIVE_DATA_BY_MONTH,
    DUMMY_PAYMENT_STATUS_HF_DATA,
    DUMMY_PAYMENT_STATUS_HF_LABEL,
    DUMMY_PAPMPHF_ROW_DATA,
    DUMMY_OUTCOMES_ROW_DATA
} from './dummyData';

export const PRIMARY_CHART_COLOR = '#008631';
export const SECONDARY_CHART_COLOR = '#00ab41';
export const DUMMY_PAYMENT_STATUS_HF_LABEL_LENGTH = (DUMMY_PAYMENT_STATUS_HF_LABEL).length;

export const DisplayCardGroup = ({ data, col }) => {
    return (
        <>
            {data.map((el, i) => (
                <div key={i} className={`${col} card-group-container`}>
                    <div className="card-group p-1" style={{ background: "#FFFFFF" }}>
                        <div>
                            <img src={el["icon"]} alt="icon" className="card-group-icon" />
                        </div>
                        <div className="pl-1">{el["label"]} </div>
                        <div className="pl-1" style={{ fontWeight: 600 }}>{el["value"]}</div>
                    </div>
                </div>
            ))}
        </>
    )

}

export const DisplayIndicator = ({ data, children }) => {
    return (
        <>
            <Card style={{}} className="">
                <CardTitle style={{ fontSize: "10px" }}>
                    <span>{data["label"]}</span>
                    <span style={{ fontSize: "12px", fontWeight: 600, marginLeft: "2px" }}>{data["value"]}</span>
                </CardTitle>
                <CardBody className="p-0">
                    {children}
                </CardBody>
            </Card>
        </>
    )
}

export const OverviewSection = (props) => {
    const {
        // OSTotalEmergencyReferrals,
        // OSTotalPatientCases,
        // OSTotalNeonatalEmergencies,
        // OSTotalMaternalEmergencies,
        // OSTotalBothEmergencies,
        selectedFilterOption
    } = props;

    useEffect(() => {
        // props.getOSTotalEmergencyReferrals();
        // props.getOSTotalPatientCases();
        // props.getOSTotalMaternalEmergencies();
        // props.getOSTotalNeonatalEmergencies();
        // props.getOSTotalBothEmergencies();
        props.getTotalReferralsPerMonth(selectedFilterOption)
    }, [])

    const TOTAL_CARD_GROUP_DATA = [
        { label: "Total cumulative cost:", icon: "content/images/cost_icon.svg", value: "345" },
    ]

    useEffect(() => {
        props.getTotalReferralsPerMonth(selectedFilterOption)
    }, [selectedFilterOption])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>Overview</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 2)} col="col-6" />
            </div>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(2, 5)} col="col-4" />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "Average cost per trip:", value: "1250" }}>
                        <LineChart totalData={DUMMY_DATA} label="Total referrals per month" color={PRIMARY_CHART_COLOR} />
                    </DisplayIndicator>
                </div>
            </div>
        </div >
    )
}

// export const PaymentStatusSection = (props) => {
//     return (
//         <div className='mt-0 mb-3' style={{ overflow: 'hidden' }}>
//             <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>Payment Status</p>
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Number of payment by status:", value: "1250" }}>
//                         <BarChart chartLabel="Number of payment by status" labels={DUMMY_PAYMENT_STATUS_DATA} dataSet={BAR_CHART_DATA_SET.slice(0, 5)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Cumulative amount of payments by payment status:", value: "1250" }}>
//                         <BarChart chartLabel="Cumulative amount of payments by payment status" labels={DUMMY_PAYMENT_STATUS_DATA} dataSet={BAR_CHART_DATA_SET} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Cumulative amount of payment by month by status:", value: "1250" }}>
//                         <StackedBarChart chartLabels={DUMMMY_LABELS} chartData={DUMMY_CUMULATIVE_DATA_BY_MONTH} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Payment Details - HF to HF trips:" }}>
//                         <AgGridComp rowData={DUMMY_OUTCOMES_ROW_DATA} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Payment Details - Community to HF trips:" }}>
//                         <AgGridComp rowData={DUMMY_OUTCOMES_ROW_DATA} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//             <div className="row mb-3">
//                 <div className="col-12" style={DUMMY_PAYMENT_STATUS_HF_LABEL_LENGTH > 12 ? { scrollBehavior: 'auto', overflowY: 'scroll', maxHeight: '400px' } : {}} >
//                     < DisplayIndicator data={{ label: "Payment status - number of payment per HF:", value: "1250" }}>
//                         <BarChart chartLabel="Payment status - number of payment per HF:" labels={DUMMY_PAYMENT_STATUS_HF_LABEL} dataSet={DUMMY_PAYMENT_STATUS_HF_DATA} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
//                     </DisplayIndicator>
//                 </div>
//             </div >
//             <div className="row mb-3">
//                 <div className="col-12">
//                     <DisplayIndicator data={{ label: "Payment amount per month per HF:" }}>
//                         <AgGridComp rowData={DUMMY_PAPMPHF_ROW_DATA} />
//                     </DisplayIndicator>
//                 </div>
//             </div>
//         </div >
//     )
// }

const MeDashboard = (props) => {
    const {
        selectedFilterOption,
        region,
        district,
        council,
        dispatchCenter,
        healthFacility,
        healthFaciliyLevel,
        year,
        month,
        transportType,
        journeyType,
        patientType,
        emergencyType,
        bypassType
    } = props;

    const OPTIONS_FILTERS = [
        { label: "By Year", values: year, category: "Time", slug: "year" },
        { label: "By Month", values: month, category: "Time", slug: "month" },
        { label: "By Date Range", values: DATE_OPTIONS, category: "Time", slug: "dateRange" },
        { label: "By Region", values: region, category: "Geography", slug: "region" },
        { label: "By District", values: district, category: "Geography", slug: "district" },
        { label: "By Community council", values: council, category: "Geography", slug: "communityCouncil" },
        { label: "By Dispatch center", values: dispatchCenter, category: "Geography", slug: "dispatchCenter" },
        { label: "By Health Facility", values: healthFacility, category: "Health Facility", slug: "healthFacility" },
        { label: "By Health Facility level", values: healthFaciliyLevel, category: "Health Facility", slug: "healthFacilityLevel" },
        { label: "By Type of transport", values: transportType, category: "Cases", slug: "typeOfTransport" },
        { label: "By Journey type", values: journeyType, category: "Cases", slug: "journeyType" },
        { label: "By Patient type", values: patientType, category: "Cases", slug: "PatientType" },
        { label: "By Emergency type", values: emergencyType, category: "Cases", slug: "emergencyType" },
        { label: "By Bypass type", values: bypassType, category: "Cases", slug: "emergencyType" },
    ];

    const OPTIONS_FILTERS_CATEGORIES = ["Time", "Geography", "Health Facility", "Cases"];

    const MENU_ITEMS = [
        { label: "Overview", icon: faBriefcase, path: `${props.match.url}#overview` },
        { label: "Payment Status", icon: faCashRegister, path: `${props.match.url}#emergency-referrals` },
    ]

    useEffect(() => {
        props.getRegionsByType();
        props.getDispatchCenters();
        props.getAllFacility();
        props.getHealthFacilityLevel();
        props.getYear();
        props.getMonth();
        props.getTransportType();
        props.getJourneyType();
        props.getPatientType();
        props.getEmergencyType();
        props.getBypassType();
        props.setFilterOption({});
    }, [])

    const [overviewRef, overviewInView] = useInView({});
    const [emergencyReferralsRef, emergencyReferralsInView] = useInView({});
    const [triageConditionsRef, triageConditionsView] = useInView({});
    const [referralPathwayRef, referralPathwayInView] = useInView({});
    const [journeyRef, journeyRefInView] = useInView({});
    const [outcomesRef, outcomesInView] = useInView({});

    const activeTab = () => {
        if (overviewInView) {
            return "Overview";
        } else if (emergencyReferralsInView) {
            return "Emergency Referrals";
        } else if (triageConditionsView) {
            return "Triage Conditions";
        } else if (referralPathwayInView) {
            return "Referral Pathway";
        } else if (journeyRefInView) {
            return "Journey";
        } else if (outcomesInView) {
            return "Outcomes";
        } else {
            return null
        }
    };


    return (
        <DashboardWrapper optionsFilters={OPTIONS_FILTERS} optionsFiltersCategories={OPTIONS_FILTERS_CATEGORIES} menuItems={MENU_ITEMS} activeTab={activeTab}>
            <div className='content-container container-fluid'>
                <p className="mb-2" style={{ fontSize: "22px", fontWeight: 600, color: PRIMARY_CHART_COLOR }}>m-mama Payment Dashboard</p>
                <section id="overview" ref={overviewRef}>
                    <OverviewSection {...props} />
                </section>
                {/* <section id="emergency-referrals" ref={emergencyReferralsRef}>
                    <PaymentStatusSection {...props} />
                </section> */}
            </div>
        </DashboardWrapper>
    )
}

const mapStateToProps = ({ meDashboard, filterOption }: IRootState) => ({
    selectedFilterOption: filterOption.selectedFilterOption,
    region: meDashboard.region,
    district: meDashboard.district,
    council: meDashboard.council,
    dispatchCenter: meDashboard.dispatchCenter,
    healthFacility: meDashboard.healthFacility,
    healthFaciliyLevel: meDashboard.healthFaciliyLevel,
    year: meDashboard.year,
    month: meDashboard.month,
    transportType: meDashboard.transportType,
    journeyType: meDashboard.journeyType,
    patientType: meDashboard.patientType,
    emergencyType: meDashboard.emergencyType,
    bypassType: meDashboard.bypassType,
    // OSTotalEmergencyReferrals: meDashboard.OSTotalEmergencyReferrals,
    // OSTotalPatientCases: meDashboard.OSTotalPatientCases,
    // OSTotalMaternalEmergencies: meDashboard.OSTotalMaternalEmergencies,
    // OSTotalNeonatalEmergencies: meDashboard.OSTotalNeonatalEmergencies,
    // OSTotalBothEmergencies: meDashboard.OSTotalBothEmergencies,
});

const mapDispatchToProps = {
    getRegionsByType,
    getDispatchCenters,
    getAllFacility,
    getHealthFacilityLevel,
    getYear,
    getMonth,
    getTransportType,
    getJourneyType,
    getPatientType,
    getEmergencyType,
    getBypassType,
    // getOSTotalEmergencyReferrals,
    // getOSTotalPatientCases,
    // getOSTotalMaternalEmergencies,
    // getOSTotalNeonatalEmergencies,
    // getOSTotalBothEmergencies,
    getTotalReferralsPerMonth,
    setFilterOption
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MeDashboard)
