import {EmergencyType} from 'app/shared/model/enumerations/emergency-type.model';
import {IRegion} from "app/shared/model/region.model";

export interface IFollowup {
  id?: string;
  followupType?: EmergencyType;
  emergencyType?: EmergencyType;
  enFollowup?: string;
  stFollowup?: string;
  countryId?: string;
  isActive?:boolean;
  isCondition?:boolean;
  isAlive?:boolean;
 
}

export const defaultValue: Readonly<IFollowup> = {};
