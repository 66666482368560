import './header.scss';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'app/shared/reducers';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Brand, Home } from './header-components';
import { AccountMenu, AdminMenu, EntitiesMenu, ReportsMenu } from '../menus';
import config from "app/config/constants";
import { PERMISSIONS } from 'app/config/constants';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { getSession, getReportMenu } from 'app/shared/reducers/authentication';
import { IReportMenu } from "app/shared/model/reportMenu.model";
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from 'app/shared/layout/menus/menu-components';
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

export interface IHeaderProps extends StateProps, DispatchProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  permissions: string[];
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;

}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { mainMenus, permissions } = props
  const { reportMenus } = props;
  /**
   * This could be useful but disabled for now until
   * we figure out how to get the current environment.
   */
  const renderDevRibbon = () => null;
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    // props.getReportMenu(1);
    // console.log("data",data);

  }, [reportMenus]);
  const menuArray = [];
  mainMenus.forEach((menu) => {
    const child = mainMenus?.filter((ele) => ele.parentId === menu.id);
    if (menu.parentId === null) {
      const obj = { data: menu, children: child };
      menuArray.push(obj)
    }

  })
  const reportMenus1 = reportMenus.filter((ele) => {
    return props.isAdmin ? 1 : ele.countryId === props.userCountryId;
  }).sort((a, b) => {
    return a.displayOrder - b.displayOrder


  });

  const newMenu = reportMenus1;

  menuArray.sort((a, b) => a.data.displayOrder.localeCompare(b.data.displayOrder));

  console.log("permissions", permissions)
  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar dark expand="sm" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ml-auto" navbar>
            <Home />
            {menuArray.map((menu) => {
              const fruits = ["Banana", "Orange", "Apple", "Mango"];
              fruits.includes("Banana", 3);
              let navshow = false;
              if (permissions.includes(menu.data.permissions)) {
                navshow = true;
              }
              menu.children.sort((a, b) => a.displayOrder.localeCompare(b.displayOrder));
              return (<>
                {(menu.data.slug !== 'home' && menu.data.slug !== 'account' && menu.data.slug !== 'administration') ?

                  <NavDropdown show={navshow} icon={faChartArea} name={menu.data.name} id={menu.data.slug} style={{ overflow: 'auto' }}>

                    {menu.data.slug === 'program-dashboard' ?
                      <>
                        {reportMenus1.length > 0 ? reportMenus1.map((childMenu, index) => {
                          let childShow = false;
                          const childPermissions = childMenu.reportMenuPermissions;
                          if (childMenu.reportMenuPermissions !== null) {
                            for (let i = 0; i < childPermissions.length; i++) {

                              if (props.accountDetail.roles.includes(childPermissions[i].role)) {
                                childShow = true;
                              }
                            }

                          }

                          // console.log('childPermissions',childhow)
                          return (
                            <>
                              {/* {index === 0 && <MenuItem show={true} icon="asterisk" to="/program-dashboard/me-dashboard">
                                Lesotho M&E Dashboard
                              </MenuItem>
                              } */}
                              {/* index === 0 && <MenuItem show={true} icon="asterisk" to="/program-dashboard/payment-dashboard">
                                Payment Dashboard
                              </MenuItem> */}
                              {/* {index === 0 && <MenuItem show={true} icon="asterisk" to="/program-dashboard/case-management-dashboard">
                                Case Management Dashboard
                              </MenuItem>} */}
                              <MenuItem show={childShow} key={index + "-" + childMenu.slug} icon="asterisk" to={"/program-dashboard/" + childMenu.slug}>
                                {childMenu.label}
                              </MenuItem>
                            </>
                          )
                        })
                          : ''
                        }
                      </>
                      :
                      <>

                        {
                          menu.children.length > 0 ? menu.children.map((childMenu, index) => {
                            let childShow = false;
                            if (permissions.includes(childMenu.permissions)) {
                              childShow = true;
                            }


                            return (
                              <>

                                <MenuItem show={childShow} key={index + "-" + childMenu.slug} icon="asterisk" to={"/" + childMenu.slug}>
                                  {childMenu.name}
                                </MenuItem>

                              </>
                            )
                          }) :
                            <></>
                        }

                      </>

                    }
                  </NavDropdown>
                  :
                  ''
                }

              </>)

            }
            )}
            {
              props.isAuthenticated && (props.isAdmin) && (
                <AdminMenu pagesView={props.permissions} showSwagger={props.isSwaggerEnabled} showDatabase={!props.isInProduction} />
              )}
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  reportMenus: authentication.reportMenus,
  mainMenus: authentication.mainMenus,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),

  accountDetail: authentication.account
});

const mapDispatchToProps = {
  getReportMenu,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
