import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavDropdown} from './menu-components';
import { faChartLine, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
export const AdminMenuItems = (props)=>{


    return (
  <>
    <MenuItem icon="user" show={true}  to="/admin/user-management">
      User Management
    </MenuItem>
    <MenuItem icon="money-check-alt" show={true}  to="/admin/mmama-settings">
    Mmama Settings
    </MenuItem>
   
    <MenuItem icon={faChartLine} show={true}  to="/admin/report-settings">
      Program Dashboard Settings
    </MenuItem>

    <MenuItem icon={faChartLine} show={true}  to="/admin/permission-settings">
      Manage Permission
    </MenuItem>

    <MenuItem icon={faMoneyBill} show={true}  to="/admin/payment-breakdown">
      Payment Breakdown
    </MenuItem>
   
  </>
  )

  
};

const swaggerItem = (
  <MenuItem show={true} icon="book" to="/admin/docs">
    API
  </MenuItem>
);

const databaseItem = (
  <DropdownItem tag="a" href="./h2-console" target="_tab">
    <FontAwesomeIcon icon="hdd" fixedWidth /> Database
  </DropdownItem>
);

export const AdminMenu = ({ showSwagger, showDatabase,pagesView }) => (
  <NavDropdown  show={true} permission={pagesView} icon="user-plus" name="Administration" style={{ width: '180%' }} id="admin-menu">
    <AdminMenuItems pagesView={pagesView}/>
  </NavDropdown>
);

export default AdminMenu;
