import axios from 'axios';
// import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult} from 'react-jhipster';

// import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

// import {defaultValue, IPayment, IPaymentUpdate,IPaymentFilter} from 'app/shared/model/payment.model';
import {defaultValue} from 'app/shared/model/patient-case.model';
// import {PaymentStatus} from "app/shared/model/enumerations/payment-status.model";
// import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IPatientCase} from 'app/shared/model/patient-case.model';

export const ACTION_TYPES = {
  FETCH_PATIENT_LIST: 'case-management/FETCH_PATIENT_LIST',
  FETCH_PATIENT: 'case-management/FETCH_PATIENT',
  FETCH_PATIENT_CASE: 'case-management/FETCH_PATIENT_CASE',
  PATCH_PATIENT: 'case-management/PATCH_PATIENT',
  CREATE_PATIENT: 'case-management/CREATE_PATIENT',
  UPDATE_PATIENT: 'case-management/UPDATE_PATIENT',
  DELETE_PATIENT: 'case-management/DELETE_PATIENT',
  RESET: 'case-management/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  patientCases: [] as ReadonlyArray<IPatientCase>,
  totalItems: 0,
  patientCase: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type CaseManagementState = Readonly<typeof initialState>;

// Reducer

export default (state: CaseManagementState = initialState, action): CaseManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_CASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.PATCH_PATIENT):
    case REQUEST(ACTION_TYPES.CREATE_PATIENT):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENT):
    case REQUEST(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENT):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENT):
    case FAILURE(ACTION_TYPES.DELETE_PATIENT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_CASE):
    case FAILURE(ACTION_TYPES.PATCH_PATIENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_LIST):
      
      return {
        ...state,
        loading: false,
        patientCases: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        loading: false,
        patientCase: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_CASE):
      return {
        ...state,
        loading: false,
        patientCase: {
          ...state.patientCase,
          // journey: {
          //   ...state.patientCase.journey,
          //   patientCase: action.payload.data,
          // }
        },
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        patientCase: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        // patientCase: { 
          // id: null, 
          // status: null,  
          // requestedAmount: null}, // TODO
      };
    case SUCCESS(ACTION_TYPES.PATCH_PATIENT): {
      const data = action.payload.data;
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        patientCase: {
          ...state.patientCase,
          // overrideAmount: data.overrideAmount,
          // status: data.status
        }, // TODO
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patient-cases-findall';

// Actions

export const getPatientCases = (filter: any) => {
  // console.log("countryId cal",filter)
  let requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}&page=${filter.activePage}&size=25`;
  // &sort=${filter.sort.sortType},${filter.sort.sortOrder}`;
  if(filter.districtId !== '' && filter.districtId){
    requestUrl += `&districtId=${filter.districtId}`;
  } else if(filter.regionId !== '' && filter.regionId){
    requestUrl += `&regionId=${filter.regionId}`;
  } else if(filter.countryId !== '' && filter.countryId){
    requestUrl += `&countryId=${filter.countryId}`;
  }
  if(filter.startDate !== '' && filter.startDate){
    requestUrl += `&startDate=${filter.startDate}`;
  }  
  if(filter.endDate !== '' && filter.endDate){
    requestUrl += `&endDate=${filter.endDate}`;
  }

  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get(requestUrl),
  }
}

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});