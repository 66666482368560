import './payment-detail.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Badge, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { getPaymentAndPatientCase, changePaymentStatus, patchPayment, processPaymentOnline } from './payment.reducer';
import { getMMamaSettings as getSettings } from 'app/modules/administration/mmama-settings/mmama-settings.reducer';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import OverridePriceModal from './payment-override-price-modal';
import { fromCents, toCents } from 'app/shared/util/money-utils';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { APP_DATE_FORMAT, PERMISSIONS } from 'app/config/constants';
import Loader from "react-loader-spinner";
import set = Reflect.set;
import LoadingOverlay from 'react-loading-overlay';

export interface IPaymentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

const PatientTypeMap = {
  "MOTHER": "Mother",
  "BABY": "Baby",
  "BOTH": "Mother & Baby"
}

export const PaymentDetail = (props: IPaymentDetailProps) => {
  // Constants
  const apiUrl = 'api/payments';

  // Props
  const { payment, mmamaSettings } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [patchingPayment, setPatchingPayment] = useState<boolean>(false);
  const journey = payment.payment.journey;
  const route = payment.payment.journey?.route;
  const paymentPrice = fromCents(payment.payment.overrideAmount || payment.payment.requestedAmount);
  const paymentRequestedPrice = fromCents(payment.payment.requestedAmount || payment.payment.overrideAmount);
  const [additionalNotes, setAdditionalNotes] = useState("")

  const handleAdditionalNotes = event => {
    (
      setAdditionalNotes(event.target.value)
    )
  }

  useEffect(() => {
    props.getPaymentAndPatientCase(props.match.params.id);
    props.getSettings();
    setPatchingPayment(false);
  }, [payment.payment.status]);

  useEffect(() => {
    if(payment.updateSuccess) {
      if (payment.payment.status === 'PROCESSING_ERROR') {
        alert('Processing error')
      }else if (payment.payment.status === 'PROCESSED') {
        alert('Processed successfully')
      }
    }
  }, [payment.payment.status, payment.updateSuccess]);

  useEffect(() => {
    if(payment.errorMessage) {
      alert('Payment Failed: ' + payment.errorMessage)
      setPatchingPayment(false);
    }
  }, [payment.errorMessage]);

  // MARK: - Event handlers
  const changeRequestStatus = (status: PaymentStatus, paymentMethod: string) => {
    setPatchingPayment(true)
    // if (paymentMethod === 'OFFLINE') {
    //   status = PaymentStatus.PROCESSED_OFFLINE;
    // }
    props.patchPayment(payment.payment.id, { status, additionalNotes, paymentMethod });
  }

  const processOnline = (status: PaymentStatus) => {
    setPatchingPayment(true)
    props.processPaymentOnline(payment.payment.id);
  }

  const overridePrice = (newPrice: number) => {
    props.patchPayment(payment.payment.id, { overrideAmount: toCents(newPrice), additionalNotes });
  }

  // Rendering
  if (payment.loading) {
    return (
      <p>Loading...</p>
    )
  }
  const countryId = journey?.patientCase?.patient?.countryId;

  let mmamaSettingsDetail = {
    paymentEditThreshold: 0,
    costKm: 0,
    flatDriverFee: 0,
    countryId: '0',
    countryName: '',
    paymentMethod: '',
    currencySymbol: ''
  }

  mmamaSettings.forEach((mmamaSetting) => {
    if (payment.payment.countryId === mmamaSetting.country.id) {
      mmamaSettingsDetail = mmamaSetting;
    }
  });
  return (
    <>
    <LoadingOverlay active={patchingPayment} spinner text='Loading...' >
      <OverridePriceModal
        opened={modalOpen}
        toggle={() => { setModalOpen(false); }}
        onSave={overridePrice}
        defaultAmount={paymentPrice}
        settings={mmamaSettingsDetail}
        paymentRequestedPrice={paymentRequestedPrice}
      />
      <Row className='payment-detail-header-background p-3'>
        <Col md="8">
          <h3>Patient Case [<b>{journey?.patientCase?.id}</b>]</h3>
          <h3 className='payment-detail-first-title'>{PatientTypeMap[journey?.patientCase?.type]}</h3>
          <p className='payment-detail-mother-baby-label'>{journey?.patientCase?.patient?.firstName} {journey?.patientCase?.patient?.lastName}</p>
          <h2 className='payment-detail-subtitle-header'>Primary Contact ({journey?.patientCase?.primaryContactType})</h2>
          <p className='payment-detail-subtitle'>{(journey?.patientCase?.primaryContactName || 'Name Unspecified')}</p>
          {journey?.patientCase?.primaryContactNumber != null ? (
            <p className='payment-detail-subtitle'>Primary Contact Number: {journey.patientCase.primaryContactNumber}</p>
          ) : null}
          {journey?.patientCase?.secondaryContactNumber != null ? (
            <p className='payment-detail-subtitle'>Secondary Contact Number: {journey.patientCase.secondaryContactNumber}</p>
          ) : null}
        </Col>
        <Col md="4">
          <Badge className="btn btn-primary float-right ml-2">{payment.payment.status}</Badge>
          <Badge className="btn btn-primary float-right ml-2">{journey?.patientCase?.caseStatus}</Badge>
          <Badge className="btn btn-danger float-right">{convertDateTimeFromServer(payment.payment.createdDateTime)}</Badge>
        </Col>
      </Row>
      {
        ((journey?.patientCase?.motherSymptoms || journey?.patientCase?.babySymptoms) && (
          <Row className='p-3'>
            <Col>
              <h2 className="payment-detail-header mt-3">Symptoms</h2>
              <p className="payment-detail-body">{journey?.patientCase?.motherSymptoms?.map(symptom => symptom.enCondition).join(', ')}</p> {/* TODO: confirm that these should be in english */}
              <p className="payment-detail-body">{journey?.patientCase?.babySymptoms?.map(symptom => symptom.enCondition).join(', ')}</p>
            </Col>
          </Row>
        ))
      }
      <Row className='p-3'>
        <Col md="4">
          <h2 className="payment-detail-header mt-3">Journey 1</h2>
        </Col>
        <Col md="8">
          <div style={{ float: 'right' }}>
            <Badge>Route</Badge>{' '}
            <Badge color="primary">{journey?.totalDistance} km</Badge>{' '}
            <Badge color="success">{paymentPrice} {mmamaSettingsDetail.currencySymbol ? mmamaSettingsDetail.currencySymbol : 'LSL'}</Badge>
            {(payment.payment.status === PaymentStatus.REQUESTED) && (
              <Button onClick={() => { setModalOpen(true); }} className="btn btn-primary" style={{ marginLeft: 30 }}>
                EDIT PAYMENT
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row className="payment-detail-journey-cell">
        <Col>
          <h2 className='payment-detail-subtitle-header mt-2'>Pickup Location ({journey?.startHealthcareFacility ? `Facility` : journey?.startVillage ? `Village` : ``})</h2>
          <p className='payment-detail-subtitle'>{journey?.startHealthcareFacility?.name || journey?.startVillage?.name}</p>
        </Col>
      </Row>
      <Row className="payment-detail-journey-cell">
        <Col>
          <h2 className='payment-detail-subtitle-header mt-2'>Destination ({journey?.endHealthcareFacility ? `Facility` : journey?.endVillage ? `Village` : ``})</h2>
          <p className='payment-detail-subtitle'>{journey?.endHealthcareFacility ? `${journey?.endHealthcareFacility?.name} ${journey?.endHealthcareFacility?.level}`: `${journey?.endVillage?.name}`}</p>
        </Col>
      </Row>
      {
        (journey?.incidentReport?.description && (
          <Row className="payment-detail-journey-cell">
            <Col>
              <h2 className='payment-detail-subtitle-header mt-2'>Incident Reported</h2>
              <p className='payment-detail-subtitle'>{journey?.incidentReport?.description}</p>
            </Col>
          </Row>
        ))
      }
      <Row className="payment-detail-journey-cell">
        <Col md="8">
          <h2 className='payment-detail-subtitle-header mt-2'>Community Driver</h2>
          <p className='payment-detail-subtitle'>{journey?.driver?.firstName} {journey?.driver?.lastName}</p>
        </Col>
        <Col md="4">
          <div style={{ float: 'right' }}>
            <h2 className='payment-detail-subtitle-header mt-2'>M-Pesa ID</h2>
            <p className='payment-detail-subtitle'>{journey?.driver?.mpesaNumber}</p>
          </div>
        </Col>
      </Row>

      {
        (payment.payment.status === PaymentStatus.PROCESSED) && (
          <Row className="payment-detail-journey-cell">
            <Col>
              <h2 className='payment-detail-subtitle-header mt-2'>M-Pesa Transaction ID</h2>
              <p className='payment-detail-subtitle'>{payment.payment.mpesaTransactionId}</p>
            </Col>
          </Row>
        )
      }
      {
        (payment.payment.status === PaymentStatus.PROCESSING_ERROR) && (
          <Row className="payment-detail-journey-cell">
            <Col>
              <h2 className='payment-detail-subtitle-header mt-2'>Payment Processing Error</h2>
              <p className='payment-detail-subtitle-danger'>{payment.payment.processingError}</p>
            </Col>
          </Row>
        )
      }

      <Row className="payment-detail-journey-cell">
        <Col md="8">
          <h2 className='payment-detail-subtitle-header mt-2'>Additional Notes</h2>
          <p className='payment-detail-subtitle'>{payment.payment.additionalNotes}</p>
        </Col>
      </Row>
      {
        (payment.payment.status !== PaymentStatus.PROCESSED && payment.payment.status !== PaymentStatus.REJECTED) && (
          <Row>
            <Col className='m-3'>
              <Form>
                <FormGroup>
                  <Label for="additional-notes">Edit Additional Notes</Label>
                  <Input name="additionalNotes" id="additional-notes" onChange={handleAdditionalNotes} />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        )
      }

      <Row>
        <Col className='m-3'>
          {
            !patchingPayment && (
              <>
                {payment.payment.status !== PaymentStatus.PROCESSING_ERROR && payment.payment.status !== PaymentStatus.PROCESSED && payment.payment.status !== PaymentStatus.PROCESSED_OFFLINE && payment.payment.status !== PaymentStatus.REJECTED && (
                  <Button onClick={() => { changeRequestStatus(PaymentStatus.REJECTED, mmamaSettingsDetail.paymentMethod) }} className="btn btn-danger" style={{ margin: 10 }}>
                    REJECT REQUEST
                  </Button>
                )}


                {
                  (payment.payment.status === PaymentStatus.PROCESSING_ERROR) && (
                    <Button onClick={() => {
                      props.changePaymentStatus(payment.payment.id, PaymentStatus.APPROVED)
                    }} className="btn btn-danger" style={{ margin: 10 }}>
                      CHANGE STATUS TO APPROVED
                    </Button>
                  )
                }

                {(payment.payment.status === PaymentStatus.REQUESTED && ((hasAnyPermission(props.permissions, [PERMISSIONS.PAYMENTS_APPROVE]) &&
                  hasAnyPermission(props.permissions, [PERMISSIONS.USER_ADMIN])) || (props.isAdmin))) && (
                  <Button onClick={() => {
                    setPatchingPayment(true);
                    changeRequestStatus(PaymentStatus.APPROVED,PaymentStatus.APPROVED)
                  }} className="btn btn-primary" style={{ float: 'right', margin: 10 }}>
                    APPROVE REQUEST
                  </Button>
                )}

                {(payment.payment.status === PaymentStatus.APPROVED && (hasAnyPermission(props.permissions, [PERMISSIONS.PAYMENTS_PROCESS])
                  || hasAnyPermission(props.permissions, [PERMISSIONS.USER_ADMIN]))) && (
                  <>
                    <Button onClick={() => { changeRequestStatus(PaymentStatus.PROCESSED_OFFLINE, 'OFFLINE') }} className="btn btn-primary" style={{ float: 'right', margin: 10 }}>
                      PROCESS PAYMENT-OFFLINE
                    </Button>

                    {mmamaSettingsDetail.paymentMethod !== 'OFFLINE' ?

                      <Button onClick={() => { processOnline(PaymentStatus.PROCESSING) }} className="btn btn-primary" style={{ float: 'right', margin: 10 }}>
                        PROCESS PAYMENT-ONLINE
                      </Button>
                      : ""
                    }
                  </>
                )}
                {
                  payment.payment.status === PaymentStatus.PROCESSING && (
                    <p style={{ textAlign: 'center' }}>Payment is currently processing.</p>
                  )
                }
                {
                  payment.payment.status === PaymentStatus.PROCESSED || payment.payment.status === PaymentStatus.PROCESSED_OFFLINE && (
                    <p style={{ textAlign: 'center' }}>Payment has been processed.</p>
                  )
                }
                {
                  payment.payment.status === PaymentStatus.REJECTED && (
                    <p style={{ textAlign: 'center' }}>Payment has been rejected.</p>
                  )
                }
              </>
            )
          }
        </Col>
      </Row>
    </LoadingOverlay>
    </>
  );
};

const mapStateToProps = ({ payment, mmamaSettings, authentication }: IRootState) => ({
  payment,
  mmamaSettings: mmamaSettings.settings,
  permissions: authentication.permissions,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getPaymentAndPatientCase,
  patchPayment,
  processPaymentOnline,
  getSettings,
  changePaymentStatus
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
