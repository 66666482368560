import React from 'react';

import {NavbarBrand, NavItem, NavLink} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import logo from './../../../../content/images/M-MAMA_Linear_Earth_RGB.jpg';
import appConfig from 'app/config/constants';
// M-MAMA_Linear_Earth_RGB-White.png
export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/M-MAMA_Linear_Earth_RGB-White.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <img  className="brand-icon " src="content/images/M-MAMA_Linear_Earth_RGB-White.png" alt="Logo" />
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
  </NavItem>
);
