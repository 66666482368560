import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, PieChart } from '../../../charts';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR } from '../constants';
import AgGridComp from '../../../common/AgGridComp';

export const OutcomesSection = ({countReferrals, referrals}) => {
    const [antepartumData, setAntepartumData] = useState([]);
    const [labourData, setLabourData] = useState([]);
    const [postpartumData, setPostpartumData] = useState([]);
    const [babyAntepartumData, setBabyAntepartumData] = useState([]);
    const [babyLabourData, setBabyLabourData] = useState([]);
    const [babyPostpartumData, setBabyPostpartumData] = useState([]);
    // const [motherOutcomesData, setMotherOutcomesData] = useState([]);
    const [babyFollowupsData, setBabyFollowupsData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [antepartumConditionData, setAntepartumConditionData] = useState([]);
    const [labourConditionData, setLabourConditionData] = useState([]);
    const [postpartumConditionData, setPostpartumConditionData] = useState([]);
    const [babyAntepartumConditionData, setBabyAntepartumConditionData] = useState([]);
    const [babyLabourConditionData, setBabyLabourConditionData] = useState([]);
    const [babyPostpartumConditionData, setBabyPostpartumConditionData] = useState([]);
    const [babyConditionsData, setBabyConditionsData] = useState([]);

    const TOTAL_CARD_GROUP_DATA = [
        { label: "6.1 Number of follow up conducted :", icon: "content/images/new_ambulance.svg", value: countReferrals?.totalFollowups },
        { label: "6.2 Number of maternal deaths:", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.motherDead },
        { label: "6.3 Number of neonatal deaths:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyDead },
    ]

    useEffect(() => {
        if(referrals?.followupAntepartum && Object.keys(referrals?.followupAntepartum)) {
            const antepartumKeys = Object.keys(referrals?.followupAntepartum).map((el, i) => {
                return { label: el, data: referrals?.followupAntepartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const labourKeys = Object.keys(referrals?.followupLabour).map((el, i) => {
                return { label: el, data: referrals?.followupLabour[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const postpartumKeys = Object.keys(referrals?.followupPostpartum).map((el, i) => {
                return { label: el, data: referrals?.followupPostpartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            // const motherOutcomesKeys = Object.keys(referrals?.motherOutcomes).map((el, i) => {
            //     return { label: el, data: referrals?.motherOutcomes[el]}
            // });

            const babyAntepartumKeys = Object.keys(referrals?.babyFollowupAntepartum).map((el, i) => {
                return { label: el, data: referrals?.babyFollowupAntepartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyLabourKeys = Object.keys(referrals?.babyFollowupLabour).map((el, i) => {
                return { label: el, data: referrals?.babyFollowupLabour[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyPostpartumKeys = Object.keys(referrals?.babyFollowupPostpartum).map((el, i) => {
                return { label: el, data: referrals?.babyFollowupPostpartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyFollowupsKeys = Object.keys(referrals?.babyFollowups).map((el, i) => {
                return { label: el, data: referrals?.babyFollowups[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const tableKeys = [];

            Object.keys(referrals?.motherCsection).sort().map((item, i) => {
                // if(item !== "NULL") {
                    tableKeys.push({
                        ' ': item,
                        'Mother is alive, C-section delivery': referrals?.motherCsection[item],
                        'Mother is alive, normal delivery': referrals?.motherNormal[item],
                        'Mother is alive, pregnant': referrals?.motherPregnant[item],
                        'Mother is dead': referrals?.motherDead[item],
                        'Grand Total': referrals?.motherCsection[item] + referrals?.motherNormal[item] + referrals?.motherPregnant[item] + referrals?.motherDead[item],
                    })
                // }
            })

            setAntepartumData(antepartumKeys);
            setLabourData(labourKeys);
            setPostpartumData(postpartumKeys);
            // setMotherOutcomesData(motherOutcomesKeys);
            setBabyAntepartumData(babyAntepartumKeys);
            setBabyLabourData(babyLabourKeys);
            setBabyPostpartumData(babyPostpartumKeys);
            setBabyFollowupsData(babyFollowupsKeys);
            setTableData(tableKeys)
        }
    }, [referrals])


    useEffect(() => {
        if(referrals?.conditionAntepartum && Object.keys(referrals?.conditionAntepartum)) {
            const antepartumConditionKeys = Object.keys(referrals?.conditionAntepartum).map((el, i) => {
                return { label: el, data: referrals?.conditionAntepartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const labourConditionKeys = Object.keys(referrals?.conditionLabour).map((el, i) => {
                return { label: el, data: referrals?.conditionLabour[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const postpartumConditionKeys = Object.keys(referrals?.conditionPostpartum).map((el, i) => {
                return { label: el, data: referrals?.conditionPostpartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyAntepartumConditionKeys = Object.keys(referrals?.babyConditionAntepartum).map((el, i) => {
                return { label: el, data: referrals?.babyConditionAntepartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyLabourConditionKeys = Object.keys(referrals?.babyConditionLabour).map((el, i) => {
                return { label: el, data: referrals?.babyConditionLabour[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyPostpartumConditionKeys = Object.keys(referrals?.babyConditionPostpartum).map((el, i) => {
                return { label: el, data: referrals?.babyConditionPostpartum[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyConditionsKeys = Object.keys(referrals?.babyConditions).map((el, i) => {
                return { label: el, data: referrals?.babyConditions[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setAntepartumConditionData(antepartumConditionKeys);
            setLabourConditionData(labourConditionKeys);
            setPostpartumConditionData(postpartumConditionKeys);
            setBabyAntepartumConditionData(babyAntepartumConditionKeys);
            setBabyLabourConditionData(babyLabourConditionKeys);
            setBabyPostpartumConditionData(babyPostpartumConditionKeys);
            setBabyConditionsData(babyConditionsKeys);
        }
    }, [referrals])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>6. Outcomes</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA} col="col-4" />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.4 Maternal outcomes - Antepartum", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - Antepartum' labels={antepartumData.map(item => item.label)} dataSet={antepartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.5 Maternal outcomes - In labour", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - In labour' labels={labourData.map(item => item.label)} dataSet={labourData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.6 Maternal outcomes - Postpartum", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - Postpartum' labels={postpartumData.map(item => item.label)} dataSet={postpartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby outcomes (maternal emergencies)", value: "" }}> */}
                        {/* <PieChart chartData={NEONATAL_OUTCOMES_PIE_DATA} /> */}
                        {/* <BarChart chartLabel='Baby outcomes (maternal emergencies)' labels={motherOutcomesData.map(item => item.label)} dataSet={motherOutcomesData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby Outcomes for “Antepartum” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby outcomes - Baby Outcomes for “Antepartum” Emergencies' labels={babyAntepartumData.map(item => item.label)} dataSet={babyAntepartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Baby Outcomes for “Labour” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Labour” Emergencies' labels={babyLabourData.map(item => item.label)} dataSet={babyLabourData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.9 Baby Outcomes for “Postpartum” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Postpartum” Emergencies' labels={babyPostpartumData.map(item => item.label)} dataSet={babyPostpartumData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.10 Baby Outcomes for “Neonatal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Neonatal” Emergencies' labels={babyFollowupsData.map(item => item.label)} dataSet={babyFollowupsData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Outcomes Table", value: "" }}>
                        {tableData.length && <AgGridComp rowData={tableData} />}
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.4 Maternal conditions - Antepartum", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - Antepartum' labels={antepartumConditionData.map(item => item.label)} dataSet={antepartumConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.5 Maternal conditions - In labour", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - In labour' labels={labourConditionData.map(item => item.label)} dataSet={labourConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.6 Maternal conditions - Postpartum", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - Postpartum' labels={postpartumConditionData.map(item => item.label)} dataSet={postpartumConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby Conditions for “Antepartum” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Antepartum” Emergencies' labels={babyAntepartumConditionData.map(item => item.label)} dataSet={babyAntepartumConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Baby Conditions for “Labour” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Labour” Emergencies' labels={babyLabourConditionData.map(item => item.label)} dataSet={babyLabourConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.9 Baby Conditions for “Postpartum” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Postpartum” Emergencies' labels={babyPostpartumConditionData.map(item => item.label)} dataSet={babyPostpartumConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.10 Baby Conditions for “Neonatal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Neonatal” Emergencies' labels={babyConditionsData.map(item => item.label)} dataSet={babyConditionsData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
