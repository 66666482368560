  import {PaymentStatus} from 'app/shared/model/enumerations/payment-status.model';
  import {IIncidentReport} from './incident-report.model';
  import {IPatientCase} from './patient-case.model';
  import {IRoute} from './route.model';

  export interface IPaymentDriver {
    id: string;
    firstName?: string;
    lastName: string;
    phoneNumber: string;
    mpesaNumber: string;
  }

  export interface IPaymentJourney {
    endVillage?: any;
    endHealthcareFacility?: any;
    startVillage?: any;
    startHealthcareFacility?: any;
    id: string;
    incidentReportDescription?: string;
    incidentReported?: boolean;
    totalCost: number;
    totalDurationSeconds: number;
    totalDistance: number;
    driver: IPaymentDriver;
    route?: IRoute;
    patientCase?: IPatientCase;
    incidentReport?: IIncidentReport;

  }

  export interface IPayment {
    id?: string;
    status?: PaymentStatus;
    requestedAmount?: number;
    processingError?: string;
    mpesaTransactionId?: string;
    overrideAmount?: number;

    tenderedDateTime?: string;

    approverId?: string
    approvedDateTime?: string;
    approverNote?: string;

    processorId?: string
    processedDateTime?: string;
    processorNote?: string;

    additionalNotes?: string;
    countryId?: string;
    journey?: IPaymentJourney

    createdDateTime?: Date;
    rejectedDateTime?: Date;
  }

  export interface IPaymentUpdate {
    status?: PaymentStatus;
    overrideAmount?: number;
    paymentMethod?:string;
    additionalNotes?: string;
  }
  export interface IPaymentFilter {
    countryId?: string;
    status?: string;
    activePage?:number;
    paymentId?:string;
    sort?:ISortType;
  }

  export interface ISortType {
    sortType?: string;
    sortOrder?: string;
  }
  export const defaultValue: Readonly<IPayment> = {};
