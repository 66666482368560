import axios from 'axios';
import {
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
  Storage,
  IPayloadResult
} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {REQUEST, SUCCESS, FAILURE} from 'app/shared/reducers/action-type.util';
import {IVehicleType, defaultValue} from 'app/shared/model/vehicleType.model';
import {IRegion} from "app/shared/model/region.model";
import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IPatientCase} from "app/shared/model/patient-case.model";

export const ACTION_TYPES = {
  FETCH_VEHICLE_TYPE_LIST: 'vehicleType/FETCH_VEHICLE_TYPE_LIST',
  FETCH_VEHICLE_TYPE: 'vehicleType/FETCH_VEHICLE_TYPE',
  CREATE_VEHICLE_TYPE: 'vehicleType/CREATE_VEHICLE_TYPE',
  UPDATE_VEHICLE_TYPE: 'vehicleType/UPDATE_VEHICLE_TYPE',
  DELETE_VEHICLE_TYPE: 'vehicleType/DELETE_VEHICLE_TYPE',
  RESET: 'vehicleType/RESET',
  SET_ACTIVE_COUNTRY: 'vehicleType/SET_ACTIVE_COUNTRY',
  FETCH_ALL_COUNTRIES: 'vehicleType/FETCH_ALL_COUNTRIES',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVehicleType>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  countries: [] as ReadonlyArray<IRegion>
};

export type VehicleTypeState = Readonly<typeof initialState>;

// Reducer

export default (state: VehicleTypeState = initialState, action): VehicleTypeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VEHICLE_TYPE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_VEHICLE_TYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VEHICLE_TYPE):
    case REQUEST(ACTION_TYPES.UPDATE_VEHICLE_TYPE):
    case REQUEST(ACTION_TYPES.DELETE_VEHICLE_TYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_VEHICLE_TYPE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VEHICLE_TYPE):
    case FAILURE(ACTION_TYPES.CREATE_VEHICLE_TYPE):
    case FAILURE(ACTION_TYPES.UPDATE_VEHICLE_TYPE):
    case FAILURE(ACTION_TYPES.DELETE_VEHICLE_TYPE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VEHICLE_TYPE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VEHICLE_TYPE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VEHICLE_TYPE):
    case SUCCESS(ACTION_TYPES.UPDATE_VEHICLE_TYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VEHICLE_TYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IVehicleType>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/vehicle_type';

// Actions

// export const getEntities: ICrudGetAllAction<IVehicleType> = (page, size, sort) => ({
//   type: ACTION_TYPES.FETCH_VEHICLE_TYPE_LIST,
//   payload: axios.get<IVehicleType>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
// });
export const getEntities: (page?, size?, sort?) => IPayload<IVehicleType> | IPayloadResult<IVehicleType> = (page, size, sort) => async dispatch => {
  const requestUrl = 'api/vehicle_type';
 
  const symptoms = await dispatch({
    type: ACTION_TYPES.FETCH_VEHICLE_TYPE_LIST,
    payload: axios.get<IVehicleType>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  });

  return symptoms
}

export const getEntity: ICrudGetAction<IVehicleType> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VEHICLE_TYPE,
    payload: axios.get<IVehicleType>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVehicleType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VEHICLE_TYPE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IVehicleType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VEHICLE_TYPE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const createVehicleType = (data: any) => {
  const result = {
    type: ACTION_TYPES.CREATE_VEHICLE_TYPE,
    payload: axios.post(apiUrl, data)
  };
  // dispatch(getEntities());
  return result;
};

export const updateVehicleType = (id: any, data: any) => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.UPDATE_VEHICLE_TYPE,
    payload: axios.put(requestUrl, data)
  }
};

export const deleteEntity: ICrudDeleteAction<IVehicleType> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VEHICLE_TYPE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
