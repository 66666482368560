import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CaseManagement from './case-management';
// import RejectionReasonDetail from './rejection-reason-detail';
// import RejectionReasonUpdate from './rejection-reason-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/* <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RejectionReasonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RejectionReasonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RejectionReasonDetail} /> */}
      <ErrorBoundaryRoute path={match.url} component={CaseManagement} />
    </Switch>
  </>
);

export default Routes;
