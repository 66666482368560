import React from 'react';
import {Switch} from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import vehicleType from './vehicleType';
import vehicleTypeDetail from './vehicleType-detail';
import vehicleTypeUpdate from './vehicleType-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={vehicleTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={vehicleTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={vehicleTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={vehicleType} />
    </Switch>
  </>
);

export default Routes;
