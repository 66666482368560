import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormFeedback, FormGroup, FormText, Label, Input } from 'reactstrap';
import axios from 'axios';
import { AvForm, AvGroup, AvInput, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const CaseManagementModal = ({ opened, toggle, modalData, refreshData, setRefreshData }: any) => {
  const [disableButton, setDisableButton] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [driverSearchData, setDriverSearchData] = useState([]);
  const [locationSearchData, setLocationSearchData] = useState([]);
  const [selectedData, setSelectedData] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    console.log(modalData);

    // API call to get necessary data on the basis of modal code

    if (modalData.modalCode === 'editDriver') {
      modalData.driverType === 'driver'
        ? axios
            .get(`api/Drivers?countryId=${modalData.countryId}`)
            .then(response => {
              // console.log(response.data);
              setApiData(response.data);
            })
            .catch(error => console.log(error))
        : axios
            .get(`api/Ambulances?countryId=${modalData.countryId}`)
            .then(response => {
              // console.log(response.data);
              setApiData(response.data);
            })
            .catch(error => console.log(error));
    }
    if (modalData.modalCode === 'changeVehicleType') {
      axios.get('api/vehicle_type').then((response) => {
        // console.log(response.data);
        setApiData(response.data);
      }).catch(error => console.log(error));
    }
    if (modalData.modalCode === 'changePickupLocation') {
      modalData.pickupType === 'HEALTHCARE FACILITY'
        ? axios.get(`api/healthcare?countryId=${modalData.countryId}`).then((response) => {
        // console.log(response.data);
        setApiData(response.data);
        }).catch(error => console.log(error))
      : axios.get(`api/villages-by-country?countryId=${modalData.countryId}`).then((response) => {
        // console.log(response.data);
        setApiData(response.data);
        }).catch(error => console.log(error))
    }
    if (modalData.modalCode === 'changeDestination') {
      modalData.endPickupType === "HEALTHCARE FACILITY"
      ? axios.get(`api/healthcare?countryId=${modalData.countryId}`).then((response) => {
          // console.log(response.data);
          setApiData(response.data);
        }).catch(error => console.log(error))
      : axios.get(`api/villages-by-country?countryId=${modalData.countryId}`).then((response) => {
        // console.log(response.data);
        setApiData(response.data);
        }).catch(error => console.log(error))
    }
    if (modalData.modalCode === 'editMotherFollowup' || modalData.modalCode === 'editBabyFollowup') {
      axios.get(`api/followup-emergency?emergencyType=${modalData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
        const filteredData = response.data.filter((item: any) =>
          modalData.modalCode === 'editMotherFollowup' ? item.followupType === 'MATERNAL' : item.followupType === 'NEONATAL'
        );
        setApiData(filteredData);
        setSelectedData({
          emergencyType: modalData.emergencyType,
          followupId: modalData.followupId
        })
      }).catch(error => console.log(error));
    }
    if (modalData.modalCode === 'editMotherCondition' || modalData.modalCode === 'editBabyCondition') {
      axios.get(`api/condition-emergency?emergencyType=${modalData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
        const filteredData = response.data.filter((item: any) =>
          modalData.modalCode === 'editMotherCondition' ? item.followupType === 'MATERNAL' : item.followupType === 'NEONATAL'
        );
        setApiData(filteredData);
        setSelectedData({
          emergencyType: modalData.emergencyType,
          conditionId: modalData.conditionId
        })
      }).catch(error => console.log(error));
    }
    if (modalData.modalCode === 'editMotherSymptoms' || modalData.modalCode === 'editBabySymptoms') {
      axios.get(`api/symptoms-by-emergency?emergencyType=${modalData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
        // console.log(response.data);
        setApiData(response.data);
        setSelectedData({
          emergencyType: modalData.emergencyType,
          symptomsId: modalData.symptomsId
        })
      }).catch(error => console.log(error));
    }
  }, [modalData]);

  useEffect(() => {
    if(selectedData.emergencyType) {
      if(modalData.modalCode === 'editMotherFollowup' || modalData.modalCode === 'editBabyFollowup') {
      axios.get(`api/followup-emergency?emergencyType=${selectedData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
        const filteredData = response.data.filter((item: any) =>
          modalData.modalCode === 'editMotherFollowup' ? item.followupType === 'MATERNAL' : item.followupType === 'NEONATAL'
        );
        setApiData(filteredData);
      }).catch(error => console.log(error));
      }
      if(modalData.modalCode === 'editMotherCondition' || modalData.modalCode === 'editBabyCondition') {
      axios.get(`api/condition-emergency?emergencyType=${selectedData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
        const filteredData = response.data.filter((item: any) =>
          modalData.modalCode === 'editMotherCondition' ? item.followupType === 'MATERNAL' : item.followupType === 'NEONATAL'
        );
        setApiData(filteredData);
      }).catch(error => console.log(error));
      }
      if (modalData.modalCode === 'editMotherSymptoms' || modalData.modalCode === 'editBabySymptoms') {
        axios.get(`api/symptoms-by-emergency?emergencyType=${selectedData.emergencyType}&countryId=${modalData.countryId}`).then((response) => {
          // console.log(response.data);
          setApiData(response.data);
        }).catch(error => console.log(error));
      }
    }
  }, [selectedData.emergencyType]);

  const changeActiveStatus = () => {
    setDisableButton(true);
    axios.put(`api/patient-cases-activated/${modalData.caseId}`).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        toggle();
      },
      error => {
        setDisableButton(false);
      }
    );
  };

  const changePaymentStatus = () => {
    setDisableButton(true);
    axios.put(`api/journey/${modalData.journeyId}`, {
      paymentId: modalData.paymentId
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        toggle();
      },
      error => {
        setDisableButton(false);
      }
    );
  };

  const changeJourneyDriver = () => {
    setDisableButton(true);
    axios
      .put(`api/journey/${modalData.journeyId}`, {
        [modalData.driverType === 'driver' ? 'driverId' : 'ambulanceId']: selectedData.id,
      })
      .then(response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      })
      .catch(error => {
        console.log(error);
        setDisableButton(false);
        // setApiData([]);
        // setSelectedData({});
      });
  };

  const changeVehicleType = () => {
    setDisableButton(true);
    axios.put(`api/journey/${modalData.journeyId}`, {
      vehicleId: selectedData.selectedVehicle
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      },
      error => {
        setDisableButton(false);
        // setApiData([]);
        // setSelectedData({});
      }
    );
  };

  const changePickupLocation = () => {
    setDisableButton(true);
    axios.put(`api/journey/${modalData.journeyId}`, {
      [modalData.pickupType === "VILLAGE" ? "startVillageId": "startHealthcareId"]: selectedData.id,
      // [modalData.endPickupType === "VILLAGE" ? "endVillageId": "endHealthcareId"]: modalData.destinationId
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      }
    ).catch(error => {
      console.log(error.response);
      if (error.response.data.status === 404) {
        setErrorMessage("No Route Exists for the selected Pickup Location");
      } else if (error.response.data.status === 500) {
        setErrorMessage(`${error.response.data.detail}`);
      }
    });
  };

  const changeDestination = () => {
    setDisableButton(true);
    axios.put(`api/journey/${modalData.journeyId}`, {
      // [modalData.pickupType === "VILLAGE" ? "startVillageId": "startHealthcareId"]: modalData.pickupId,
      [modalData.endPickupType === "VILLAGE" ? "endVillageId": "endHealthcareId"]: selectedData.id
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      }
    ).catch(error => {
      console.log(error.response);
      if (error.response.data.status === 404) {
        setErrorMessage("No Route Exists for the selected Destination");
      } else if (error.response.data.status === 500) {
        setErrorMessage(`${error.response.data.detail}`);
      }
    });
  };

  const editFollowup = () => {
    setDisableButton(true);
    axios.put(`api/followup/${modalData.caseId}`, {
      emergencyType: selectedData.emergencyType,
      followupId: selectedData.followupId,
      followupMsg: selectedData.followupMsg
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      }
    ).catch(error => {
      console.log(error.response);
    });
  };

  const editCondition = () => {
    setDisableButton(true);
    axios.put(`api/condition/${modalData.caseId}`, {
      emergencyType: selectedData.emergencyType,
      conditionId: selectedData.conditionId,
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      }
    ).catch(error => {
      console.log(error.response);
    });
  };

  const editSymptoms = () => {
    setDisableButton(true);
    axios.put(`api/patient-edit/${modalData.caseId}`, {
      emergencyType: selectedData.emergencyType,
      ids: selectedData.symptomsId,
    }).then(
      response => {
        setRefreshData(!refreshData);
        setDisableButton(false);
        setApiData([]);
        setSelectedData({});
        setConfirmAction(false);
        toggle();
      }
    ).catch(error => {
      console.log(error.response);
    });
  };

  console.log('MODAL DATA > > ', modalData);

  const driverNames = (includeEmpty = false, data: any) => {
    const driverItems = data.map((item: any) => ({
      id: item.id,
      name: modalData.driverType === 'driver' ? item.firstName + ' ' + item.lastName : item.driverFirstName + ' ' + item.driverLastName,
      driverType: modalData.driverType === 'driver' ? item.driverType : 'AMBULANCE',
      vehicleType: item.vehicleType,
      activated: modalData.driverType === 'driver' ? item.activated : item.active,
    }));
    let result = includeEmpty ? [{ id: undefined, name: '', driverType: '', vehicleType: '', activated: '' }] : [];
    result = result.concat(driverItems);
    return result;
  };

  const handleDriverSearch = (query: string) => {
    console.log(query);
    const filteredDrivers = apiData.filter((item: any) =>
      modalData.driverType === 'driver'
        ? item.firstName.toLowerCase().includes(query.toLocaleLowerCase()) ||
          item.lastName.toLowerCase().includes(query.toLocaleLowerCase())
        : item.driverFirstName.toLowerCase().includes(query.toLocaleLowerCase()) ||
          item.driverLastName.toLowerCase().includes(query.toLocaleLowerCase())
    );
    setDriverSearchData(driverNames(false, filteredDrivers));
  };

  const locationNames = (includeEmpty = false, data: any) => {
    const locationItems = data.map((item: any) => ({
      id: item.id,
      name: item.name + ' / ' + item?.council?.parent?.name + ' / ' +  item?.council?.parent?.parent?.name,
    }));
    let result = includeEmpty ? [{ id: undefined, name: ''}] : [];
    result = result.concat(locationItems);
    return result;
  };

  const handleLocationSearch = (query: string) => {
    console.log(query);
    const filteredLocations = apiData.filter((item: any) =>
      item.name.toLowerCase().includes(query.toLocaleLowerCase())
    );
    setLocationSearchData(locationNames(false, filteredLocations));
  };

  const submit = (event, values) => {
    console.log(values);
    if(values.symptomsId.length > 0) {
      setSelectedData({
        ...selectedData,
        symptomsId: values.symptomsId
      });
      setConfirmAction(true);
    } else {
      setErrorObj({symptomsId: "Please select a Symptom"});
    }
  }

  const editSymptomsComponent = () => {
    return (
      <>
        <AvForm onValidSubmit={submit}>
          <ModalBody>
            <AvGroup>
              <FormText>Emergency Type</FormText>
              <AvInput
                id="mother-symptoms-emergency"
                type="select"
                className="form-control"
                name="emergencyType"
                value={selectedData.emergencyType ? selectedData.emergencyType : modalData.emergencyType}
                onChange={(e: any) => {
                  console.log(e.target.value)
                  setSelectedData({
                    ...selectedData, emergencyType: e.target.value, symptomsId: []
                  })
                }}
                disabled={true}
              >
                <option value="ANTEPARTUM">ANTEPARTUM</option>
                <option value="LABOUR">LABOUR</option>
                <option value="POSTPARTUM">POSTPARTUM</option>
                <option value="NEONATAL">NEONATAL</option>
              </AvInput>
            </AvGroup>

            {apiData && <AvGroup>
              <FormText>Symptoms</FormText>
              <AvCheckboxGroup
                id="mother-symptoms"
                name="symptomsId"
                className="form-control"
                style={{ overflowY: 'auto', height: '150px' }}
                value={selectedData.symptomsId ? selectedData.symptomsId : modalData.symptomsId}
                onChange={(e: any) => {
                  setErrorObj({symptomsId: ""});
                }}
              >
                {apiData.length > 0 && apiData.map(item => (
                  <AvCheckbox value={item.id} key={item.enCondition} label={item.enCondition}/>
                ))}
              </AvCheckboxGroup>
              {errorObj && <FormText color="danger" >{errorObj.symptomsId}</FormText>}
            </AvGroup>}
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              disable={disableButton}
            >
              Change
            </Button>
          </ModalFooter>
        </AvForm>
      </>
    )
  }
  const editConditionComponent = () => {
    return (
      <>
        <ModalBody>
          <AvForm model={{}}>
            <AvGroup>
              <FormText>Emergency Type</FormText>
              <AvInput
                id="mother-condition-emergency"
                type="select"
                className="form-control"
                name="emergencyType"
                value={selectedData.emergencyType ? selectedData.emergencyType : modalData.emergencyType}
                onChange={(e: any) => {
                  console.log(e.target.value)
                  setSelectedData({
                    ...selectedData, emergencyType: e.target.value, conditionId: ""
                  })
                }}
                disabled={true}
              >
                <option value="ANTEPARTUM">ANTEPARTUM</option>
                <option value="LABOUR">LABOUR</option>
                <option value="POSTPARTUM">POSTPARTUM</option>
                <option value="NEONATAL">NEONATAL</option>
              </AvInput>
            </AvGroup>

            {apiData && <AvGroup>
              <FormText>Condition</FormText>
              <AvInput
                id="mother-condition"
                type="select"
                className="form-control"
                name="conditionId"
                value={selectedData.conditionId ? selectedData.conditionId : modalData.conditionId}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setErrorObj({conditionId: ""});
                  setSelectedData({
                    ...selectedData, conditionId: e.target.value
                  })
                }}
              >
                <option value="" key="">-- Select Condition --</option>
                {apiData.length > 0 && apiData.map(item => (
                  <option value={item.id} key={item.id}>{item.enFollowup}</option>
                ))}
              </AvInput>
              {errorObj && <FormText color="danger" >{errorObj.conditionId}</FormText>}
            </AvGroup>}
          </AvForm>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (selectedData.conditionId) {
                setConfirmAction(true);
              } else {
                setErrorObj({conditionId: "Please Select a Condition"});
              }
            }}
            disable={disableButton}
          >
            Change
          </Button>
        </ModalFooter>
      </>
    )
  }


  return (
    <Modal isOpen={opened} toggle={() => {
      setSelectedData({});
      setApiData([]);
      setSelectedData({});
      setErrorMessage("");
      setErrorObj({});
      setConfirmAction(false);
      toggle();
    }}>
      <ModalHeader toggle={() => {
                setSelectedData({});
                setApiData([]);
                setSelectedData({});
                setErrorMessage("");
                setErrorObj({});
                setConfirmAction(false);
                toggle();
              }}>{modalData.modalText}</ModalHeader>
      {confirmAction ? (
        <>
          <ModalBody>{`Are you sure you want to update the ${
            modalData.modalCode === 'editDriver' ? 'driver'
              : modalData.modalCode === 'changeVehicleType' ? 'vehicle type'
              : modalData.modalCode === 'changePickupLocation' ? 'pickup location'
              : modalData.modalCode === 'changeDestination' ? 'destination'
              : (modalData.modalCode === 'editMotherFollowup' || modalData.modalCode === 'editBabyFollowup') ? 'followup'
              : (modalData.modalCode === 'editMotherCondition' || modalData.modalCode === 'editBabyCondition') ? 'condition'
              : (modalData.modalCode === 'editMotherSymptoms' || modalData.modalCode === 'editBabySymptoms') ? 'symptoms' : ''
          } for this case?`}

          {errorMessage && <FormText color="danger" >{errorMessage}</FormText>}

          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                modalData.modalCode === 'editDriver' ? changeJourneyDriver()
                  : modalData.modalCode === 'changeVehicleType' ? changeVehicleType()
                  : modalData.modalCode === 'changePickupLocation' ? changePickupLocation()
                  : modalData.modalCode === 'changeDestination' ? changeDestination()
                  : (modalData.modalCode === 'editMotherFollowup' || modalData.modalCode === 'editBabyFollowup') ? editFollowup()
                  : (modalData.modalCode === 'editMotherCondition' || modalData.modalCode === 'editBabyCondition') ? editCondition()
                  : (modalData.modalCode === 'editMotherSymptoms' || modalData.modalCode === 'editBabySymptoms') ? editSymptoms() : '';
              }}
              disable={disableButton}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setSelectedData({});
                setApiData([]);
                setSelectedData({});
                setErrorMessage("");
                setErrorObj({});
                setConfirmAction(false);
                toggle();
              }}
            >
              No
            </Button>
          </ModalFooter>
        </>
      ) : modalData.modalCode === 'activeStatus' ? (
        <>
          <ModalBody>{`Are you sure you want to ${modalData.caseStatus ? 'De-Activate' : 'Activate'} this case?`}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={changeActiveStatus} disable={disableButton}>
              Yes
            </Button>
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </>
      ) : modalData.modalCode === 'paymentStatus' ? (
        <>
          <ModalBody>{`Are you sure you want to change the status to “REQUESTED” for this Journey?`}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={changePaymentStatus} disable={disableButton}>
              Yes
            </Button>
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </>
      ) : modalData.modalCode === "changeVehicleType" ? (
        <>
        <ModalBody>
          <AvForm model={{}}>
            <AvGroup>
              {apiData && (
                <AvInput
                  id="vehicle-type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={modalData.vehicleTypeId}
                  onChange={(e: any) => {
                    console.log(e.target.value)
                    setSelectedData({
                      selectedVehicle: e.target.value
                    })
                  }}
                >
                  {apiData.length > 0 &&
                    apiData.filter(item => item.countryId === modalData.countryId)?.map(vehicle => {
                      return (
                        <option value={vehicle.id} key={'vehicle-' + vehicle.id}>
                          {vehicle.enType}
                        </option>
                      );
                    })}
                </AvInput>
              )}
            </AvGroup>
          </AvForm>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (Object.keys(selectedData).length > 0) {
                setConfirmAction(true);
              }
            }}
            disable={disableButton}
          >
            Change
          </Button>
        </ModalFooter>
      </>
      ) : modalData.modalCode === 'editDriver' ? (
        <>
          <ModalBody>
            {modalData.paymentStatus === "PROCESSED" && <FormText color="danger" >The payment has already been processed, are you sure want to edit the driver?</FormText>}
            <AsyncTypeahead
              filterBy={() => true}
              id="from-async"
              labelKey="name"
              minLength={3}
              placeholder="Select Driver"
              onSearch={handleDriverSearch}
              options={driverSearchData}
              onChange={(selected: any) => {
                const selectedDriver = selected.length > 0 ? selected[0] : undefined;
                console.log(selected);
                setSelectedData(selected[0]);
              }}
            />

            <FormText>Previous Driver : {modalData.driverName}</FormText>
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (Object.keys(selectedData).length > 0) {
                  setConfirmAction(true);
                }
              }}
              disable={disableButton}
            >
              Change
            </Button>
          </ModalFooter>
        </>
      ) : modalData.modalCode === 'changePickupLocation' ? (
        <>
          <ModalBody>
            <AsyncTypeahead
              filterBy={() => true}
              id="from-async"
              labelKey="name"
              minLength={3}
              placeholder="Select Pickup Location"
              onSearch={handleLocationSearch}
              options={locationSearchData}
              onChange={(selected: any) => {
                const selectedLocation = selected.length > 0 ? selected[0] : undefined;
                console.log(selected);
                setSelectedData(selectedLocation);
              }}
            />

            <FormText>Previous Pickup Location : {modalData.pickupLocationName}</FormText>
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (Object.keys(selectedData).length > 0) {
                  setConfirmAction(true);
                }
              }}
              disable={disableButton}
            >
              Change
            </Button>
          </ModalFooter>
        </>
      ) : modalData.modalCode === 'changeDestination' ? (
        <>
          <ModalBody>
            <AsyncTypeahead
              filterBy={() => true}
              id="from-async"
              labelKey="name"
              minLength={3}
              placeholder="Select Destination"
              onSearch={handleLocationSearch}
              options={locationSearchData}
              onChange={(selected: any) => {
                const selectedLocation = selected.length > 0 ? selected[0] : undefined;
                console.log(selected);
                setSelectedData(selectedLocation);
              }}
            />

          <FormText>Previous Destination : {modalData.destinationName}</FormText>
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (Object.keys(selectedData).length > 0) {
                  setConfirmAction(true);
                }
              }}
              disable={disableButton}
            >
              Change
            </Button>
          </ModalFooter>
        </>
      ) : (modalData.modalCode === 'editMotherFollowup' || modalData.modalCode === "editBabyFollowup") ? (
        <>
        <ModalBody>
        <AvForm model={{}}>
            <AvGroup>
              <FormText>Emergency Type</FormText>
              <AvInput
                id="mother-followup-emergency"
                type="select"
                className="form-control"
                name="emergencyType"
                value={selectedData.emergencyType ? selectedData.emergencyType : modalData.emergencyType}
                onChange={(e: any) => {
                  console.log(e.target.value)
                  setSelectedData({
                    ...selectedData, emergencyType: e.target.value, followupId: ""
                  })
                }}
                disabled={true}
              >
                <option value="ANTEPARTUM">ANTEPARTUM</option>
                <option value="LABOUR">LABOUR</option>
                <option value="POSTPARTUM">POSTPARTUM</option>
                <option value="NEONATAL">NEONATAL</option>
              </AvInput>
            </AvGroup>

            {apiData && <AvGroup>
              <FormText>Followup</FormText>
              <AvInput
                id="mother-followup"
                type="select"
                className="form-control"
                name="followupId"
                value={selectedData.followupId ? selectedData.followupId : modalData.followupId}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setErrorObj({followupId: ""});
                  setSelectedData({
                    ...selectedData, followupId: e.target.value
                  })
                }}
              >
                <option value="" key="">-- Select Followup --</option>
                {apiData.length > 0 && apiData.map(item => (
                  <option value={item.id} key={item.id}>{item.enFollowup}</option>
                ))}
              </AvInput>
              {errorObj && <FormText color="danger" >{errorObj.followupId}</FormText>}
            </AvGroup>}

            <AvGroup>
            <FormText>Followup Message (Optional)</FormText>
              <Input
                id="mother-followup-msg"
                type="textarea"
                className="form-control"
                name="followupMsg"
                value={selectedData.followupMsg}
                maxLength={1000}
                rows={5}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setSelectedData({
                    ...selectedData, followupMsg: e.target.value
                  })
                }}
              ></Input>
            </AvGroup>
          </AvForm>
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (selectedData.followupId) {
                  setConfirmAction(true);
                } else {
                  setErrorObj({followupId: "Please Select a Followup"});
                }
              }}
              disable={disableButton}
            >
              Change
            </Button>
          </ModalFooter>
        </>
      ) : (modalData.modalCode === "editMotherSymptoms" || modalData.modalCode === "editBabySymptoms") ? editSymptomsComponent()
        : (modalData.modalCode === 'editMotherCondition' || modalData.modalCode === "editBabyCondition") ? editConditionComponent()
        : (<></>)
      }
    </Modal>
  );
};


export default CaseManagementModal;
