import React, {useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, PieChart, StackedBarChart } from '../../../charts';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, MONTH_LABELS, GREEN_COLORS } from '../constants';
import value from "*.json";
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const EmergencyReferralSection = ({ countReferrals, emergencyReferrals }) => {
    const [labels, setLabels] = useState([]);
    const [graphData, setGraphData] = useState({});
    const [pieData, setPieData] = useState([]);
    const [cancelledEmergencies, setCancelledEmergencies] = useState(0);
    const [districtLabels, setDistrictLabels] = useState([]);
    const [bypassGraphData, setBypassGraphData] = useState({});

    const TOTAL_CARD_GROUP_DATA = [
        { label: "2.1 Total patient emergencies:", icon: "content/images/new_ambulance.svg", value: countReferrals?.totalEmergenciesCount },
        // { label: "2.2 Total bypass emergencies:", icon: "content/images/new_car.svg", value: countReferrals?.totalBypassEmergencies },
        { label: "2.2 Total non eligible emergencies:", icon: "content/images/restore.svg", value: countReferrals?.totalNonEligibleEmergencies },
        { label: "2.3 Total maternal emergencies", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.motherCount },
        { label: "2.4 Total neonatal emergencies:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyCount },
        { label: "2.5 Total both emergencies:", icon: "content/images/pregnant_woman_black.svg", value: countReferrals?.bothCount },
        { label: "2.6 Total cancelled emergencies:", icon: "content/images/new_ambulance.svg", value: countReferrals?.totalCancelledEmergencies },
    ]

    useEffect(() => {
        if (emergencyReferrals?.maternalReferral && Object.keys(emergencyReferrals?.maternalReferral)) {
            const data = {
                Antepartum: {
                  label: 'Maternal',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'In Labour': {
                  label: 'Neonatal',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
                Postpartum: {
                  label: 'Both',
                  color: '#00c04b',
                  data: [],
                },
            };

            const aggregatedGraphData = mergeAndSum([
                emergencyReferrals?.maternalReferral,
                emergencyReferrals?.neonatalReferral,
                emergencyReferrals?.bothReferral
            ])

            const graphDistrictKeys = Object.keys(aggregatedGraphData)
                .sort(function(a,b){return aggregatedGraphData[b]-aggregatedGraphData[a]});

            for(let i = 0; i < graphDistrictKeys.length; i++) {
                data['Antepartum'].data.push(emergencyReferrals?.maternalReferral[graphDistrictKeys[i]] ?? 0)
                data['In Labour'].data.push(emergencyReferrals?.neonatalReferral[graphDistrictKeys[i]] ?? 0)
                data['Postpartum'].data.push(emergencyReferrals?.bothReferral[graphDistrictKeys[i]] ?? 0)
            }

            let sum = 0;
            const pieKeys = Object.keys(emergencyReferrals?.cancelledJourneys).map((el, i) => {
                sum = sum + emergencyReferrals?.cancelledJourneys[el];
                return { label: el, bdColor: '#000000', bgColor: GREEN_COLORS[i], data: emergencyReferrals?.cancelledJourneys[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const districtKeys = Object.keys(emergencyReferrals?.totalBypassEmergencies).length > Object.keys(emergencyReferrals?.totalLiveEmergencies).length ? Object.keys(emergencyReferrals?.totalBypassEmergencies) : Object.keys(emergencyReferrals?.totalLiveEmergencies);

            const DOLVBE_DATA = {
                'Live emergency': {
                  label: 'Live emergency',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'Bypass emergency': {
                  label: 'Bypass emergency',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
            };

            for(let i = 0; i < districtKeys.length; i++) {
                DOLVBE_DATA['Live emergency'].data.push(emergencyReferrals?.totalLiveEmergencies[districtKeys[i]] ? emergencyReferrals?.totalLiveEmergencies[districtKeys[i]] : 0)
                DOLVBE_DATA['Bypass emergency'].data.push(emergencyReferrals?.totalBypassEmergencies[districtKeys[i]] ? emergencyReferrals?.totalBypassEmergencies[districtKeys[i]] : 0)
            }

            setLabels(graphDistrictKeys);
            setGraphData(data);
            setPieData(pieKeys);
            setCancelledEmergencies(sum);
            setDistrictLabels(districtKeys);
            setBypassGraphData(DOLVBE_DATA);
        }
    }, [emergencyReferrals])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>2. Emergency Referrals</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 3)} col="col-4" />
            </div>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(3, 6)} col="col-4" />
            </div>
            {/* <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(4, 6)} col="col-6" />
            </div>
            <div className="row mb-3">
                <div className="col-12" >
                    <DisplayIndicator data={{ label: "2.8 Total referrals per type of emergencies (origin):", value: "1250" }}>
                        <StackedBarChart chartLabels={DUMMMY_LABELS} chartData={DUMMY_REFERRALS_EMERGENCIES_DATA} axis='y' />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "2.8 Total referrals per type of emergencies", value: "" }}>
                        <StackedBarChart chartLabels={labels} chartData={graphData} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "2.9 Distribution of live vs bypass emergencies", value: "" }}>
                        <StackedBarChart chartLabels={districtLabels} chartData={bypassGraphData} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "2.9 Number of cancelled journeys - Emergency Referrals:", value: cancelledEmergencies }}>
                        {/* <PieChart chartData={pieData} /> */}
                        <BarChart chartLabel='Cancelled Journeys' labels={pieData.map(item => item.label)} dataSet={pieData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
