import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities} from './rejection-reason.reducer';
import {IRegion} from "app/shared/model/region.model";
import {AvForm, AvGroup, AvInput, Label} from 'availity-reactstrap-validation';
export interface IRejectionReasonDriverProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const RejectionReasonDriver = (props: IRejectionReasonDriverProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);
  const [activeCountry, setActiveCountry] = useState<IRegion>({})
  const { rejectionReasonDriverList, match, loading,userCountryId } = props;

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries, userCountryId]);

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    // updateVehicleTypeList(newActiveCountry)
  }

  const regCountriesList = props.countries.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
  })

  console.log("rejectionReasonDriverList",rejectionReasonDriverList)
  return (
    <div>
      <h2 id="incident-report-heading">
          Rejection Reason (Driver)
        {activeCountry && (<AvForm>
            <AvGroup>
              <AvInput id="payment-countries" type="select" className="form-control" name="payment-country"
                       value={activeCountry.id} onChange={onCountryChange}>
                {regCountriesList
                  ? regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))
                  : null}
              </AvInput>
            </AvGroup>
          </AvForm>
        )
        }
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Rejection Reason
        </Link>
      </h2>
      <div className="table-responsive">
        {rejectionReasonDriverList && rejectionReasonDriverList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Rejection Reason(English)</th>
                <th>Rejection Reason (Local Language)</th>
                <th>Country</th>
              
                <th />
              </tr>
            </thead>
            <tbody>
              {rejectionReasonDriverList.filter((v)=> activeCountry?.id === v?.countryId).map((rejectionReasonDriver, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${rejectionReasonDriver.id}`} color="link" size="sm">
                      {rejectionReasonDriver.id}
                    </Button>
                  </td>
                  <td>{rejectionReasonDriver.enRejectionReason}</td>
                  <td>{rejectionReasonDriver.stRejectionReason}</td>
                  <td>{props.countries.find(country => country.id === rejectionReasonDriver.countryId)?.name}</td>
                  
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${rejectionReasonDriver.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${rejectionReasonDriver.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Incident Reports found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ rejectionReasonDriver ,authentication}: IRootState) => ({
  rejectionReasonDriverList: rejectionReasonDriver.entities,
  loading: rejectionReasonDriver.loading,
  countries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RejectionReasonDriver);
