import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities} from './incident-report.reducer';
import {IRegion} from "app/shared/model/region.model";
import {AvForm, AvGroup, AvInput, Label} from 'availity-reactstrap-validation';
export interface IIncidentReportProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const IncidentReport = (props: IIncidentReportProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);
  const [activeCountry, setActiveCountry] = useState<IRegion>({})
  const { incidentReportList, match, loading,userCountryId } = props;

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries, userCountryId]);

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    // updateVehicleTypeList(newActiveCountry)
  }

  const regCountriesList = props.countries.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
  })
  return (
    <div>
      <h2 id="incident-report-heading">
        Incident Reports
        {activeCountry && (<AvForm>
            <AvGroup>
              <AvInput id="payment-countries" type="select" className="form-control" name="payment-country"
                       value={activeCountry.id} onChange={onCountryChange}>
                {regCountriesList
                  ? regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))
                  : null}
              </AvInput>
            </AvGroup>
          </AvForm>
        )
        }
        <Link to={`${match.url}/new?country_id=${activeCountry.id}`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Incident Report
        </Link>
      </h2>
      <div className="table-responsive">
        {incidentReportList && incidentReportList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Incident Report(English)</th>
                <th>Incident Report (Local Language)</th>
                <th>Country</th>
              <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {incidentReportList.filter((v)=> activeCountry.id === v.countryId).map((incidentReport, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${incidentReport.id}`} color="link" size="sm">
                      {incidentReport.id}
                    </Button>
                  </td>
                  <td>{incidentReport.description}</td>
                  <td>{incidentReport.enIncident}</td>
                  <td>{incidentReport.stIncident}</td>
                  <td>{props.countries.find(country => country.id === incidentReport.countryId)?.name}</td>
                  <td>{incidentReport.isActive ? 'Active' :'Inactive'}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${incidentReport.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${incidentReport.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Incident Reports found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ incidentReport ,authentication}: IRootState) => ({
  incidentReportList: incidentReport.entities,
  loading: incidentReport.loading,
  countries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentReport);
