import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import {NavDropdown} from './menu-components';

export const AccountMenu = ({ isAuthenticated = false  }) => (
  <NavDropdown show={true} icon="user" name="Account" id="account-menu">
    <>
      <MenuItem icon="wrench" show={true} to="/account/settings">
        Settings
      </MenuItem>
      <MenuItem icon="lock"  show={true} to="/account/password">
        Password
      </MenuItem>
      <MenuItem icon="sign-out-alt"  show={true} to="/logout">
        Sign out
      </MenuItem>
    </>
  </NavDropdown>
);

export default AccountMenu;
