import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './condition.reducer';

export interface ISymptomDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConditionDetail = (props: ISymptomDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conditionEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Condition [<b>{conditionEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="emergencyType">Followup Type</span>
          </dt>
          <dd>{conditionEntity.followupType}</dd>
          <dt>
            <span id="emergencyType">Emergency Type</span>
          </dt>
          <dd>{conditionEntity?.emergencyType}</dd>
          <dt>
            <span id="enCondition">Condition (English)</span>
          </dt>
          <dd>{conditionEntity.enFollowup}</dd>
          <dt>
            <span id="stCondition">Condition (Local Language)</span>
          </dt>
          <dd>{conditionEntity.stFollowup}</dd>
          
        </dl>
        <Button tag={Link} to="/condition" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/condition/${conditionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ condition }: IRootState) => ({
  conditionEntity: condition.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConditionDetail);
