import axios from 'axios';
import {
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
  Storage,
  IPayloadResult
} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {REQUEST, SUCCESS, FAILURE} from 'app/shared/reducers/action-type.util';

import {IPaymentBreakdown, defaultValue} from 'app/shared/model/paymentBreakdown.model';
import {IRegion} from "app/shared/model/region.model";
import {IPayload} from "react-jhipster/src/type/redux-action.type";

export const ACTION_TYPES = {
  FETCH_PAYMENT_LIST: 'paymentBreakdown/FETCH_PAYMENT_LIST',
  FETCH_PAYMENT: 'paymentBreakdown/FETCH_PAYMENT',
  CREATE_PAYMENT: 'paymentBreakdown/CREATE_PAYMENT',
  UPDATE_PAYMENT: 'paymentBreakdown/UPDATE_PAYMENT',
  DELETE_PAYMENT: 'paymentBreakdown/DELETE_PAYMENT',
  RESET: 'paymentBreakdown/RESET',
  SET_ACTIVE_COUNTRY: 'paymentBreakdown/SET_ACTIVE_COUNTRY',
  FETCH_ALL_COUNTRIES: 'paymentBreakdown/FETCH_ALL_COUNTRIES',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPaymentBreakdown>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  countries: [] as ReadonlyArray<IRegion>
};

export type PaymentBreakDownState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentBreakDownState = initialState, action): PaymentBreakDownState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PAYMENT):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENT):
    case REQUEST(ACTION_TYPES.DELETE_PAYMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT):
    case FAILURE(ACTION_TYPES.CREATE_PAYMENT):
    case FAILURE(ACTION_TYPES.UPDATE_PAYMENT):
    case FAILURE(ACTION_TYPES.DELETE_PAYMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAYMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PAYMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPaymentBreakdown>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/payment-breakdown';

// Actions

// export const getEntities: ICrudGetAllAction<IPaymentBreakdown> = (page, size, sort) => ({
//   type: ACTION_TYPES.FETCH_PAYMENT_LIST,
//   payload: axios.get<IPaymentBreakdown>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
// });
export const getEntities: (page?, size?, sort?) => IPayload<IPaymentBreakdown> | IPayloadResult<IPaymentBreakdown> = (page, size, sort) => async dispatch => {
  const requestUrl = 'api/payment-breakdown';

  const paymentBreakdowns = await dispatch({
    type: ACTION_TYPES.FETCH_PAYMENT_LIST,
    payload: axios.get<IPaymentBreakdown>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
  });

  return paymentBreakdowns
}

export const getEntity = (id: string, startDate: string) => {
  const requestUrl = `${'api/one-payment-breakdown'}/${id}/${startDate}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENT,
    payload: axios.get<IPaymentBreakdown>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IPaymentBreakdown> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAYMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPaymentBreakdown> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPaymentBreakdown> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAYMENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
