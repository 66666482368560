import axios from 'axios';
import {Storage,ICrudGetAction} from 'react-jhipster';

import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';
import {defaultValue, IReportMenu,IMainMenu } from 'app/shared/model/reportMenu.model';
import {IRegion} from "app/shared/model/region.model";
export const LOGOUT_TYPES = {
  USER: 'user',
  TIMEOUT: 'timeout',
  ERROR: 'error'
}

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  GET_CREDENTIALS: 'authentication/GET_CREDENTIALS',
  FETCH_ALL_COUNTRIES: 'commondata/FETCH_ALL_COUNTRIES',
  GET_REPORT_MENU: 'header/GET_REPORT_MENU',
  GET_MAIN_MENU:'header/GET_MAIN_MENU',

};

const AUTH_TOKEN_KEY = 'mmama-authenticationToken';

const initialState = {
  loading: undefined,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  logoutType: undefined,
  account: {} as any,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  permissions: [],
  userId: (null as unknown) as string,
  expiration: (null as unknown) as number,
  countries: [] as ReadonlyArray<IRegion>,
  reportMenus: [] as ReadonlyArray<IReportMenu>,
  userCountryId:'',
  mainMenus: []
};

function extractJwt(headers) {
  let jwt;
  const bearerToken = headers?.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    jwt = bearerToken.slice(7, bearerToken.length);
  }
  return jwt;
}

function decodeJwt(jwt) {
  let userId
  let permissions = []
  let countryId = '';
  let expiration
  if (jwt) {
    const parts = jwt.split(".")
    if (parts) {
      try {
        if (parts[1]) {
          const body = JSON.parse(atob(parts[1]))
          userId = body.sub
          permissions = body.auth?.split(',')
          countryId = body.countryId
          console.log("permissions123",permissions)
          expiration = body.exp
        }
      } catch (err) {
        console.error(`Bad JWT token format (possibly bad base64 encoding): ${err.message} : ${jwt}`);
      }
    }
  }
  return {userId, permissions, expiration,countryId};
}

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        logoutType: undefined,
        isAuthenticated: false,
        loginError: true,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        sessionHasBeenFetched: true,
        isAuthenticated: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.LOGIN): {
      // TODO: Check that JWT userId matches account login
      const {userId, permissions, expiration,countryId} = decodeJwt(extractJwt(action?.payload?.headers))
      return {
        ...state,
        loading: false,
        loginError: false,
        logoutType: undefined,
        loginSuccess: true,
        permissions,
        userCountryId:countryId,
        userId,
        expiration
      };
    }
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        loading: false,
        logoutType: LOGOUT_TYPES.USER,
        isAuthenticated: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        countries: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      const {userId, permissions, expiration,countryId} = decodeJwt(Storage?.session?.get(AUTH_TOKEN_KEY));


      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data,
        permissions,
        userCountryId:countryId,
      };
    }
    case ACTION_TYPES.GET_CREDENTIALS:
      return {
        ...state,
        permissions: action?.payload.permissions,
        userId: action?.payload.userId,
        expiration: action?.payload.expiration
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        logoutType: LOGOUT_TYPES.ERROR,
        isAuthenticated: false,
        redirectMessage: action.message,
      };

      case REQUEST(ACTION_TYPES.GET_REPORT_MENU):
      return {
        ...state,
        loading: true,
      };

      case REQUEST(ACTION_TYPES.GET_MAIN_MENU):
      return {
        ...state,
        loading: true,
      };



    case FAILURE(ACTION_TYPES.GET_MAIN_MENU):
      return {
        ...state,
        loading: false,
      };


    case FAILURE(ACTION_TYPES.GET_REPORT_MENU):
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.GET_REPORT_MENU):
      return {
        ...state,
        loading: false,
        reportMenus: action.payload.data,
      }

      case SUCCESS(ACTION_TYPES.GET_MAIN_MENU):
      return {
        ...state,
        loading: false,
        mainMenus: action.payload.data,
      }
    default:
      return state;
  }
};

export const getSession: () => void = () => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account'),
  });

  const requestUrl = 'api/regions/type/COUNTRY';
  await dispatch({
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES,
    payload: axios.get<IRegion>(`${requestUrl}`),
  })

  return dispatch({
    type: ACTION_TYPES.GET_CREDENTIALS,
    payload: decodeJwt(Storage?.session?.get(AUTH_TOKEN_KEY))
  });
};

export const login: (username: string, password: string, rememberMe?: boolean) => void = (username, password, rememberMe = false) => async (
  dispatch
) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('/api/authenticate', {username, password, rememberMe}),
  });
  const jwt = extractJwt(result?.value.headers)
  if (jwt) {
    Storage.session.set(AUTH_TOKEN_KEY, jwt);
  }
  await dispatch(getSession());
};

export const clearAuthToken = () => {
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => void = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};
const apiUrl = 'api/report-menu';
export const getReportMenu: ICrudGetAction<IReportMenu> = () => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;

  return {
    type: ACTION_TYPES.GET_REPORT_MENU,
    payload: axios.get<IReportMenu>(requestUrl),
  }
}

export const getMainMenu: ICrudGetAction<IMainMenu> = () => {
  const requestUrl = `${'api/main-menu'}?cacheBuster=${new Date().getTime()}`;

  return {
    type: ACTION_TYPES.GET_MAIN_MENU,
    payload: axios.get<IMainMenu>(requestUrl),
  }
}
